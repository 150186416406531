import { useAttributeApi } from '@entities/attributes'
import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { ROUTES } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'
import { useTypedParams } from 'react-router-typesafe-routes'

import { AttributeInfo } from '../ui/attribute-info'
import { OptionsList } from '../ui/options-list'

const useAttributeTabsList = () => {
  const { id: attributeId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE)
  const { data: attribute } = useAttributeApi(attributeId)

  const translate = useTranslate(['label.attribute', 'label.options_list'])

  const attributeTabsList = [
    {
      id: 'settings',
      name: translate['label.attribute'],
      content: <AttributeInfo />,
    },
  ]

  if (attribute?.attribute_type === API_ATTRIBUTE_TYPE.LIST) {
    attributeTabsList.push({
      id: 'options-list',
      name: translate['label.options_list'],
      content: <OptionsList />,
    })
  }

  return attributeTabsList
}

export { useAttributeTabsList }
