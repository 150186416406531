import {
  type EmailList,
  getAutomaticTaskTypeOptions,
  useAutomaticTaskApi,
  useEmailListsApi,
} from '@entities/automatic_tasks'
import {
  AUTOMATIC_TASK_FIELDS,
  AUTOMATIC_TASK_FIELDS_NAMES,
  TASK_STATUS_NAME,
} from '@shared/config'
import { getLocalDate, getSelectInputOptions } from '@shared/lib'
import { InfoPageRow } from '@shared/ui'
import {
  CheckboxInput,
  CronInput,
  DatePicker,
  JsonInput,
  SelectInput,
  TextInput,
} from '@shared/ui/form'
import { Translate, useTranslate } from '@shared/ui/translate'

import { type AttributeInfoRowsProps } from './types'

const AutomaticTaskInfoRows: ReactFc<AttributeInfoRowsProps> = ({ id, isSubmitting }) => {
  const { data: task, isLoading: isTaskLoading } = useAutomaticTaskApi(id)
  const { data: emailData, isLoading: isEmailListsLoading } = useEmailListsApi()
  const translate = useTranslate(['message.empty_value'])

  const automaticTaskTypeOptions = getAutomaticTaskTypeOptions()

  const emailListOptions = getSelectInputOptions<EmailList>({
    items: emailData,
    valueKey: 'id',
    inputDisplayKey: 'name',
  })

  const isDisabled = isTaskLoading || isSubmitting || isEmailListsLoading

  const rowsConfig = [
    {
      name: AUTOMATIC_TASK_FIELDS.UUID,
      field: <>{task?.uuid}</>,
    },
    {
      name: AUTOMATIC_TASK_FIELDS.IS_ENABLED,
      field: (
        <CheckboxInput
          name={AUTOMATIC_TASK_FIELDS.IS_ENABLED}
          inputProps={{ disabled: isDisabled }}
        />
      ),
    },
    {
      name: AUTOMATIC_TASK_FIELDS.NAME,
      field: (
        <TextInput
          name={AUTOMATIC_TASK_FIELDS.NAME}
          inputProps={{ disabled: isDisabled, compressed: true }}
        />
      ),
    },
    {
      name: AUTOMATIC_TASK_FIELDS.TASK_TYPE,
      field: (
        <SelectInput
          name={AUTOMATIC_TASK_FIELDS.TASK_TYPE}
          inputProps={{
            options: automaticTaskTypeOptions,
            disabled: isDisabled,
            compressed: true,
          }}
        />
      ),
    },
    {
      name: AUTOMATIC_TASK_FIELDS.STATUS,
      field: (
        <>
          {task?.status
            ? Translate({ token: TASK_STATUS_NAME[task.status] })
            : translate['message.empty_value']}
        </>
      ),
    },
    {
      name: AUTOMATIC_TASK_FIELDS.EMAIL_LIST,
      field: (
        <SelectInput
          name={AUTOMATIC_TASK_FIELDS.EMAIL_LIST}
          inputProps={{
            options: emailListOptions,
            disabled: isDisabled,
            compressed: true,
          }}
        />
      ),
    },
    {
      name: AUTOMATIC_TASK_FIELDS.IS_SEND_NOTIFICATIONS,
      field: (
        <CheckboxInput
          name={AUTOMATIC_TASK_FIELDS.IS_SEND_NOTIFICATIONS}
          inputProps={{ disabled: isDisabled }}
        />
      ),
    },
    {
      name: AUTOMATIC_TASK_FIELDS.TRIGGER,
      field: (
        <SelectInput
          name={AUTOMATIC_TASK_FIELDS.TRIGGER}
          inputProps={{
            options: emailListOptions,
            disabled: true,
            compressed: true,
          }}
        />
      ),
    },
    {
      name: AUTOMATIC_TASK_FIELDS.START_AT,
      field: (
        <DatePicker
          name={AUTOMATIC_TASK_FIELDS.START_AT}
          inputProps={{ disabled: isDisabled, compressed: true, showTimeSelect: true }}
        />
      ),
    },
    {
      name: AUTOMATIC_TASK_FIELDS.CRONTAB_SCHEDULE,
      field: <CronInput name={AUTOMATIC_TASK_FIELDS.CRONTAB_SCHEDULE} isDisabled={isDisabled} />,
    },
    {
      name: AUTOMATIC_TASK_FIELDS.TASK_RULES,
      field: <JsonInput name={AUTOMATIC_TASK_FIELDS.TASK_RULES} isDisabled={isDisabled} />,
    },
    {
      name: AUTOMATIC_TASK_FIELDS.CREATED_BY,
      field: <>{task?.created_by || translate['message.empty_value']}</>,
    },
    {
      name: AUTOMATIC_TASK_FIELDS.CREATED_AT,
      field: <>{getLocalDate(task?.created_at) || translate['message.empty_value']}</>,
    },
    {
      name: AUTOMATIC_TASK_FIELDS.UPDATED_AT,
      field: <>{getLocalDate(task?.updated_at) || translate['message.empty_value']}</>,
    },
    {
      name: AUTOMATIC_TASK_FIELDS.LAST_LAUNCH_AT,
      field: <>{getLocalDate(task?.last_launch_at) || translate['message.empty_value']}</>,
    },
  ]

  const rows = rowsConfig.map(({ name, field }) => {
    return <InfoPageRow key={name} label={AUTOMATIC_TASK_FIELDS_NAMES[name]!} field={field} />
  })

  return rows
}

export { AutomaticTaskInfoRows }
