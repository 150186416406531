import { getCookie } from '@shared/lib'

import { type ApiUserPermissions } from './types'

export const API_BASE = '/backend/api/portal/rest'

export const API_LOGIN = '/login/'
export const API_LOGOUT = '/logout/'

export const API_ATTRIBUTE_LIST = '/attributes/'
export const API_ATTRIBUTE_VALUES = '/attribute_values/'
export const API_ATTRIBUTE_TYPES_LIST = '/attribute_types/'
export const API_OBJECT_GROUP_LIST = '/object_groups/'
export const API_CATEGORY_LIST = '/categories/'
export const API_SKU_LIST = '/skus/'
export const API_SKU_VALUES = '/sku_values/'

export const DEFAULT_HEADERS: HeadersInit = {
  'X-CSRFToken': getCookie('csrftoken') || '',
  Accept: 'application/json',
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum API_ATTRIBUTE_MODEL {
  SKU = 10,
  CATEGORY = 20,
  OBJECT_GROUP = 30,
}

export enum API_GROUP_TYPE {
  GROUP = 10,
  ROLE = 20,
}

export enum API_ATTRIBUTE_TYPE {
  TEXT = 10,
  LIST = 20,
  BOOL = 30,
  NESTED_LIST = 40,
  NUMBER = 50,
  FILE = 60,
  JSON = 70,
  DATE = 80,
  FLOAT = 90,
  LONG_TEXT = 100,
}

export enum API_OBJECT_GROUP {
  SKU = 1,
  CATEGORY = 2,
  FILE_UPLOAD = 3,
  ATTRIBUTE = 4,
}

export const API_QUERY_KEY = {
  ATTRIBUTE_GROUP_LIST: ['get', '/object_groups/', { params: { query: { size: 0, type: 4 } } }],
  ATTRIBUTE_GROUP: (id: number) => {
    return ['get', '/object_groups/{id}/', { params: { path: { id: id } } }]
  },
  ATTRIBUTE_LIST: ['get', '/attributes/'],
  ATTRIBUTE_OPTIONS_LIST: (id: number) => {
    return ['get', '/attribute_options/', { params: { query: { attribute: id } } }]
  },
  ATTRIBUTE_OPTION: (id: number) => {
    return ['get', '/attribute_options/{id}/', { params: { path: { id } } }]
  },
  CATEGORY_LIST: ['get', '/categories/'],
  CATEGORY: (id: number) => {
    return ['get', '/categories/{id}/', { params: { path: { id: id } } }]
  },
  SKU_LIST: ['get', '/skus/'],
  SKU: (id: number) => {
    return ['get', '/skus/{id}/', { params: { path: { id: id } } }]
  },
  VALIDATOR: (id: number) => {
    return ['get', '/validators/{id}/', { params: { path: { id: id } } }]
  },
  USERS: ['get', '/users/'],
  USER: (id: number) => {
    return ['get', '/users/{id}/', { params: { path: { id: id } } }]
  },
  IMPORT_FILES: ['get', '/master_list/'],
  AUTOMATIC_TASKS: ['get', '/automatic_task/'],
  AUTOMATIC_TASK: (id: number) => {
    return ['get', '/automatic_task/{id}/', { params: { path: { id: id } } }]
  },
}

export enum ATTRIBUTE_FLAGS {
  SKU = 'is_sku',
  NAME = 'is_name',
  BRAND = 'is_brand',
  PREVIEW = 'is_preview',
}

export const PERMISSIONS: Record<string, ApiUserPermissions> = {
  CAN_VIEW_CATALOG: 'can_view_catalog',
  CAN_VIEW_ATTRIBUTES: 'can_view_attributes',
  CAN_ADD_ATTRIBUTES: 'can_add_attributes',
  CAN_VIEW_OBJECT_GROUPS: 'can_view_object_groups',
  CAN_ADD_OBJECT_GROUPS: 'can_add_object_groups',
}
