import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  transparentize,
  useEuiTheme,
} from '@elastic/eui'
import { ROUTES, TASK_STATUS, TASK_STATUS_NAME } from '@shared/config'
import { Translate } from '@shared/ui/translate'
import { Link } from 'react-router'

import { TaskCardSubInfo } from './task-card-sub-info'
import { type AutomaticTaskCardProps } from './types'

const AutomaticTaskCard: ReactFc<AutomaticTaskCardProps> = ({
  id,
  name,
  created_at,
  created_by,
  last_launch_at,
  task_type,
  status,
}) => {
  const { euiTheme } = useEuiTheme()

  const badgeColor = transparentize(
    status == TASK_STATUS.PLANNED ? euiTheme.colors.warning : euiTheme.colors.success,
    0.3,
  )

  return (
    <EuiPanel>
      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
        <EuiFlexItem grow={0}>
          <Link to={ROUTES.SETTINGS.AUTOMATIC_TASKS.AUTOMATIC_TASK.$buildPath({ params: { id } })}>
            {name}
          </Link>
        </EuiFlexItem>

        <EuiFlexItem grow={0}>
          <EuiBadge color={badgeColor}>
            {status ? Translate({ token: TASK_STATUS_NAME[status] }) : null}
          </EuiBadge>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="m" />

      <TaskCardSubInfo
        createdAt={created_at}
        createdBy={created_by}
        taskType={task_type}
        lastLaunchAt={last_launch_at}
      />
    </EuiPanel>
  )
}

export { AutomaticTaskCard }
