import { EuiSideNav } from '@elastic/eui'
import { useTranslate } from '@shared/ui/translate'

import { useSidebarMenuItems } from '../model'

export const SidebarNav = () => {
  const sidebarMenuItems = useSidebarMenuItems()
  const translate = useTranslate(['label.menu'])

  return (
    <EuiSideNav
      aria-label={translate['label.menu']}
      mobileTitle={translate['label.menu']}
      items={sidebarMenuItems}
    />
  )
}
