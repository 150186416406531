import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPageSection } from '@elastic/eui'
import { useSettingsSkusColumns, useSkusList } from '@entities/catalog'
import { type Sku } from '@entities/catalog/types'
import { ROUTES } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { Pagination, Search, usePagination } from '@shared/ui'
import { Table, useSortTable } from '@shared/ui/table'
import { useTranslate } from '@shared/ui/translate'
import { useTypedParams } from 'react-router-typesafe-routes'

const CategorySkusTab: ReactFc = () => {
  const { id: categoryId } = useTypedParams(ROUTES.SETTINGS.CATEGORIZATION.CATEGORY)
  const { sorting, sortList, onTableChange } = useSortTable<Sku>()
  const { params } = useRouterParams()
  const columns = useSettingsSkusColumns()
  const translate = useTranslate(['action.find_sku'])

  const { isLoading, data: skusList } = useSkusList({
    categories: [categoryId],
    ...params,
  })

  const paginationProps = usePagination(skusList?.count)

  return (
    <EuiPageSection paddingSize="none">
      <EuiFlexGroup alignItems="stretch">
        <EuiFlexItem grow={false}>
          <Search searchParam="search" placeholder={translate['action.find_sku']} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule margin="s" />

      <Table
        columns={columns}
        items={sortList(skusList.results || [])}
        sorting={sorting}
        onChange={onTableChange}
        loading={isLoading}
      />

      <Pagination {...paginationProps} />
    </EuiPageSection>
  )
}

export { CategorySkusTab }
