import { EuiSpacer, EuiText, EuiTextTruncate } from '@elastic/eui'
import { type Sku } from '@entities/catalog/types'
import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { fontSemiBold, noTextDecoration } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'
import { Link } from 'react-router'

import { ProgressWithStatus } from '../progress-with-status'
import { SkuCardImage } from './sku-card-image'
import { SkuCardWrapper } from './sku-card-wrapper'

export const SkuCard: ReactFc<Sku> = (props) => {
  const { href, name, progress, status } = props
  const translate = useTranslate(['message.empty_name', 'message.empty_value'])

  const cid = extractAttribute(props, ATTRIBUTE_FLAGS.SKU)?.value || translate['message.empty_name']

  return (
    <SkuCardWrapper>
      <SkuCardImage {...props} />
      <EuiSpacer size="l" />

      <Link to={href} css={noTextDecoration}>
        <EuiText css={fontSemiBold} color="default">
          <EuiTextTruncate text={name || translate['message.empty_name']} />
        </EuiText>
      </Link>

      <EuiSpacer size="s" />
      <EuiText>
        <EuiTextTruncate text={cid || translate['message.empty_value']} />
      </EuiText>

      <EuiSpacer size="s" />
      <ProgressWithStatus progress={progress} status={status} variant="full" />
    </SkuCardWrapper>
  )
}
