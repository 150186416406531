import { type ApiAttributeList } from '@shared/api/types'
import { Translate } from '@shared/ui/translate'

import { AttributeGroupName } from './attribute-group-name'

const renderAttributeGroupName: RenderColumn<ApiAttributeList, 'object_group'> = (groupId) => {
  if (!groupId) {
    return <Translate token="message.empty_value" />
  }

  return <AttributeGroupName groupId={groupId} />
}

export { renderAttributeGroupName }
