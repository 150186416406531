import { type EuiBasicTableColumn } from '@elastic/eui'
import { useTranslate } from '@shared/ui/translate'

import { renderAttributeGroupsName } from '../lib'
import { type AttributeGroup } from '../types'

export const useAttributeGroupsColumns = (): EuiBasicTableColumn<AttributeGroup>[] => {
  const translate = useTranslate(['label.order', 'label.name', 'label.attributes_in_group'])

  return [
    {
      field: 'order',
      name: translate['label.order'],
      width: '120px',
    },
    {
      field: 'name',
      name: translate['label.name'],
      render: renderAttributeGroupsName,
    },
    {
      field: 'attributes_count',
      name: translate['label.attributes_in_group'],
    },
  ]
}
