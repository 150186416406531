import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { AddUser } from '@features/users'
import { useDialog } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'
import { useTranslate } from '@shared/ui/translate'

const UsersActions: ReactFc = () => {
  const {
    isOpen: isAddUserModalOpen,
    handleOpenDialog: showAddUserModal,
    handleCloseDialog: closeAddUserModal,
  } = useDialog()

  const translate = useTranslate(['action.new_user', 'action.actions'])

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: translate['action.new_user'],
          icon: 'plus',
          onClick: showAddUserModal,
        },
      ],
    },
  ]

  return (
    <>
      <ContextMenu
        menuButtonText={translate['action.actions']}
        panels={panels}
        endIcon="arrowDown"
      />
      <AddUser isOpen={isAddUserModalOpen} onClose={closeAddUserModal} />
    </>
  )
}

export { UsersActions }
