import { number, route } from 'react-router-typesafe-routes'

import { APP_NAME } from './constants'

export const ROUTES = {
  CATALOG: route({
    path: 'catalog',
    children: {
      SKU: route({
        path: ':id',
        params: { id: number().defined() },
        children: {
          PRINT: route({ path: 'print' }),
        },
      }),
    },
  }),
  DAM: route({ path: 'dam' }), // Маршрут для DAM
  SETTINGS: route({
    path: 'settings',
    children: {
      ATTRIBUTE_GROUPS: route({
        path: 'attribute-groups',
        children: {
          GROUP: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      ATTRIBUTES: route({
        path: 'attributes',
        children: {
          ATTRIBUTE: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      VALIDATORS: route({
        path: 'validators',
        children: {
          VALIDATOR: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      USERS: route({
        path: 'users',
        children: {
          USER: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      CATEGORIZATION: route({
        path: 'categorization',
        children: {
          CATEGORY: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      AUTOMATIC_TASKS: route({
        path: 'automatic_tasks',
        children: {
          AUTOMATIC_TASK: route({
            path: ':id',
            params: {
              id: number().defined(), // Параметр id: число, обязательный
            },
          }),
        },
      }),
      CLUSTERING: route({ path: 'clustering' }),
      CURRENCIES_AND_PRICES: route({ path: 'currencies-and-prices' }),
      INFO_MODELS: route({ path: 'info-models' }),
      RELATED_PRODUCTS: route({ path: 'related-products' }),
      RULES: route({ path: 'rules' }),
      TAXONOMY: route({ path: 'taxonomy' }),
      TEMPLATES: route({ path: 'templates' }),
      UNITS: route({ path: 'units' }),
      VALUE_LISTS: route({ path: 'value-lists' }),
    },
  }),
  EXPORT: route({
    path: 'export',
    children: {
      FILES: route({ path: 'exported-files' }),
    },
  }),
  IMPORT: route({ path: 'import' }),
  LOGIN: route({ path: 'login' }),
  LOGOUT: route({ path: 'logout' }),
  PERMISSION_ERROR: route({ path: 'permission-error' }),
  ERROR: route({ path: '*' }),
}

export enum PAGE_TITLES {
  SKUS = 'label.skus',
  CATALOG = 'label.catalog',
  SETTINGS = 'label.settings',
  SETTINGS_ATTRIBUTES = 'label.attributes',
  SETTINGS_ATTRIBUTE_GROUPS = 'label.attribute_groups',
  SETTINGS_CATEGORIZATION = 'label.categorization',
  SETTINGS_VALIDATORS = 'label.validators',
  SETTINGS_VALIDATOR = 'label.validator',
  SETTINGS_USERS = 'label.users',
  SETTINGS_USER = 'label.user',
  SETTINGS_AUTOMATIC_TASKS = 'label.automatic_tasks',
  EXPORT = 'label.export',
  EXPORT_FILES = 'label.files',
  IMPORT = 'label.import',
  LOGIN = 'action.login',
  LOGOUT = 'label.exit',
  ERROR = 'message.error',
  PERMISSION_ERROR = 'label.permissions_denied',
  SUFFIX = ` | ${APP_NAME}`,
  LOADING = `…`,
}
