import { type EuiBasicTableColumn, type EuiTableFieldDataColumnType } from '@elastic/eui'
import { API_ATTRIBUTE_MODEL, useAttributeListApi } from '@shared/api'
import { SKU } from '@shared/config'
import { isNotNull } from '@shared/lib'
import { useTranslate } from '@shared/ui/translate'

import { renderAttribute, renderImage, renderProgress } from '../lib'
import { type Sku } from '../types'

export const useCatalogColumns = (): Array<EuiBasicTableColumn<Sku>> => {
  const { isSuccess, data: skuAttributes } = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.SKU,
    ordering: 'id',
  })

  const translate = useTranslate(['label.progress'])

  if (!isSuccess) {
    return []
  }

  const progressColumn = {
    field: SKU.PROGRESS,
    name: translate['label.progress'],
    width: '150px',
    render: renderProgress,
  }

  let previewIndex = -1

  const columns = skuAttributes.results
    .sort((firstAttribute, secondAttribute) => {
      // Сортируем по is_preview, is_name, is_sku и затем по id
      if (firstAttribute.is_preview !== secondAttribute.is_preview) {
        return firstAttribute.is_preview ? -1 : 1
      }

      if (firstAttribute.is_name !== secondAttribute.is_name) {
        return firstAttribute.is_name ? -1 : 1
      }

      if (firstAttribute.is_sku !== secondAttribute.is_sku) {
        return firstAttribute.is_sku ? -1 : 1
      }

      return firstAttribute.id - secondAttribute.id
    })
    .map((attribute, index) => {
      // Убираем атрибуты без slug и не featured
      if (!attribute.slug || !attribute.is_featured) {
        return null
      }

      const column: EuiTableFieldDataColumnType<Sku> = {
        field: attribute.slug,
        name: attribute.name,
        render: renderAttribute,
      }

      // Колонка с превью
      if (attribute.is_preview) {
        column.width = '100px'
        column.render = renderImage
        column.sortable = false

        previewIndex = index
      }

      return column
    })
    .filter(isNotNull)

  columns.splice(previewIndex + 1, 0, progressColumn)

  return columns
}
