import { type EuiBasicTableColumn } from '@elastic/eui'
import { Translate } from '@shared/ui/translate'

import { renderUserName } from '../lib'
import { type UsersTableRow } from '../types'

// TODO: Добавить динамические поля и переводы
export const USERS_COLUMNS: EuiBasicTableColumn<UsersTableRow>[] = [
  {
    field: 'username',
    name: Translate({ token: 'label.user_name' }),
    render: renderUserName,
  },
  {
    field: 'name',
    name: 'Имя',
  },
  {
    field: 'surname',
    name: 'Фамилия',
  },
  {
    field: 'email',
    name: 'Email',
  },
]
