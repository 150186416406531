import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiValidatorRequest } from '@shared/api/types'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'

import { useUpdateValidatorApi } from '../api'

const useUpdateValidator = (validatorId: number, onSuccessCallback?: EmptyCallback) => {
  const { mutate: updateValidator, isPending: isSubmitting } = useUpdateValidatorApi()
  const addNotification = useNotificationsStore.useAddNotification()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.VALIDATOR(validatorId) })
    addNotification(NOTIFICATION.VALIDATOR_UPDATE_SUCCESS)

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const handleUpdateValidator = (validatorData: ApiValidatorRequest) => {
    return updateValidator(
      {
        params: {
          path: {
            id: validatorId,
          },
        },
        body: validatorData,
      },
      { onSuccess },
    )
  }

  return {
    handleUpdateValidator,
    isSubmitting,
  }
}

export { useUpdateValidator }
