import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiAttributeList } from '@shared/api/types'
import { useTranslate } from '@shared/ui/translate'

import { renderAttributeName, renderAttributeType } from '../lib'

export const useAttributeGroupColumns = (): EuiBasicTableColumn<ApiAttributeList>[] => {
  const translate = useTranslate(['label.attribute_name', 'label.attribute_type'])

  return [
    {
      field: 'name',
      name: translate['label.attribute_name'],
      render: renderAttributeName,
    },
    {
      field: 'attribute_type',
      name: translate['label.attribute_type'],
      render: renderAttributeType,
    },
  ]
}
