import { EuiForm } from '@elastic/eui'
import {
  type EmailList,
  getAutomaticTaskTypeOptions,
  useEmailListsApi,
} from '@entities/automatic_tasks'
import { DATE_FORMAT_FULL } from '@shared/config'
import { getSelectInputOptions } from '@shared/lib'
import {
  CheckboxInput,
  CronInput,
  DatePicker,
  FormError,
  SelectInput,
  TextInput,
} from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import moment from 'moment'
import { FormProvider } from 'react-hook-form'

import { useAddAutomaticTask } from '../model'
import { type AddTaskModalProps } from './types'

const AddTaskModal: ReactFc<AddTaskModalProps> = ({ isOpen, onClose }) => {
  const { data: emailData, isLoading: isEmailListsLoading } = useEmailListsApi()

  const translate = useTranslate([
    'action.new_task',
    'action.type_select',
    'action.select_list',
    'action.select_trigger',
    'action.select_date',
    'label.type',
    'label.name',
    'label.email_list',
    'label.send_notification',
    'label.trigger',
    'label.start_date',
    'label.repeatability',
    'message.enter_type',
    'message.enter_name',
  ])

  const { handleAddAutomaticTask, handleModalClose, methods, isSubmitting } = useAddAutomaticTask({
    onClose,
  })

  const taskTypeOptions = getAutomaticTaskTypeOptions()

  const emailListOptions = getSelectInputOptions<EmailList>({
    items: emailData,
    valueKey: 'id',
    inputDisplayKey: 'name',
  })

  return (
    <FormProvider {...methods}>
      <Modal
        title={translate['action.new_task']}
        onClose={handleModalClose}
        onSubmit={handleAddAutomaticTask}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <SelectInput
            name="task_type"
            placeholder={translate['action.type_select']}
            label={translate['label.type']}
            registerOptions={{ required: translate['message.enter_type'] }}
            inputProps={{
              options: taskTypeOptions,
              disabled: isSubmitting,
              compressed: true,
            }}
          />

          <TextInput
            name="name"
            placeholder={translate['label.name']}
            registerOptions={{ required: translate['message.enter_name'] }}
            inputProps={{
              disabled: isSubmitting,
              compressed: true,
            }}
          />

          <SelectInput
            name="email_list"
            placeholder={translate['action.select_list']}
            label={translate['label.email_list']}
            inputProps={{
              options: emailListOptions,
              isLoading: isEmailListsLoading,
              disabled: isSubmitting,
              compressed: true,
            }}
          />

          <CheckboxInput
            name="is_send_notifications"
            inputProps={{ label: translate['label.send_notification'] }}
          />

          {/* TODO: добавить актуальные данные после доработок с бэка */}
          <SelectInput
            name="trigger"
            placeholder={translate['action.select_trigger']}
            label={translate['label.trigger']}
            inputProps={{
              options: emailListOptions,
              isLoading: isEmailListsLoading,
              disabled: true,
              compressed: true,
            }}
          />

          <DatePicker
            name="start_at"
            label={translate['label.start_date']}
            inputProps={{
              compressed: true,
              placeholder: translate['action.select_date'],
              minDate: moment(),
              showTimeSelect: true,
              dateFormat: DATE_FORMAT_FULL,
            }}
          />

          <CronInput name="crontab_schedule" label={translate['label.repeatability']} />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddTaskModal }
