import { EuiButtonEmpty } from '@elastic/eui'
import { useDeleteAutomaticTaskApi } from '@entities/automatic_tasks'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { ROUTES } from '@shared/config'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { Translate } from '@shared/ui/translate'
import { useNavigate } from 'react-router'
import { useTypedParams } from 'react-router-typesafe-routes'

const DeleteAutomaticTask: ReactFc = () => {
  const { id: taskId } = useTypedParams(ROUTES.SETTINGS.AUTOMATIC_TASKS.AUTOMATIC_TASK)
  const { mutate: deleteTask, isPending: isTaskDeleting } = useDeleteAutomaticTaskApi()
  const addNotification = useNotificationsStore.useAddNotification()

  const navigate = useNavigate()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.AUTOMATIC_TASKS })
    navigate(ROUTES.SETTINGS.AUTOMATIC_TASKS.$path())
    addNotification(NOTIFICATION.TASK_REMOVE_SUCCESS)
  }

  const handleDeleteTask = () => {
    return deleteTask(
      {
        params: {
          path: {
            id: taskId,
          },
        },
      },
      { onSuccess },
    )
  }

  return (
    <EuiButtonEmpty
      size="s"
      color="danger"
      iconType="trash"
      isLoading={isTaskDeleting}
      onClick={handleDeleteTask}
    >
      <Translate token="action.delete" />
    </EuiButtonEmpty>
  )
}

export { DeleteAutomaticTask }
