import { EuiEmptyPrompt, EuiLoadingSpinner, EuiPageSection } from '@elastic/eui'
import { ROUTES } from '@shared/config'
import { Layout } from '@shared/ui/layouts'
import { minHViewport } from '@shared/ui/styles'
import { Translate } from '@shared/ui/translate'
import { Navigate, useMatches } from 'react-router'

import { useUserPermissions } from '../model'
import { type PermissionsMatches } from './types'

const PermissionProvider: ReactFc<React.PropsWithChildren> = ({ children }) => {
  const { isLoading, isError, permissions } = useUserPermissions()
  const matches = useMatches() as PermissionsMatches

  const hasPermission = matches.every((match) => {
    if (permissions && match?.handle?.permission) {
      return permissions[match.handle.permission]
    }

    return true // Если маршрут не требует прав, пропускаем его
  })

  if (isError) {
    return (
      <Layout>
        <EuiPageSection alignment="center" grow css={minHViewport}>
          <EuiEmptyPrompt
            body={
              <p>
                <Translate token="error.server" />
              </p>
            }
          />
        </EuiPageSection>
      </Layout>
    )
  }

  if (isLoading) {
    return (
      <Layout>
        <EuiPageSection alignment="center" grow css={minHViewport}>
          <EuiEmptyPrompt body={<EuiLoadingSpinner size="xl" />} />
        </EuiPageSection>
      </Layout>
    )
  }

  // Если хотя бы один маршрут требует права, которых у пользователя нет, перенаправляем на страницу ошибки
  if (!hasPermission) {
    return <Navigate to={ROUTES.PERMISSION_ERROR.$path()} />
  }

  return <>{children}</>
}

export { PermissionProvider }
