import { type ApiAttributeList } from '@shared/api/types'
import { InfoTooltip } from '@shared/ui'
import { Translate } from '@shared/ui/translate'

import { ATTRIBUTE_TYPE_HELP } from './attribute-type-help'
import { ATTRIBUTE_TYPE_MAP } from './attribute-type-map'

export const renderAttributeType: RenderColumn<ApiAttributeList, 'attribute_type'> = (type) => {
  if (!type) {
    return null
  }

  return (
    <InfoTooltip tooltip={<Translate token={ATTRIBUTE_TYPE_HELP[type].TOOLTIP} />}>
      <Translate token={ATTRIBUTE_TYPE_MAP[type]} />
    </InfoTooltip>
  )
}
