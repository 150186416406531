import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'
import { ValidatorInfo } from '@widgets/settings'

const ValidatorPage: ReactFc = () => {
  const translate = useTranslate([PAGE_TITLES.SETTINGS_VALIDATOR])
  useMeta({ title: translate[PAGE_TITLES.SETTINGS_VALIDATOR] })

  return (
    <Layout>
      <ValidatorInfo />
    </Layout>
  )
}

export { ValidatorPage }
