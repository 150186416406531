import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiTab, EuiTabs } from '@elastic/eui'
import { useTabs } from '@shared/hooks'
import { useNotificationsStore } from '@shared/ui/notifications'
import { minHFull } from '@shared/ui/styles'

import { SkuDates } from './sku-dates'
import { useSkuTabsList } from './use-sku-tabs-list'

export const SkuTabs: ReactFc = () => {
  const skuTabsList = useSkuTabsList()

  const { selectedTabId, selectedTabContent, onSelectedTabChanged } = useTabs(
    skuTabsList,
    skuTabsList[0].id,
  )

  const canLeave = useNotificationsStore.useCanLeave()
  const setOnLeave = useNotificationsStore.useSetOnLeave()

  const getTabClickHandler = (tabId: string) => {
    return () => {
      if (!canLeave) {
        setOnLeave(() => {
          onSelectedTabChanged(tabId)
        })

        return
      }

      onSelectedTabChanged(tabId)
    }
  }

  const renderTabs = () => {
    return skuTabsList.map((tab, index) => {
      return (
        <EuiTab
          key={index}
          onClick={getTabClickHandler(tab.id)}
          isSelected={tab.id === selectedTabId}
        >
          {tab.name}
        </EuiTab>
      )
    })
  }

  return (
    <EuiPanel paddingSize="none">
      <EuiFlexGroup direction="column" gutterSize="none" css={minHFull}>
        <EuiFlexItem grow={false}>
          <EuiPanel hasShadow={false}>
            <SkuDates />
            <EuiTabs>{renderTabs()}</EuiTabs>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel color="subdued">{selectedTabContent}</EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
