import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import {
  AddAttributeToGroup,
  RemoveAttributeFromGroup,
  UpdateAttributeGroup,
} from '@features/attributes'
import { Search } from '@shared/ui'
import { noShadow } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'

export const AttributeGroupHeader: ReactFc = () => {
  const translate = useTranslate(['action.find_attribute'])

  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={translate['action.find_attribute']} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSectionItem>
        <RemoveAttributeFromGroup />
        <AddAttributeToGroup />
        <UpdateAttributeGroup />
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
