import { EuiPageSection } from '@elastic/eui'
import { useAttributesColumns } from '@entities/attributes'
import { type ApiAttributeList } from '@shared/api/types'
import { Pagination, usePagination } from '@shared/ui'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'

import { useAttributesData } from '../model'

export const AttributesTable: ReactFc = () => {
  const { sorting, sortList, onTableChange } = useSortTable<ApiAttributeList>()
  const { selection } = useSelectionTable<ApiAttributeList>()
  const { isLoading, attributesList, attributesCount } = useAttributesData()
  const attributesColumns = useAttributesColumns()

  const paginationProps = usePagination(attributesCount)

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={attributesColumns}
        items={sortList(attributesList)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />

      <Pagination {...paginationProps} />
    </EuiPageSection>
  )
}
