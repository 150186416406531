import {
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiHorizontalRule,
  EuiSkeletonLoading,
} from '@elastic/eui'
import { useAttributeGroupsList } from '@entities/attributes'
import { SkuTabSkeleton, useSkuApi } from '@entities/catalog'
import {
  SkuAttributeGroups,
  useSearchFilterAttributes,
  useSkuUpdateAttributes,
  useValueFilterOptions,
} from '@features/catalog'
import { PARAMS, ROUTES } from '@shared/config'
import { renderMultiple } from '@shared/lib'
import { FilterPopover, LeaveNotificationModal, Search } from '@shared/ui'
import { SaveButton } from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

const SkuAttributesTab: ReactFc = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { isLoading: isSkuLoading } = useSkuApi(skuId)
  const { isLoading: isAttributesGroupsLoading } = useAttributeGroupsList()

  const isLoading = isSkuLoading || isAttributesGroupsLoading

  // Фильтры и поиск
  const { attributeTypePopoverOptions, notEmptyAttributeGroups } = useSearchFilterAttributes()
  const attributeValuesPopoverOptions = useValueFilterOptions()

  const isEmptyAttributeGroups = notEmptyAttributeGroups.length === 0

  // Сохранение
  const { methods, changed, updateSkuHandler, isSubmitting } = useSkuUpdateAttributes()

  const translate = useTranslate([
    'label.attributes',
    'action.find_attribute',
    'label.attribute_type',
    'label.value',
    'message.empty',
  ])

  return (
    <EuiSkeletonLoading
      isLoading={isLoading}
      contentAriaLabel={translate['label.attributes']}
      loadingContent={<>{renderMultiple(SkuTabSkeleton, 5)}</>}
      loadedContent={
        <>
          <EuiFlexGroup gutterSize="m">
            <EuiFlexItem grow={false}>
              <Search searchParam="search" placeholder={translate['action.find_attribute']} />
            </EuiFlexItem>

            <EuiFilterGroup compressed>
              <FilterPopover
                label={translate['label.attribute_type']}
                param={PARAMS.ATTRIBUTE_TYPE}
                options={attributeTypePopoverOptions}
                searchable={false}
              />
            </EuiFilterGroup>

            <EuiFilterGroup compressed>
              <FilterPopover
                label={translate['label.value']}
                param={PARAMS.ATTRIBUTE_VALUE}
                options={attributeValuesPopoverOptions}
                searchable={false}
                isSingleSelect
              />
            </EuiFilterGroup>

            <EuiFlexItem>
              <EuiFlexGroup justifyContent="flexEnd">
                <SaveButton
                  isActive={changed.length > 0}
                  onClick={updateSkuHandler}
                  isLoading={isSubmitting}
                />
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiHorizontalRule margin="s" />

          {!isLoading && isEmptyAttributeGroups && translate['message.empty']}

          <FormProvider {...methods}>
            <EuiForm component="form" onSubmit={updateSkuHandler}>
              <SkuAttributeGroups isLoading={isLoading} isSubmitting={isSubmitting} />
            </EuiForm>
          </FormProvider>

          <LeaveNotificationModal
            onSave={updateSkuHandler}
            isDirty={changed.length > 0}
            isSubmitting={isSubmitting}
          />
        </>
      }
    />
  )
}

export { SkuAttributesTab }
