import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'
import { ExportFilesList } from '@widgets/export'

const FilesPage: ReactFc = () => {
  const translate = useTranslate([PAGE_TITLES.EXPORT_FILES])
  useMeta({ title: translate[PAGE_TITLES.EXPORT_FILES] })

  return (
    <Layout>
      <ExportFilesList />
    </Layout>
  )
}

export { FilesPage }
