import { API_ATTRIBUTE_MODEL, useAttributeListApi } from '@shared/api'
import { SKU } from '@shared/config'
import { type FilterPopoverOptions } from '@shared/ui/filter-popover'
import { useTranslate } from '@shared/ui/translate'

const useCatalogFiltersList = (): FilterPopoverOptions => {
  const { isLoading, data: skuAttributes } = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.SKU,
  })

  const translate = useTranslate([
    'label.progress',
    'label.public',
    'label.create_date',
    'label.update_date',
  ])

  if (isLoading) {
    return []
  }

  const progressOption = {
    label: translate['label.progress'],
    data: {
      id: SKU.PROGRESS,
    },
  }

  const publicOption = {
    label: translate['label.public'],
    data: {
      id: 'public',
    },
  }

  const createDateOption = {
    label: translate['label.create_date'],
    data: {
      id: 'created_at',
    },
  }

  const updateDateOption = {
    label: translate['label.update_date'],
    data: {
      id: 'updated_at',
    },
  }

  const attributesOptions = skuAttributes?.results.map((attribute) => {
    return {
      label: attribute.name,
      data: {
        id: attribute.slug,
      },
    }
  })

  return [
    progressOption,
    publicOption,
    createDateOption,
    updateDateOption,
    ...(attributesOptions || []),
  ]
}

export { useCatalogFiltersList }
