import { EuiSkeletonLoading } from '@elastic/eui'
import { SkuTabSkeleton, useSkuApi } from '@entities/catalog'
import { SkuFileAttribute } from '@features/catalog'
import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { ROUTES } from '@shared/config'
import { renderMultiple } from '@shared/lib'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider, useForm } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

const SkuFilesTab: ReactFc = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { data: sku, isLoading } = useSkuApi(skuId)
  const methods = useForm()
  const translate = useTranslate(['label.images_files'])

  // Фильтруем файловые атрибуты
  const skuFileAttributes = sku?.attributes.filter((attribute) => {
    return attribute.attribute_type === API_ATTRIBUTE_TYPE.FILE
  })

  return (
    <EuiSkeletonLoading
      isLoading={isLoading}
      contentAriaLabel={translate['label.images_files']}
      loadingContent={<>{renderMultiple(SkuTabSkeleton, 5)}</>}
      loadedContent={
        <FormProvider {...methods}>
          {skuFileAttributes?.map((attribute, index) => {
            return (
              <SkuFileAttribute
                key={attribute.id}
                attribute={attribute}
                index={index}
                isLoading={isLoading}
              />
            )
          })}
        </FormProvider>
      }
    />
  )
}

export { SkuFilesTab }
