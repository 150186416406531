import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'
import {
  AttributeFormField,
  useUpdateAttributeFiles,
  useUploadAttributeValue,
} from '@entities/attributes'
import { FileCard } from '@entities/files'
import { Accordion } from '@shared/ui/accordion'
import { Fragment } from 'react'

import { type SkuFileAttributeProps } from './types'

const SkuFileAttribute: ReactFc<SkuFileAttributeProps> = ({ attribute, index, isLoading }) => {
  // Загрузка файла
  const { isFileSubmitting } = useUploadAttributeValue()

  // Удаляем файл, обновляя attribute_value
  const { updateAttributeFiles, isRemoving } = useUpdateAttributeFiles()

  const hasFiles = attribute.files

  return (
    <Fragment key={`attribute-${attribute.id}`}>
      <Accordion
        id={attribute.id.toString()}
        buttonContent={attribute.name}
        paddingSize="l"
        initialIsOpen={index === 0}
        isLoading={isLoading}
      >
        <EuiFlexGroup gutterSize="s" wrap>
          {/* Если есть файлы, выводим их на экран */}
          {hasFiles &&
            attribute.files.map((file) => {
              return (
                <FileCard
                  key={file.id}
                  {...file}
                  onRemove={updateAttributeFiles({ attribute, file })}
                  isRemoving={isRemoving}
                  isEditable={attribute.is_editable}
                />
              )
            })}

          {/* Форма загрузки файла */}
          <EuiFlexItem grow={3}>
            <AttributeFormField
              attribute={attribute}
              disablePlaceholder
              isSubmitting={isFileSubmitting}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </Accordion>
      <EuiHorizontalRule margin="s" />
    </Fragment>
  )
}

export { SkuFileAttribute }
