import { userSettingsStore, useUserSettingsStore } from '@entities/user/store/user-settings-store'
import { $api } from '@shared/api'
import { TranslateMapper } from '@shared/ui/translate'
import { type PropsWithChildren } from 'react'

const TranslateProvider: ReactFc<PropsWithChildren> = ({ children }) => {
  const changeLanguage = useUserSettingsStore.useChangeLanguage()
  const language = userSettingsStore.getState().language

  const { data } = $api.useQuery('get', '/user_settings/')
  changeLanguage(data?.settings?.language || '')

  return <TranslateMapper language={language}>{children}</TranslateMapper>
}

export { TranslateProvider }
