import { EuiForm } from '@elastic/eui'
import { FormError, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'

import { useUpdateAttributeOption } from '../model'
import { type UpdateAttributeOptionModalProps } from './types'

const UpdateAttributeOptionModal: ReactFc<UpdateAttributeOptionModalProps> = ({
  attributeId,
  optionId,
  isOpen,
  closeModal: handleCloseDialog,
}) => {
  const { methods, updateOption, closeModal, isSubmitting } = useUpdateAttributeOption(
    attributeId,
    optionId,
    handleCloseDialog,
  )

  const translate = useTranslate([
    'action.edit_attribute_option',
    'action.save',
    'label.name',
    'message.enter_name',
  ])

  return (
    <FormProvider {...methods}>
      <Modal
        title={translate['action.edit_attribute_option']}
        submitButtonText={translate['action.save']}
        onClose={closeModal}
        onSubmit={updateOption}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <TextInput
            name="name"
            placeholder={translate['label.name']}
            registerOptions={{ required: translate['message.enter_name'] }}
            inputProps={{
              disabled: isSubmitting,
            }}
            isRequired
          />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { UpdateAttributeOptionModal }
