import { EuiButtonEmpty, EuiPopover, EuiToolTip } from '@elastic/eui'
import { css } from '@emotion/react'
import { useUpdateImportFileApi } from '@entities/import-files'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { DATE_FORMAT } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { DatePicker } from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'

import { type StartTimePickerProps } from './types'

const datePickerButton = css`
  padding: 0;
  background-color: transparent !important;
`

const StartTimePicker: ReactFc<StartTimePickerProps> = ({ isDisabled = false, fileId }) => {
  const { mutate: setStartTime, isPending: isSubmitting } = useUpdateImportFileApi()
  const { isOpen, handleOpenDialog, handleCloseDialog } = useDialog()
  const translate = useTranslate(['message.import_date'])

  const { getValues } = useFormContext()

  const value = getValues('scheduled_to')

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.IMPORT_FILES })
    handleCloseDialog()
  }

  const handleDatePick = (date: moment.Moment) => {
    setStartTime(
      {
        params: {
          path: {
            id: fileId,
          },
        },
        body: {
          scheduled_to: date.format('YYYY-MM-DD'),
        },
      },
      { onSuccess },
    )
  }

  const button = (
    <EuiToolTip position="top" content={translate['message.import_date']}>
      <EuiButtonEmpty
        iconType="timeslider"
        onClick={handleOpenDialog}
        isDisabled={isDisabled}
        isLoading={isSubmitting}
        size="s"
        color="text"
        css={datePickerButton}
      >
        {value ? moment(value).format(DATE_FORMAT) : null}
      </EuiButtonEmpty>
    </EuiToolTip>
  )

  return (
    <EuiPopover button={button} isOpen={isOpen} closePopover={handleCloseDialog}>
      <DatePicker
        name="scheduled_to"
        inputProps={{
          minDate: moment(),
          onChange: handleDatePick,
          isLoading: isSubmitting,
          controlOnly: true,
          inline: true,
        }}
      />
    </EuiPopover>
  )
}

export { StartTimePicker }
