import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'
import { CategorizationHeader, CategorizationTable } from '@widgets/settings'

export const CategorizationPage: ReactFc = () => {
  const translate = useTranslate([PAGE_TITLES.SETTINGS_CATEGORIZATION])
  useMeta({ title: translate[PAGE_TITLES.SETTINGS_CATEGORIZATION] })

  return (
    <Layout header={<CategorizationHeader />}>
      <CategorizationTable />
    </Layout>
  )
}
