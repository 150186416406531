import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'

import { useUpdateCategory } from '../model'
import { CategoryForm } from './category-form'
import { type UpdateCategoryModalProps } from './types'

const UpdateCategoryModal: ReactFc<UpdateCategoryModalProps> = ({ id, isOpen, closeModal }) => {
  const { methods, updateCategory, isSubmitting } = useUpdateCategory(id, closeModal)
  const translate = useTranslate(['action.edit_category', 'action.save'])

  return (
    <FormProvider {...methods}>
      <Modal
        title={translate['action.edit_category']}
        submitButtonText={translate['action.save']}
        onClose={closeModal}
        onSubmit={updateCategory}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <CategoryForm id={id} isSubmitting={isSubmitting} />
      </Modal>
    </FormProvider>
  )
}

export { UpdateCategoryModal }
