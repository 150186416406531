import { EuiContextMenuItem, EuiIcon } from '@elastic/eui'
import { Translate } from '@shared/ui/translate'

import { type MenuItemUpdateAttributeOptionProps } from './types'

const MenuItemUpdateAttributeOption: ReactFc<MenuItemUpdateAttributeOptionProps> = ({
  showModal,
  closeContextMenu,
}) => {
  const onClick = () => {
    showModal()

    if (closeContextMenu) {
      closeContextMenu()
    }
  }

  return (
    <EuiContextMenuItem icon={<EuiIcon type="pencil" color="primary" />} size="s" onClick={onClick}>
      <Translate token="action.edit" />
    </EuiContextMenuItem>
  )
}

export { MenuItemUpdateAttributeOption }
