import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiAttributeList } from '@shared/api/types'
import { useTranslate } from '@shared/ui/translate'

import { renderAttributeGroupName, renderAttributeName, renderAttributeType } from '../lib'

export const useAttributesColumns = (): EuiBasicTableColumn<ApiAttributeList>[] => {
  const translate = useTranslate([
    'label.attribute_name',
    'label.attribute_type',
    'label.attribute_group',
  ])

  return [
    {
      field: 'name',
      name: translate['label.attribute_name'],
      render: renderAttributeName,
    },
    {
      field: 'attribute_type',
      name: translate['label.attribute_type'],
      render: renderAttributeType,
    },
    {
      field: 'object_group',
      name: translate['label.attribute_group'],
      render: renderAttributeGroupName,
    },
  ]
}
