import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'
import { ValidatorsTable } from '@widgets/settings'

const ValidatorsPage: ReactFc = () => {
  const translate = useTranslate([PAGE_TITLES.SETTINGS_VALIDATORS])
  useMeta({ title: translate[PAGE_TITLES.SETTINGS_VALIDATORS] })

  return (
    <Layout>
      <ValidatorsTable />
    </Layout>
  )
}

export { ValidatorsPage }
