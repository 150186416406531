import { EuiBadge, EuiIcon, transparentize, useEuiTheme } from '@elastic/eui'
import { getAttributeNameLabel, useAttributeApi, useAttributeTitle } from '@entities/attributes'
import { ATTRIBUTE_LABEL, ROUTES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useBreadcrumbsStore } from '@widgets/header/ui'
import { AttributeTabs } from '@widgets/settings'
import { useEffect } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes'

export const AttributePage: ReactFc = () => {
  const { id: attributeId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE)
  const attribute = useAttributeApi(attributeId)
  const title = useAttributeTitle(attribute)
  const { euiTheme } = useEuiTheme()

  const setRenderAppendBreadcrumbs = useBreadcrumbsStore.useSetRenderAppend()
  const resetBreadcrumbsStore = useBreadcrumbsStore.useReset()

  useEffect(() => {
    setRenderAppendBreadcrumbs(() => {
      if (!attribute.data) {
        return
      }

      const text = getAttributeNameLabel(attribute?.data)

      if (!text) {
        return null
      }

      if (text === ATTRIBUTE_LABEL.PREVIEW) {
        return <EuiIcon type="image" size="s" />
      }

      return <EuiBadge color={transparentize(euiTheme.colors.warning, 0.3)}>{text}</EuiBadge>
    })

    return () => {
      resetBreadcrumbsStore()
    }
  }, [attribute.data])

  useMeta({ title })

  return (
    <Layout
      pageBodyProps={{
        paddingSize: 'm',
      }}
    >
      <AttributeTabs />
    </Layout>
  )
}
