import { useUserSettingsStore } from '@entities/user'
import _ from 'lodash'

import { type DragEndParams, type UseSkuDraggableListReturn } from './types'
import { useFeaturedGroupsList } from './use-featured-groups-list'

export const useSkuDraggableList = (): UseSkuDraggableListReturn => {
  const attributeOrder = useUserSettingsStore.useAttributeOrder()
  const setAttributeOrder = useUserSettingsStore.useSetAttributeOrder()

  const featuredGroupsList = useFeaturedGroupsList().sort((prev, next) => {
    if (!prev.order || !next.order) {
      return 0
    }

    if (_.isEmpty(attributeOrder)) {
      return prev.order - next.order
    }

    return attributeOrder.indexOf(prev.order) - attributeOrder.indexOf(next.order)
  })

  const onDragEnd = ({ source, destination }: DragEndParams) => {
    if (!source || !destination) return

    const reorderedItems = Array.from(featuredGroupsList)
    const [removed] = reorderedItems.splice(source.index, 1)
    reorderedItems.splice(destination.index, 0, removed)

    const newOrder = reorderedItems
      .map((group) => {
        return group.order
      })
      .filter((order): order is number => {
        return order !== null && order !== undefined
      })

    setAttributeOrder(newOrder)
  }

  return [featuredGroupsList, onDragEnd]
}
