import { EuiFlexGroup, EuiHorizontalRule, EuiPanel } from '@elastic/eui'
import { useAttributeApi } from '@entities/attributes'
import { useUpdateAttributeInfo } from '@features/attributes'
import { ROUTES } from '@shared/config'
import { InfoPageCategory } from '@shared/ui'
import { SaveButton } from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

import { AttributeInfoRows } from './attribute-info-rows'

const AttributeInfo: ReactFc = () => {
  const { id: attributeId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE)
  const { data: attribute, isLoading: isAttributeLoading } = useAttributeApi(attributeId)

  const { updateAttribute, isAttributeSubmitting, isSaveButtonActive, methods } =
    useUpdateAttributeInfo()

  const translate = useTranslate(['action.all_fields'])

  // TODO: реализовать обработку кейса, когда атрибут не получен
  if (!attribute) {
    if (!isAttributeLoading) {
      console.error('Не удалось получить атрибут.')
    }

    return null
  }

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <FormProvider {...methods}>
        <EuiFlexGroup justifyContent="flexEnd">
          <SaveButton
            isActive={isSaveButtonActive}
            onClick={updateAttribute}
            isLoading={isAttributeSubmitting}
          />
        </EuiFlexGroup>

        <EuiHorizontalRule margin="s" />

        <InfoPageCategory id={attribute.id} categoryName={translate['action.all_fields']}>
          <AttributeInfoRows id={attribute.id} isSubmitting={isAttributeSubmitting} />
        </InfoPageCategory>
      </FormProvider>
    </EuiPanel>
  )
}

export { AttributeInfo }
