import { type Toast } from '@elastic/eui/src/components/toast/global_toast_list'
import { Translate } from '@shared/ui/translate'

const NOTIFICATION: Record<string, Toast> = {
  ATTRIBUTE_CREATE_SUCCESS: {
    id: `attribute-create-success`,
    title: Translate({ token: 'message.created' }),
    text: Translate({ token: 'notification.attribute_create_success' }),
    color: 'success',
  },
  ATTRIBUTE_GROUP_CREATE_SUCCESS: {
    id: 'attribute-group-create-success',
    title: Translate({ token: 'message.created' }),
    text: Translate({ token: 'notification.attribute_group_create_success' }),
    color: 'success',
  },
  ATTRIBUTE_GROUP_REMOVE_SUCCESS: {
    id: 'attribute-group-remove-success',
    title: Translate({ token: 'message.removed' }),
    text: Translate({ token: 'notification.attribute_group_remove_success' }),
    color: 'success',
  },
  ATTRIBUTE_GROUP_UPDATE_SUCCESS: {
    id: 'attribute-group-update-success',
    title: Translate({ token: 'message.saved' }),
    text: Translate({ token: 'notification.attribute_group_update_success' }),
    color: 'success',
  },
  ATTRIBUTE_OPTION_CREATE_SUCCESS: {
    id: 'attribute-щзешщт-create-success',
    title: Translate({ token: 'message.created' }),
    text: Translate({ token: 'notification.attribute_option_create_success' }),
    color: 'success',
  },
  ATTRIBUTE_OPTIONS_REMOVE_SUCCESS: {
    id: 'attribute-option-remove-success',
    title: Translate({ token: 'message.removed' }),
    text: Translate({ token: 'notification.attribute_options_remove_success' }),
    color: 'success',
  },
  ATTRIBUTE_OPTIONS_UPDATE_SUCCESS: {
    id: 'attribute-option-update-success',
    title: Translate({ token: 'message.saved' }),
    text: Translate({ token: 'notification.attribute_options_update_success' }),
    color: 'success',
  },
  ATTRIBUTE_UPDATE_SUCCESS: {
    id: 'attribute-update-success',
    title: Translate({ token: 'message.saved' }),
    text: Translate({ token: 'notification.attribute_update_success' }),
    color: 'success',
  },
  CATEGORY_CREATE_SUCCESS: {
    id: `category-create-success`,
    title: Translate({ token: 'message.created' }),
    text: Translate({ token: 'notification.category_create_success' }),
    color: 'success',
  },
  CATEGORY_REMOVE_SUCCESS: {
    id: 'category-remove-success',
    title: Translate({ token: 'message.removed' }),
    text: Translate({ token: 'notification.category_remove_success' }),
    color: 'success',
  },
  CATEGORY_UPDATE_SUCCESS: {
    id: 'category-update-success',
    title: Translate({ token: 'message.saved' }),
    text: Translate({ token: 'notification.category_update_success' }),
    color: 'success',
  },
  NOT_FOUND: {
    id: 'server-404-error',
    title: Translate({ token: 'message.error' }),
    text: Translate({ token: 'notification.not_found' }),
    color: 'danger',
  },
  PERMISSIONS_ERROR: {
    id: 'permissions-error',
    title: Translate({ token: 'message.error' }),
    text: Translate({ token: 'notification.permissions_error' }),
    color: 'danger',
  },
  SERVER_ERROR: {
    id: 'server-error',
    title: Translate({ token: 'message.error' }),
    text: Translate({ token: 'notification.server_error' }),
    color: 'danger',
  },
  SKU_CREATE_SUCCESS: {
    id: 'sku-create-success',
    title: Translate({ token: 'message.created' }),
    text: Translate({ token: 'notification.sku_create_success' }),
    color: 'success',
  },
  SKU_FORM_ERROR: {
    id: 'sku-update-error',
    title: Translate({ token: 'message.error' }),
    text: Translate({ token: 'notification.sku_form_error' }),
    color: 'danger',
  },
  SKU_UPDATE_ATTRIBUTES_SUCCESS: {
    id: 'sku-update-success',
    title: Translate({ token: 'message.saved' }),
    text: Translate({ token: 'notification.sku_update_attributes_success' }),
    color: 'success',
  },
  TASK_CREATE_SUCCESS: {
    id: `task-create-success`,
    title: Translate({ token: 'message.created' }),
    text: Translate({ token: 'notification.task_create_success' }),
    color: 'success',
  },
  TASK_REMOVE_SUCCESS: {
    id: 'task-remove-success',
    title: Translate({ token: 'message.removed' }),
    text: Translate({ token: 'notification.task_remove_success' }),
    color: 'success',
  },
  TASK_UPDATE_SUCCESS: {
    id: 'task-update-success',
    title: Translate({ token: 'message.saved' }),
    text: Translate({ token: 'notification.task_update_success' }),
    color: 'success',
  },
  USER_CREATE_SUCCESS: {
    id: `user-create-success`,
    title: Translate({ token: 'message.created' }),
    text: Translate({ token: 'notification.user_create_success' }),
    color: 'success',
  },
  VALIDATOR_UPDATE_SUCCESS: {
    id: 'validators-update-success',
    title: Translate({ token: 'message.saved' }),
    text: Translate({ token: 'notification.validator_update_success' }),
    color: 'success',
  },
}

export { NOTIFICATION }
