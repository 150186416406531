import { EuiForm } from '@elastic/eui'
import { ComboBox, FormError, SelectInput, TextInput } from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'

import { useCategoryForm } from '../model'
import { type CategoryFormProps } from './types'

const CategoryForm: ReactFc<CategoryFormProps> = ({ id, isSubmitting }) => {
  const { isCatalogOptionsLoading, catalogOptions, isCategoryOptionsLoading, categoryOptions } =
    useCategoryForm(id)

  const translate = useTranslate([
    'label.category_name',
    'label.catalog',
    'label.parent_category',
    'message.enter_name',
    'message.select_catalog',
    'message.enter_category',
  ])

  return (
    <EuiForm component="form">
      <TextInput
        name="name"
        placeholder={translate['label.category_name']}
        registerOptions={{ required: translate['message.enter_name'] }}
        inputProps={{
          disabled: isSubmitting,
        }}
        isRequired
      />
      <ComboBox
        name="object_group"
        placeholder={translate['message.select_catalog']}
        label={translate['label.catalog']}
        inputProps={{
          options: catalogOptions,
          isLoading: isCatalogOptionsLoading,
        }}
        registerOptions={{ required: translate['message.select_catalog'] }}
      />
      <SelectInput
        name="parent"
        placeholder={translate['message.enter_category']}
        label={translate['label.parent_category']}
        inputProps={{
          options: categoryOptions,
          isLoading: isCategoryOptionsLoading,
        }}
      />

      <FormError />
    </EuiForm>
  )
}

export { CategoryForm }
