import { EuiSelect } from '@elastic/eui'
import { useCatalogListApi } from '@entities/catalog'
import { useUserSettingsStore } from '@entities/user'
import { PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useSearchParams } from 'react-router'

import { createCatalogSelectOptions } from '../model'

export const CatalogSelect: ReactFc = () => {
  const [searchParams] = useSearchParams()
  const { hasParam, setParam, deleteParam } = useRouterParams()
  const { isLoading, data: catalogs = [], hasCatalogs } = useCatalogListApi()
  const setCatalog = useUserSettingsStore.useSetCatalog()
  const storedCatalogId = useUserSettingsStore.useCatalog()

  // Если на странице каталога нет параметра id ставим первый каталог
  if (!hasParam(PARAMS.OBJECT_GROUPS) && hasCatalogs) {
    setParam(PARAMS.OBJECT_GROUPS, storedCatalogId || catalogs[0].id)
  }

  const catalogId = searchParams.get(PARAMS.OBJECT_GROUPS) ?? undefined
  const catalogsOptions = createCatalogSelectOptions({ catalogs, isLoading, hasCatalogs })

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const catalogId = event.target.value
    setParam(PARAMS.OBJECT_GROUPS, catalogId)
    setCatalog(catalogId)
    deleteParam(PARAMS.CATEGORIES)
  }

  return (
    <EuiSelect
      isLoading={isLoading}
      value={catalogId}
      options={catalogsOptions}
      onChange={handleSelect}
      disabled={!hasCatalogs}
    />
  )
}
