import { EuiHeaderLink } from '@elastic/eui'
import { Translate } from '@shared/ui/translate'

import { useRemoveAttributeFromGroup } from '../model'

export const RemoveAttributeFromGroup: ReactFc = () => {
  const { isDisabledButton, isPending, removeFromGroup } = useRemoveAttributeFromGroup()

  return (
    <EuiHeaderLink
      iconType="minusInCircle"
      color="danger"
      iconSide="right"
      onClick={removeFromGroup}
      isLoading={isPending}
      disabled={isDisabledButton}
    >
      <Translate token="action.exclude_selected" />
    </EuiHeaderLink>
  )
}
