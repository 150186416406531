import { EuiFilterGroup } from '@elastic/eui'
import { useTranslate } from '@shared/ui/translate'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useCatalogFiltersList, useParamDateOptions } from '../../model'
import { type FilterFields } from '../../types'
import { RemoveFilter } from '../remove-filter'
import { type SkuParam } from '../types'
import { FilterDate } from './filter-date'
import { FilterProgress } from './filter-progress'
import { FilterPublic } from './filter-public'

const FilterParam: ReactFc<SkuParam> = ({ skuParam }) => {
  const options = useCatalogFiltersList()
  const { getValues, setValue, resetField } = useFormContext<FilterFields>()
  const paramDateOptions = useParamDateOptions(skuParam)

  const translate = useTranslate([
    'label.progress',
    'label.public',
    'label.create_date',
    'label.update_date',
  ])

  const param = options?.find((option) => {
    return option.data.id === skuParam
  })

  const isProgress = param?.label === translate['label.progress']
  const isPublic = param?.label === translate['label.public']

  const isDate =
    param?.label === translate['label.create_date'] ||
    param?.label === translate['label.update_date']

  const isValueEmpty = !getValues(`filters.${skuParam}`)

  // Set default values when filter appears on screen: attribute is filled for sku
  useEffect(() => {
    if (isValueEmpty) {
      if (isDate) {
        setValue(`filters.${skuParam}.filterParams`, paramDateOptions[0].data)
      }
    }

    return () => {
      if (isValueEmpty) {
        resetField(`filters.${skuParam}`)
      }
    }
  }, [])

  return (
    <EuiFilterGroup compressed>
      {/* Фильтрация по прогрессу */}
      {isProgress && <FilterProgress skuParam={skuParam} isValueEmpty={isValueEmpty} />}

      {/* Фильтрация по статусу публикации */}
      {isPublic && <FilterPublic skuParam={skuParam} isValueEmpty={isValueEmpty} />}

      {/* Фильтрация по дате создания и обновления */}
      {isDate && <FilterDate skuParam={skuParam} />}

      <RemoveFilter id={skuParam} />
    </EuiFilterGroup>
  )
}

export { FilterParam }
