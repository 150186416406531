import { getPriorityOrderedAttributes } from '@entities/attributes'
import { API_ATTRIBUTE_MODEL, useAttributeListApi, type UseAttributeListApiArgs } from '@shared/api'
import { type ApiObjectAttribute } from '@shared/api/types'
import { PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { getArrayParam } from '@shared/lib'

const useAttributesList = (param: PARAMS) => {
  const { params } = useRouterParams()

  const { isLoading, data: { results: attributes, count: attributesCount = 0 } = {} } =
    useAttributeListApi({
      model: API_ATTRIBUTE_MODEL.SKU,
      [PARAMS.ATTRIBUTE_TYPE]: getArrayParam(params[PARAMS.ATTRIBUTE_TYPE]),
      [PARAMS.OBJECT_GROUP]: getArrayParam(params[PARAMS.OBJECT_GROUP]),

      [PARAMS.CATEGORY]:
        param === PARAMS.CATEGORY ? getArrayParam(params[PARAMS.CATEGORIES]) : undefined,

      [PARAMS.CATEGORY_ISNULL]:
        param === PARAMS.CATEGORY_ISNULL
          ? getArrayParam(params[PARAMS.CATEGORY_ISNULL])
          : undefined,

      [PARAMS.SEARCH]: getArrayParam(params[PARAMS.SEARCH]),
      [PARAMS.PAGE]: params[PARAMS.PAGE] ? parseInt(params[PARAMS.PAGE]) : undefined,
      [PARAMS.SIZE]: parseInt(params[PARAMS.SIZE]) || undefined,
    } as UseAttributeListApiArgs)

  return {
    isLoading,
    attributes: getPriorityOrderedAttributes(attributes as ApiObjectAttribute[]),
    attributesCount,
  }
}

export { useAttributesList }
