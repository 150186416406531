import { EuiIcon } from '@elastic/eui'
import { useUserPermissions } from '@entities/user'
import { PERMISSIONS } from '@shared/api'
import { ROUTES } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'

import { createItem } from './create-item'

const useSidebarMenuItems = () => {
  const { permissions } = useUserPermissions()

  const translate = useTranslate([
    'label.menu',
    'label.skus',
    'label.catalog',
    'label.settings',
    'label.attributes',
    'label.attribute_groups',
    'label.validators',
    'label.categorization',
    'label.users',
    'label.automatic_tasks',
    'label.export',
    'label.files',
    'label.import',
  ])

  return [
    {
      id: 'menu',
      name: translate['label.menu'].toUpperCase(),
      items: [
        {
          id: 'all',
          name: translate['label.skus'],
          icon: <EuiIcon type="package" />,
          forceOpen: true,
          items: [
            createItem(
              translate['label.catalog'],
              ROUTES.CATALOG.$path(),
              permissions?.[PERMISSIONS.CAN_VIEW_CATALOG],
            ),
          ],
        },
        {
          id: 'settings',
          name: translate['label.settings'],
          icon: <EuiIcon type="gear" />,
          forceOpen: true,
          items: [
            createItem(
              translate['label.attributes'],
              ROUTES.SETTINGS.ATTRIBUTES.$path(),
              permissions?.[PERMISSIONS.CAN_VIEW_ATTRIBUTES],
            ),
            createItem(
              translate['label.attribute_groups'],
              ROUTES.SETTINGS.ATTRIBUTE_GROUPS.$path(),
              permissions?.[PERMISSIONS.CAN_VIEW_OBJECT_GROUPS],
            ),
            createItem(
              translate['label.validators'],
              ROUTES.SETTINGS.VALIDATORS.$path(),
              // TODO: Добавить права
              // permissions?.[PERMISSIONS.CAN_VIEW_VALIDATORS],
              true,
            ),
            createItem(
              translate['label.categorization'],
              ROUTES.SETTINGS.CATEGORIZATION.$path(),
              true,
            ),
            createItem(
              translate['label.users'],
              ROUTES.SETTINGS.USERS.$path(),
              // TODO: Добавить права
              // permissions?.[PERMISSIONS.CAN_VIEW_USERS],
              true,
            ),
            createItem(
              translate['label.automatic_tasks'],
              ROUTES.SETTINGS.AUTOMATIC_TASKS.$path(),
              true,
            ),
          ],
        },
        {
          id: 'export',
          name: translate['label.export'],
          icon: <EuiIcon type="exportAction" />,
          forceOpen: true,
          items: [createItem(translate['label.files'], ROUTES.EXPORT.FILES.$path(), true)],
        },
        {
          id: 'import',
          name: translate['label.import'],
          icon: <EuiIcon type="importAction" />,
          href: ROUTES.IMPORT.$path(),
        },
      ],
    },
  ]
}

export { useSidebarMenuItems }
