import { useTranslate } from '@shared/ui/translate'

import { CategoryAttributesTab } from './category-attributes-tab'
import { CategoryInfoTab } from './category-info-tab'
import { CategorySkusTab } from './category-skus-tab'

const useCategoryTabsList = () => {
  const translate = useTranslate(['label.settings', 'label.attributes', 'label.skus'])

  return [
    {
      id: 'settings',
      name: translate['label.settings'],
      content: <CategoryInfoTab />,
    },
    {
      id: 'attributes',
      name: translate['label.attributes'],
      content: <CategoryAttributesTab />,
    },
    {
      id: 'imagesAndFiles',
      name: translate['label.skus'],
      content: <CategorySkusTab />,
    },
  ]
}

export { useCategoryTabsList }
