import { type Category } from '@entities/categories/types'
import { MenuItemRemoveCategory, UpdateCategoryModal } from '@features/categories'
import { useDialog } from '@shared/hooks'
import { MenuItemDialog, TableContextMenu } from '@shared/ui/context-menu'
import { Translate } from '@shared/ui/translate'

const ContextMenu = (props: Category) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()

  return (
    <>
      <TableContextMenu>
        <MenuItemDialog onClick={showModal}>
          <Translate token="action.edit" />
        </MenuItemDialog>
        <MenuItemRemoveCategory id={props.id} />
      </TableContextMenu>

      <UpdateCategoryModal id={props.id} isOpen={isOpen} closeModal={closeModal} />
    </>
  )
}

export { ContextMenu }
