import { EuiFlexItem, EuiIconTip } from '@elastic/eui'
import { PasswordInput } from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'

import { type PasswordComponentProps } from './types'

const PasswordComponent: ReactFc<PasswordComponentProps> = ({ getPasswordValue, isSubmitting }) => {
  const translate = useTranslate([
    'error.enter_password',
    'error.user_password_common',
    'error.user_password_min_length',
    'error.user_password_max_length',
    'error.user_password_numeric',
    'error.user_password_match',
    'error.user_password_personal_info',
    'label.password',
    'label.confirm_password',
  ])

  const passwordValidationOptions = {
    required: translate['error.enter_password'],
    minLength: {
      value: 8,
      message: translate['error.user_password_min_length'],
    },
    maxLength: {
      value: 150,
      message: translate['error.user_password_max_length'],
    },
    validate: {
      numericPassword: (value: string) => {
        return /^(?!\d+$).*/.test(value) || translate['error.user_password_numeric']
      },
    },
  }

  const confirmPasswordValidateOptions = {
    required: translate['error.enter_password'],
    validate: {
      passwordMatch: (value: string) => {
        return value === getPasswordValue() || translate['error.user_password_match']
      },
    },
  }

  const passwordTooltip = (
    <>
      • {translate['error.user_password_personal_info']}
      <br />• {translate['error.user_password_min_length']}
      <br />• {translate['error.user_password_common']}
      <br />• {translate['error.user_password_numeric']}
    </>
  )

  return (
    <>
      <EuiFlexItem>
        <PasswordInput
          name="password"
          placeholder={translate['label.password']}
          registerOptions={passwordValidationOptions}
          inputProps={{
            disabled: isSubmitting,
            autoComplete: 'new-password',
            append: [<EuiIconTip key="username" content={passwordTooltip} />],
            compressed: true,
          }}
          isRequired
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <PasswordInput
          name="confirmPassword"
          placeholder={translate['label.confirm_password']}
          registerOptions={confirmPasswordValidateOptions}
          inputProps={{
            disabled: isSubmitting,
            autoComplete: 'new-password',
            compressed: true,
          }}
          isRequired
        />
      </EuiFlexItem>
    </>
  )
}

export { PasswordComponent }
