import { addOtherGroup, useAttributeGroupsList } from '@entities/attributes'
import { useAttributeTypeOptions } from '@entities/attributes/lib/use-attribute-type-options'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { type ApiObjectAttribute } from '@shared/api/types'
import { PARAMS, ROUTES, VALUE_FILTER } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { convertSelectToPopoverOptions } from '@shared/lib'
import { useTranslate } from '@shared/ui/translate'
import { useTypedParams } from 'react-router-typesafe-routes'

const useSearchFilterAttributes = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { data: sku } = useSkuApi(skuId)
  const { data: attributeGroups } = useAttributeGroupsList()
  const { hasParam, getParamValues, params: query } = useRouterParams()
  const translate = useTranslate(['label.other'])
  const attributeTypeOptions = useAttributeTypeOptions()
  const attributeTypePopoverOptions = convertSelectToPopoverOptions(attributeTypeOptions)

  const doesAttributeMatchTypeFilters = (attribute: ApiObjectAttribute) => {
    return hasParam(PARAMS.ATTRIBUTE_TYPE)
      ? getParamValues(PARAMS.ATTRIBUTE_TYPE).includes(attribute.attribute_type?.toString() || '')
      : true
  }

  const isNameMatchSearch = (attribute: ApiObjectAttribute) => {
    const attributeName = attribute.name?.toLocaleLowerCase()
    const searchValue = query.search?.toLocaleLowerCase()

    return query.search ? attributeName.includes(searchValue) : true
  }

  // Проверяем активность поиска/фильтров
  const hasActiveSearchOrFilters = hasParam(PARAMS.ATTRIBUTE_TYPE) || hasParam(PARAMS.SEARCH)

  const isFilteredAttribute = (attribute: ApiObjectAttribute) => {
    return doesAttributeMatchTypeFilters(attribute) && isNameMatchSearch(attribute)
  }

  const getNotEmptyAttributeGroups = (attributeGroups: AttributeGroup[]) => {
    // Фильтруем существующие группы, оставляя только те, у которых есть подходящие атрибуты
    const filteredGroups = attributeGroups.filter((group) => {
      const hasAttributes = Boolean(
        sku?.attributes.find((attribute) => {
          return attribute.object_group === group.id && isFilteredAttribute(attribute)
        }),
      )

      return hasAttributes
    })

    // Собираем атрибуты для группы "Прочее"
    const otherAttributes =
      sku?.attributes.filter((attribute) => {
        return isFilteredAttribute(attribute)
      }) || []

    // Добавляем группу "Прочее"
    return addOtherGroup(filteredGroups, otherAttributes, translate['label.other'])
  }

  const isValueMatchOccupancyFilter = ({ value }: ApiObjectAttribute) => {
    const valueFilter = parseInt(getParamValues(PARAMS.ATTRIBUTE_VALUE)[0])

    switch (valueFilter) {
      case VALUE_FILTER.FILLED:
        return !!value
      case VALUE_FILTER.UNFILLED:
        return !value
      default:
        return true
    }
  }

  const notEmptyAttributeGroups = getNotEmptyAttributeGroups(attributeGroups)

  return {
    attributeTypePopoverOptions,
    notEmptyAttributeGroups,
    hasActiveSearchOrFilters,
    isFilteredAttribute,
    isValueMatchOccupancyFilter,
  }
}

export { useSearchFilterAttributes }
