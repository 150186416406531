import { EuiFieldText, EuiFlexItem, EuiFormRow } from '@elastic/eui'
import { CRON_FIELD_NAME } from '@shared/config'
import { getKeys } from '@shared/lib'

import { Label } from './helpers'
import { type GetCronFieldsArgs } from './types'

const getCronFields = ({
  crontabName: name,
  getValues,
  errors,
  onChange,
  isDisabled,
}: GetCronFieldsArgs) => {
  return getKeys(CRON_FIELD_NAME).map((key) => {
    const label = Label(key.toLowerCase())

    return (
      <EuiFlexItem key={key}>
        <EuiFormRow label={label} isInvalid={!!errors?.[CRON_FIELD_NAME[key]]}>
          <EuiFieldText
            value={getValues(`${name}.${CRON_FIELD_NAME[key]}`) ?? ''}
            placeholder={label}
            isInvalid={!!errors?.[CRON_FIELD_NAME[key]]}
            name={`${name}.${CRON_FIELD_NAME[key]}`}
            onChange={onChange}
            compressed
            disabled={isDisabled}
          />
        </EuiFormRow>
      </EuiFlexItem>
    )
  })
}

export { getCronFields }
