import { CRON_FIELD_NAME } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'

const getCronTemplates = () => {
  return {
    hourly: {
      minute: '0',
      hour: '*',
      day_of_month: '*',
      month_of_year: '*',
      day_of_week: '*',
    },
    daily: {
      minute: '0',
      hour: '0',
      day_of_month: '*',
      month_of_year: '*',
      day_of_week: '*',
    },
    weekly: {
      minute: '0',
      hour: '0',
      day_of_month: '*',
      month_of_year: '*',
      day_of_week: '0',
    },
    monthly: {
      minute: '0',
      hour: '0',
      day_of_month: '1',
      month_of_year: '*',
      day_of_week: '*',
    },
    yearly: {
      minute: '0',
      hour: '0',
      day_of_month: '1',
      month_of_year: '1',
      day_of_week: '*',
    },
  }
}

const Label = (key: string) => {
  const translate = useTranslate([
    'label.minutes',
    'label.hours',
    'label.days',
    'label.months',
    'label.days_of_week',
  ])

  switch (key.toLowerCase()) {
    case CRON_FIELD_NAME.MINUTE:
      return translate['label.minutes']
    case CRON_FIELD_NAME.HOUR:
      return translate['label.hours']
    case CRON_FIELD_NAME.DAY_OF_MONTH:
      return translate['label.days']
    case CRON_FIELD_NAME.MONTH_OF_YEAR:
      return translate['label.months']
    case CRON_FIELD_NAME.DAY_OF_WEEK:
      return translate['label.days_of_week']
    default:
      return key
  }
}

export { getCronTemplates, Label }
