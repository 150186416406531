import { EuiFlexGroup, EuiHorizontalRule } from '@elastic/eui'
import { getPriorityOrderedAttributes } from '@entities/attributes'
import { useSkuApi } from '@entities/catalog'
import { OTHER_ATTRIBUTE_GROUP_ID, ROUTES } from '@shared/config'
import { Accordion } from '@shared/ui/accordion'
import { Fragment } from 'react'
import { useTypedParams } from 'react-router-typesafe-routes'

import { useSearchFilterAttributes } from '../model'
import { SkuAttribute } from './sku-attribute'
import { type SkuAttributeGroupsProps } from './types'

const SkuAttributeGroups: ReactFc<SkuAttributeGroupsProps> = ({ isLoading, isSubmitting }) => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { data: sku } = useSkuApi(skuId)

  const orderedAttributes = getPriorityOrderedAttributes(sku?.attributes)

  // Фильтры и поиск
  const {
    hasActiveSearchOrFilters,
    isFilteredAttribute,
    isValueMatchOccupancyFilter,
    notEmptyAttributeGroups,
  } = useSearchFilterAttributes()

  return (
    <>
      {notEmptyAttributeGroups?.map((group, index) => {
        // Определяем, является ли группа "Прочее"
        const isOtherGroup = group.id === OTHER_ATTRIBUTE_GROUP_ID

        return (
          <Fragment key={`group-${group.id}`}>
            <Accordion
              id={group.id.toString()}
              buttonContent={group.name}
              paddingSize="l"
              initialIsOpen={index === 0}
              forceState={hasActiveSearchOrFilters ? 'open' : undefined}
              isLoading={isLoading}
            >
              <EuiFlexGroup direction="column" gutterSize="s">
                {orderedAttributes
                  .filter((attribute) => {
                    // Для группы "Прочее" проверяем object_group === null
                    const isGroupMatch = isOtherGroup
                      ? attribute.object_group === null
                      : attribute.object_group === group.id

                    return isGroupMatch && isFilteredAttribute(attribute)
                  })
                  .filter(isValueMatchOccupancyFilter)
                  .map((attribute) => {
                    return (
                      <SkuAttribute
                        key={`attribute-${attribute.id}`}
                        attribute={attribute}
                        isSubmitting={isSubmitting}
                      />
                    )
                  })}
              </EuiFlexGroup>
            </Accordion>

            <EuiHorizontalRule margin="s" />
          </Fragment>
        )
      })}
    </>
  )
}

export { SkuAttributeGroups }
