import { EuiForm, EuiHeaderLink } from '@elastic/eui'
import { FormError, NumberInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'

import { useAddAttributeGroup } from '../model'

export const AddAttributeGroup: ReactFc = () => {
  const { isOpen, showModal, closeModal, methods, addGroup, isSubmitting } = useAddAttributeGroup()

  const translate = useTranslate([
    'label.attribute_group_name',
    'label.order',
    'action.add_group',
    'action.add_attribute_group',
    'message.enter_name',
  ])

  return (
    <>
      <EuiHeaderLink iconType="plusInCircle" color="primary" iconSide="right" onClick={showModal}>
        {translate['action.add_group']}
      </EuiHeaderLink>

      <FormProvider {...methods}>
        <Modal
          title={translate['action.add_attribute_group']}
          onClose={closeModal}
          onSubmit={addGroup}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <EuiForm component="form">
            <TextInput
              name="name"
              placeholder={translate['label.attribute_group_name']}
              registerOptions={{ required: translate['message.enter_name'] }}
              inputProps={{
                disabled: isSubmitting,
              }}
              isRequired
            />
            <NumberInput
              name="order"
              placeholder={translate['label.order']}
              inputProps={{
                disabled: isSubmitting,
              }}
            />

            <FormError />
          </EuiForm>
        </Modal>
      </FormProvider>
    </>
  )
}
