import { EuiFilePicker, EuiFormRow } from '@elastic/eui'
import { isFieldError } from '@shared/lib'
import { useTranslate } from '@shared/ui/translate'
import { useFormContext } from 'react-hook-form'

import { type FilePickerProps } from './types'

const FilePicker: ReactFc<FilePickerProps> = ({ name, placeholder, inputProps }) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext()

  const translate = useTranslate(['message.empty_data', 'action.all_fields'])

  const error = errors?.[name]

  // Промежуточная функция для преобразования события в FileList
  const handleFileChange = (files: FileList | null) => {
    setValue(name, files)
  }

  return (
    <EuiFormRow
      label={placeholder}
      isInvalid={!!error}
      error={isFieldError(error) && error.message}
    >
      <EuiFilePicker
        name={name}
        initialPromptText={translate['message.empty_data']}
        onChange={handleFileChange}
        multiple
        {...inputProps}
      />
    </EuiFormRow>
  )
}

export { FilePicker }
