import { $api } from '@shared/api'

import { type AttributeOptionsListQuery } from './types'

const useAttributeOptionsApi = {
  listOptions: (args: AttributeOptionsListQuery = {}) => {
    return $api.useQuery('get', '/attribute_options/', {
      params: {
        query: {
          size: 100,
          ...args,
        },
      },
    })
  },

  option: (id: number) => {
    return $api.useQuery('get', '/attribute_options/{id}/', {
      params: {
        path: { id },
      },
    })
  },

  createOption: () => {
    return $api.useMutation('post', '/attribute_options/')
  },

  updateOption: () => {
    return $api.useMutation('patch', '/attribute_options/{id}/')
  },
}

export { useAttributeOptionsApi }
