import { EuiFieldSearch } from '@elastic/eui'
import { useCategoriesList } from '@entities/categories'
import { useRouterParams } from '@shared/hooks'
import { useTranslate } from '@shared/ui/translate'

export const CategorySearch: ReactFc = () => {
  const { setParam, getParamValues, deleteParam } = useRouterParams()
  const value = getParamValues('searchCategory').toString() || ''
  const { isLoading } = useCategoriesList()
  const translate = useTranslate(['action.find_category'])

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value) {
      setParam('searchCategory', event.target.value)
    } else {
      deleteParam('searchCategory')
    }
  }

  return (
    <EuiFieldSearch
      placeholder={translate['action.find_category']}
      value={value}
      isClearable
      onChange={onChange}
      disabled={isLoading}
      compressed
    />
  )
}
