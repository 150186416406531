import { EuiText } from '@elastic/eui'
import { ELEMENTS_L_WIDTH } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { useEffect } from 'react'

import { useNotificationsStore } from '../store'
import { type LeaveNotificationModalProps } from './types'

const LeaveNotificationModal: ReactFc<LeaveNotificationModalProps> = ({
  isDirty,
  isSubmitting,
  onSave,
}) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()

  const translate = useTranslate([
    'label.warning',
    'action.save',
    'action.continue_without_save',
    'message.unsaved_changes',
  ])

  const onLeave = useNotificationsStore.useOnLeave()
  const canLeave = useNotificationsStore.useCanLeave()
  const setCanLeave = useNotificationsStore.useSetCanLeave()

  useEffect(() => {
    setCanLeave(!isDirty)

    // Текст алерта не получится заменить, по соображениям безопасности в браузере
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
    }

    if (canLeave && isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    if (!canLeave && isDirty) {
      showModal()
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty, onLeave])

  const handleLeave = () => {
    onLeave()
    closeModal()
  }

  const handleSubmit = async () => {
    await onSave()
    handleLeave()
  }

  return (
    <Modal
      title={translate['label.warning']}
      onClose={closeModal}
      onSubmit={handleSubmit}
      onCancel={handleLeave}
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      submitButtonText={translate['action.save']}
      cancelButtonText={translate['action.continue_without_save']}
      maxWidth={ELEMENTS_L_WIDTH}
    >
      <EuiText>{translate['message.unsaved_changes']}</EuiText>
    </Modal>
  )
}

export { LeaveNotificationModal }
