import { EuiBasicTable, type EuiBasicTableProps } from '@elastic/eui'
import { Translate } from '@shared/ui/translate'

export const Table = <T extends object>(props: EuiBasicTableProps<T>) => {
  return (
    <EuiBasicTable
      itemId="id"
      noItemsMessage={
        props.loading ? <Translate token="message.loading" /> : <Translate token="message.empty" />
      }
      {...props}
    />
  )
}
