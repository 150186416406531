import { EuiPageSection } from '@elastic/eui'
import { useAttributeGroupColumns } from '@entities/attributes'
import { API_ATTRIBUTE_MODEL, useAttributeListApi, type UseAttributeListApiArgs } from '@shared/api'
import { type ApiAttributeList } from '@shared/api/types'
import { PARAMS, ROUTES } from '@shared/config'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'
import { useSearchParams } from 'react-router'
import { useTypedParams } from 'react-router-typesafe-routes'

export const AttributeGroupTable: ReactFc = () => {
  const { id: attributeGroupId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP)
  const { sorting, sortList, onTableChange } = useSortTable<ApiAttributeList>()
  const { selection } = useSelectionTable<ApiAttributeList>()
  const attributeGroupColumns = useAttributeGroupColumns()

  // Поиск по атрибутам
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  const { isLoading, data: attributes } = useAttributeListApi({
    [PARAMS.MODEL]: API_ATTRIBUTE_MODEL.SKU,
    [PARAMS.OBJECT_GROUP]: [attributeGroupId],
    search,
  } as UseAttributeListApiArgs)

  const attributeList = attributes?.results || []

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={attributeGroupColumns}
        items={sortList(attributeList)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}
