import { slugify } from '@elastic/eui'

import { RenderLink } from '../ui'

export const createItem = (name: string, href: string, hasPermissions = false) => {
  return {
    id: slugify(href),
    name,
    href: href,
    renderItem: hasPermissions ? RenderLink : undefined,
    disabled: !hasPermissions,
  }
}
