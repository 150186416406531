import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { ROUTES } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'

import { useSkuListApi } from '../api'
import { type Sku, type SkuListQuery } from '../types'

export const useSkusList = (args: SkuListQuery = {}) => {
  const translate = useTranslate(['label.public', 'label.draft', 'message.empty_name'])
  const skuList = useSkuListApi(args)

  const results = skuList?.data?.results.map((sku) => {
    const href = ROUTES.CATALOG.SKU.$buildPath({ params: { id: sku.id } })

    const name =
      extractAttribute(sku, ATTRIBUTE_FLAGS.NAME)?.value || translate['message.empty_name']

    const status = sku.is_public ? translate['label.public'] : translate['label.draft']

    const attributes = Object.fromEntries(
      sku.attributes.map((attribute) => {
        return [attribute.slug, attribute]
      }),
    )

    return {
      ...sku,
      ...attributes,
      href,
      name,
      status,
    }
  }) as Sku[]

  return {
    ...skuList,
    data: {
      ...skuList.data,
      results,
    },
  }
}
