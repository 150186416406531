import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { useImportTypesListApi } from '@entities/import-files'
import { AddImportFile } from '@features/import-files'
import { useDialog } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'
import { useTranslate } from '@shared/ui/translate'

import { type ImportType } from '../types'
import { DownloadTemplateButton } from './download-template-button'

const getTemplatePanels = (importTypes?: ImportType[]) => {
  if (!importTypes) {
    return []
  }

  const result = importTypes.map(({ name, value }) => {
    return {
      renderItem: () => {
        return <DownloadTemplateButton name={name} type={value} />
      },
    }
  })

  return result
}

const ImportFilesButton = () => {
  const { data: importTypes } = useImportTypesListApi()
  const translate = useTranslate(['action.import', 'action.download_template', 'action.add_file'])

  const {
    isOpen: isAddImportFileOpen,
    handleOpenDialog: showAddImportFile,
    handleCloseDialog: closeAddImportFile,
  } = useDialog()

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: translate['action.import'],
          icon: 'share',
          onClick: showAddImportFile,
        },
        {
          name: translate['action.download_template'],
          icon: 'download',
          panel: 1,
        },
      ],
    },
    {
      id: 1,
      items: getTemplatePanels(importTypes as ImportType[]),
    },
  ]

  return (
    <>
      <ContextMenu
        panels={panels}
        menuButtonText={translate['action.add_file']}
        startIcon="plusInCircle"
      />
      <AddImportFile isOpen={isAddImportFileOpen} onClose={closeAddImportFile} />
    </>
  )
}

export { ImportFilesButton }
