import { EuiFlexGroup, EuiFlexItem, EuiForm } from '@elastic/eui'
import { useImportTypesListApi } from '@entities/import-files'
import { CheckboxInput, FilePicker, FormError, SelectInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'

import { useAddImportFile } from '../model'
import { type AddImportFilesProps } from '../types'

const AddImportFile: ReactFc<AddImportFilesProps> = ({ isOpen, onClose }) => {
  const { data: importTypes } = useImportTypesListApi()

  const translate = useTranslate([
    'action.add_import_file',
    'message.import_type',
    'action.start_after_validation',
    'message.file_upload',
  ])

  const { handleModalClose, methods, handleAddUser, isSubmitting } = useAddImportFile({ onClose })

  // TODO: воспользоваться функцией для создания опций после слива изменений по автоматизации
  const importTypeOptions =
    importTypes?.map(({ value, name }) => {
      return {
        value,
        inputDisplay: name,
      }
    }) || []

  return (
    <FormProvider {...methods}>
      <Modal
        title={translate['action.add_import_file']}
        onClose={handleModalClose}
        onSubmit={handleAddUser}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <EuiFlexGroup direction="column" gutterSize="none">
            <SelectInput
              name="import_type"
              label={translate['message.import_type']}
              inputProps={{ compressed: true, options: importTypeOptions, disabled: isSubmitting }}
              registerOptions={{ required: true }}
            />
            <CheckboxInput
              name="start_now"
              inputProps={{
                label: translate['action.start_after_validation'],
                disabled: isSubmitting,
              }}
            />
            <FilePicker
              name="file_upload"
              placeholder={translate['message.file_upload']}
              registerOptions={{ required: true, disabled: isSubmitting }}
            />
            <EuiFlexItem>
              <FormError />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddImportFile }
