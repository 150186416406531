import { useUpdateObjectAttributes } from '@entities/attributes'
import { useSkuApi, useSkuDefaultValues } from '@entities/catalog'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { ROUTES } from '@shared/config'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { useForm } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

const useSkuUpdateAttributes = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { data: sku } = useSkuApi(skuId)

  const methods = useForm()

  // Заполняем значения атрибутов
  useSkuDefaultValues({ id: skuId, methods })

  // Отслеживаем изменения всех полей формы
  const watchedFields = methods.watch()

  const changed = Object.keys(watchedFields).filter((field) => {
    return methods.formState.dirtyFields[field]
  })

  const addNotification = useNotificationsStore.useAddNotification()
  const { handleUpdateAttribute, isSubmitting } = useUpdateObjectAttributes(skuId)

  const onSubmit = async () => {
    let hasErrors = false

    for (const fieldId of changed) {
      const attributeId = parseInt(fieldId)

      const attribute = sku?.attributes.find(({ id }) => {
        return id === attributeId
      })

      try {
        await handleUpdateAttribute(
          attributeId,
          methods.getValues(fieldId),
          attribute?.value_id,
          attribute?.attribute_type,
        )
      } catch (error) {
        hasErrors = true

        methods.setError(`${attributeId}`, {
          type: 'manual',
          message: `${error}`,
        })
      }
    }

    if (!hasErrors) {
      queryClient.refetchQueries({ queryKey: API_QUERY_KEY.SKU(skuId) })
      methods.reset(methods.getValues())
      addNotification(NOTIFICATION.SKU_UPDATE_ATTRIBUTES_SUCCESS)
    }
  }

  const onError = () => {
    addNotification(NOTIFICATION.SKU_FORM_ERROR)
  }

  const updateSkuHandler = methods.handleSubmit(onSubmit, onError)

  return {
    methods,
    changed,
    updateSkuHandler,
    isSubmitting,
  }
}

export { useSkuUpdateAttributes }
