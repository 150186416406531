import { useAttributeOptionsApi } from '@entities/attributes'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { useTranslate } from '@shared/ui/translate'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { type AddAttributeOptionFormInputs } from './types'

export const useUpdateAttributeOption = (
  attributeId: number,
  optionId: number,
  handleCloseDialog: EmptyCallback,
) => {
  const addNotification = useNotificationsStore.useAddNotification()
  const methods = useForm<AddAttributeOptionFormInputs>()
  const translate = useTranslate(['error.server'])

  const updateAttributeOption = useAttributeOptionsApi.updateOption()
  const attributeOptionQuery = useAttributeOptionsApi.option(optionId)
  const name = attributeOptionQuery.data?.value

  useEffect(() => {
    methods.reset({
      name,
    })
  }, [optionId, name])

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const onError = () => {
    methods.setError('root', {
      type: 'manual',
      message: translate['error.server'],
    })
  }

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_OPTIONS_LIST(attributeId) })
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_OPTION(optionId) })
    closeModal()
  }

  const onSubmitUpdate = async ({ name }: AddAttributeOptionFormInputs) => {
    if (optionId) {
      await updateAttributeOption.mutateAsync({
        params: {
          path: {
            id: optionId,
          },
        },
        body: {
          value: name,
        },
      })

      onSuccess()

      addNotification(NOTIFICATION.ATTRIBUTE_OPTIONS_UPDATE_SUCCESS)
    }
  }

  const updateOption = methods.handleSubmit(onSubmitUpdate, onError)

  return {
    closeModal,
    methods,
    updateOption,
    isSubmitting: updateAttributeOption.isPending || attributeOptionQuery.isLoading,
  }
}
