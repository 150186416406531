import { EuiForm, EuiFormRow, EuiPanel, EuiSpacer } from '@elastic/eui'
import { CatalogSelect, CategorySearch } from '@features/catalog'
import { CategoryFilter } from '@features/categories'
import { useTranslate } from '@shared/ui/translate'
import React from 'react'

import { getCatalogCategoryFilterTree } from '../model'

const CatalogSidebarForm: ReactFc = () => {
  const translate = useTranslate(['message.select_catalog'])

  return (
    <EuiPanel hasShadow={false}>
      <EuiForm fullWidth>
        <EuiFormRow label={translate['message.select_catalog']}>
          <CatalogSelect />
        </EuiFormRow>
        <EuiSpacer />

        <CategorySearch />
        <EuiSpacer />

        <CategoryFilter getTreeItems={getCatalogCategoryFilterTree} />
      </EuiForm>
    </EuiPanel>
  )
}

const CatalogSidebarFormMemo = React.memo(CatalogSidebarForm) as typeof CatalogSidebarForm

export { CatalogSidebarFormMemo as CatalogSidebarForm }
