import { useCategoriesList } from '@entities/categories'
import { convertToOption } from '@shared/lib'
import { useTranslate } from '@shared/ui/translate'

const useCategoryOptions = () => {
  const { data: categories = [], isLoading } = useCategoriesList()
  const translate = useTranslate(['label.attributes_root_category'])

  const categoryOptions = categories.map(convertToOption)

  categoryOptions.unshift({ value: 0, inputDisplay: translate['label.attributes_root_category'] })

  return { categoryOptions, isLoading }
}

export { useCategoryOptions }
