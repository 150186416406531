import { type EuiBasicTableColumn } from '@elastic/eui'
import { useTranslate } from '@shared/ui/translate'

import { renderSkuName } from '../lib'
import { type Sku } from '../types'

const useSettingsSkusColumns = (): EuiBasicTableColumn<Sku>[] => {
  const translate = useTranslate(['label.sku_name'])

  return [
    {
      field: 'name',
      name: translate['label.sku_name'],
      render: renderSkuName,
    },
  ]
}

export { useSettingsSkusColumns }
