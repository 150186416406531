import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText, EuiToolTip } from '@elastic/eui'
import { StartTimePicker } from '@features/import-files'
import { IMPORT_STATUS } from '@shared/config'
import { getLocalDate } from '@shared/lib'
import { useTranslate } from '@shared/ui/translate'
import moment, { type Moment } from 'moment'
import { FormProvider, useForm } from 'react-hook-form'

import { type FilesCardSubInfoProps } from '../types'

const FilesCardSubInfo: ReactFc<FilesCardSubInfoProps> = ({
  createdAt,
  createdBy,
  importTypes,
  importType,
  importStatus,
  fileId,
  scheduledTo,
}) => {
  const methods = useForm<{ scheduled_to?: Moment }>(
    scheduledTo
      ? {
          values: { scheduled_to: moment(scheduledTo) },
        }
      : undefined,
  )

  const translate = useTranslate([
    'message.import_user',
    'message.create_date',
    'message.import_type',
  ])

  const importTypeText = importTypes?.find((type) => {
    return type.value === importType
  })?.name

  const subInfoItems = [
    { iconType: 'user', text: createdBy, toolTip: translate['message.import_user'] },
    {
      iconType: 'calendar',
      text: getLocalDate(createdAt),
      toolTip: translate['message.create_date'],
    },
    { iconType: 'document', text: importTypeText, toolTip: translate['message.import_type'] },
  ]

  const isStartTimePickerDisabled =
    !importStatus ||
    (importStatus !== IMPORT_STATUS.VALIDATED && importStatus !== IMPORT_STATUS.PLANNED)

  return (
    <EuiFlexGroup alignItems="center">
      {subInfoItems.map((item, index) => {
        const { iconType, text, toolTip } = item

        return (
          <EuiFlexItem key={index} grow={false}>
            <EuiToolTip position="top" content={toolTip}>
              <EuiFlexGroup alignItems="center" gutterSize="s">
                {iconType && <EuiIcon type={iconType} size="m" />}

                <EuiText size="s">{text}</EuiText>
              </EuiFlexGroup>
            </EuiToolTip>
          </EuiFlexItem>
        )
      })}

      <EuiFlexItem grow={false}>
        <FormProvider {...methods}>
          <StartTimePicker isDisabled={isStartTimePickerDisabled} fileId={fileId} />
        </FormProvider>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export { FilesCardSubInfo }
