import { useSkuApi } from '@entities/catalog'
import { APP_PUBLIC_URL, ROUTES } from '@shared/config'
import { useTypedParams } from 'react-router-typesafe-routes'

export const usePublicLink = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { data: sku } = useSkuApi(skuId)

  const isPublic = sku?.is_public || false

  const openPublicLink = () => {
    window.open(`${APP_PUBLIC_URL}${sku?.id}`, '_blank')
  }

  return {
    isPublic,
    openPublicLink,
  }
}
