import { Translate } from '@shared/ui/translate'

import { type Category } from '../types'
import { CategoriesParent } from '../ui'

const renderCategoriesParent: RenderColumn<Category, 'parent'> = (parent) => {
  if (!parent) {
    return <Translate token="message.empty_value" />
  }

  return <CategoriesParent id={parent} />
}

export { renderCategoriesParent }
