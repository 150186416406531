import { EuiIcon, EuiPanel, EuiTextTruncate } from '@elastic/eui'
import { FileIcon } from '@entities/files'
import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { type ApiObjectAttribute } from '@shared/api/types'
import { formatDate } from '@shared/lib'
import { Translate } from '@shared/ui/translate'

export const renderAttribute = (attribute: ApiObjectAttribute) => {
  // Пустое значение –
  if (!attribute?.value) {
    return <Translate token="message.empty_value" />
  }

  // Первый файл
  if (attribute.files) {
    return <FileIcon {...attribute.files[0]} />
  }

  // Иконка кода
  if (attribute.attribute_type === API_ATTRIBUTE_TYPE.JSON) {
    return (
      <>
        <EuiIcon type="editorCodeBlock" />
      </>
    )
  }

  // Булевое значение - Отмечено
  if (attribute.attribute_type === API_ATTRIBUTE_TYPE.BOOL) {
    return <EuiIcon type="check" />
  }

  // Дата
  if (attribute.attribute_type === API_ATTRIBUTE_TYPE.DATE) {
    return <>{formatDate(attribute.value)}</>
  }

  // Длинный текст
  if (attribute.attribute_type === API_ATTRIBUTE_TYPE.LONG_TEXT) {
    return (
      <EuiPanel paddingSize="none" color="transparent">
        <EuiTextTruncate text={`${attribute.value}`} />
      </EuiPanel>
    )
  }

  return <>{`${attribute.value}`}</>
}
