import { API_ATTRIBUTE_TYPE } from '@shared/api'

import { type AttributeTypeMap, type AttributeTypeValueMap } from './types'

export const ATTRIBUTE_TYPE_MAP: AttributeTypeMap = {
  [API_ATTRIBUTE_TYPE.TEXT]: 'attribute_type.text',
  [API_ATTRIBUTE_TYPE.LIST]: 'attribute_type.list',
  [API_ATTRIBUTE_TYPE.BOOL]: 'attribute_type.bool',
  [API_ATTRIBUTE_TYPE.NESTED_LIST]: 'attribute_type.nested_list',
  [API_ATTRIBUTE_TYPE.NUMBER]: 'attribute_type.number',
  [API_ATTRIBUTE_TYPE.FILE]: 'attribute_type.file',
  [API_ATTRIBUTE_TYPE.JSON]: 'attribute_type.json',
  [API_ATTRIBUTE_TYPE.DATE]: 'attribute_type.date',
  [API_ATTRIBUTE_TYPE.FLOAT]: 'attribute_type.float',
  [API_ATTRIBUTE_TYPE.LONG_TEXT]: 'attribute_type.long_text',
}

export const ATTRIBUTE_TYPE_VALUE_MAP: AttributeTypeValueMap = {
  [API_ATTRIBUTE_TYPE.TEXT]: 'value',
  [API_ATTRIBUTE_TYPE.LIST]: 'option',
  [API_ATTRIBUTE_TYPE.BOOL]: 'bool',
  [API_ATTRIBUTE_TYPE.NESTED_LIST]: '', // TODO: Добавить, как будет в бекенде
  [API_ATTRIBUTE_TYPE.NUMBER]: 'number',
  [API_ATTRIBUTE_TYPE.FILE]: 'files',
  [API_ATTRIBUTE_TYPE.JSON]: 'json',
  [API_ATTRIBUTE_TYPE.DATE]: 'datetime',
  [API_ATTRIBUTE_TYPE.FLOAT]: 'float',
  [API_ATTRIBUTE_TYPE.LONG_TEXT]: 'long_text',
}
