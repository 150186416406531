import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'
import { AttributeGroupsHeader, AttributeGroupsTable } from '@widgets/settings'

export const AttributeGroupsPage: ReactFc = () => {
  const translate = useTranslate([PAGE_TITLES.SETTINGS_ATTRIBUTE_GROUPS])
  useMeta({ title: translate[PAGE_TITLES.SETTINGS_ATTRIBUTE_GROUPS] })

  return (
    <Layout header={<AttributeGroupsHeader />}>
      <AttributeGroupsTable />
    </Layout>
  )
}
