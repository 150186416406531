import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'
import { Catalog, CatalogHeader, CatalogSidebar } from '@widgets/catalog'

export const CatalogPage: ReactFc = () => {
  const translate = useTranslate([PAGE_TITLES.CATALOG])
  useMeta({ title: translate[PAGE_TITLES.CATALOG] })

  return (
    <Layout header={<CatalogHeader />} sidebar={<CatalogSidebar />}>
      <Catalog />
    </Layout>
  )
}
