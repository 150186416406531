import { EuiSelect } from '@elastic/eui'
import { DATE_RANGE_LABEL, PARAMS } from '@shared/config'
import { isEqual } from 'lodash'
import { useFormContext } from 'react-hook-form'

import { useCatalogFiltersList, useParamDateOptions } from '../../model'
import { type FilterFields } from '../../types'
import { DateRangeFilter } from '../date-range-filter'
import { type SkuParam } from '../types'

const FilterDate: ReactFc<SkuParam> = ({ skuParam }) => {
  const options = useCatalogFiltersList()
  const paramDateOptions = useParamDateOptions(skuParam)
  const { getValues, setValue } = useFormContext<FilterFields>()

  const param = options?.find((option) => {
    return option.data.id === skuParam
  })

  const selectValue =
    paramDateOptions.find((option) => {
      return isEqual(option.data, getValues(`filters.${skuParam}.filterParams`))
    })?.value || DATE_RANGE_LABEL

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const option = paramDateOptions.find((option) => {
      return option.value === event.target.value
    })

    if (option) {
      setValue(`filters.${skuParam}.option`, option.value)
      setValue(`filters.${skuParam}.filterParams`, option.data)
    }
  }

  return (
    <>
      <EuiSelect
        prepend={param?.label}
        options={paramDateOptions}
        value={selectValue}
        onChange={handleSelectChange}
        compressed
      />

      {selectValue === DATE_RANGE_LABEL && (
        <>
          <DateRangeFilter
            paramPrefix={skuParam}
            paramSuffixes={{
              after: PARAMS.AFTER,
              before: PARAMS.BEFORE,
            }}
          />
        </>
      )}
    </>
  )
}

export { FilterDate }
