import {
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderLink,
  EuiIcon,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'
import { API_ATTRIBUTE_MODEL, useAttributeListApi } from '@shared/api'
import { useToggle } from '@shared/hooks'
import { useStyles } from '@shared/ui/styles'
import { Translate, useTranslate } from '@shared/ui/translate'
import { useDebounce } from '@uidotdev/usehooks'
import { useState } from 'react'

import { useAddAttributeToGroup } from '../model'
import { getStyles } from './styles'

export const AddAttributeToGroup: ReactFc = () => {
  const [isMenuOpen, toggleMenu] = useToggle()
  const [search, setSearch] = useState('')
  const styles = useStyles(getStyles)
  const debouncedSearch = useDebounce(search, 500)
  const translate = useTranslate(['action.find_attribute'])

  const { isLoading, data: attributes } = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.SKU,
    object_group__isnull: true,
    search: debouncedSearch,
  })

  const { isPending, addToGroup, isProcessed } = useAddAttributeToGroup()

  const attributesList = attributes?.results.map((attribute) => {
    return (
      <EuiContextMenuItem
        key={attribute.id}
        icon="plus"
        onClick={addToGroup(attribute.id)}
        disabled={isProcessed(attribute.id)}
      >
        {attribute.name}
      </EuiContextMenuItem>
    )
  })

  const isDisabled = attributesList && !attributesList.length

  const addToGroupButton = (
    <EuiHeaderLink
      iconType="arrowDown"
      iconSide="right"
      color="primary"
      onClick={toggleMenu}
      isLoading={isLoading || isPending}
      isDisabled={isDisabled}
    >
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem>
          <Translate token="action.add" />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiIcon type="plusInCircle" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiHeaderLink>
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const renderEmptyMessage = () => {
    if (attributesList?.length) {
      return null
    }

    return (
      <>
        <EuiSpacer size="s" />

        <EuiText color="subdued" textAlign="center">
          <Translate token="message.empty_data" />
        </EuiText>

        <EuiSpacer size="s" />
      </>
    )
  }

  return (
    <EuiPopover
      id="addToGroupPopover"
      button={addToGroupButton}
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
      panelPaddingSize="none"
      anchorPosition="downCenter"
      hasArrow={false}
    >
      <EuiPanel paddingSize="s" hasShadow={false} color="transparent" css={styles.searchWrapper}>
        <EuiFieldSearch
          placeholder={translate['action.find_attribute']}
          compressed
          onInput={handleSearch}
          isLoading={isLoading}
        />

        {renderEmptyMessage()}
      </EuiPanel>

      <EuiContextMenuPanel items={attributesList} css={styles.list} />
    </EuiPopover>
  )
}
