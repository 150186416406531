import { EuiFlexGroup, EuiSpacer, EuiText } from '@elastic/eui'
import { useTranslate } from '@shared/ui/translate'
import { useEffect, useLayoutEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTransferList } from '../model'
import { LIST, type TransferListProps } from '../types'
import { TransferListActions } from './transfer-list-actions'
import { TransferListSide } from './transfer-list-side'

const TransferList: ReactFc<TransferListProps> = ({
  name,
  itemsList,
  selectedItems: selectedItemsExternal,
  isLoading = false,
  isRequired,
  message,
}) => {
  //TODO: добавить скелетон для TransferListSide при загрузке
  const {
    setValue,
    register,
    formState: { errors },
    trigger,
  } = useFormContext()

  const translate = useTranslate(['error.select_min_one', 'message.available', 'message.checked'])

  const { availableItems, selectedItems, actions, handledToggle } = useTransferList({
    itemsList,
    isLoading,
    selectedItems: selectedItemsExternal,
  })

  useEffect(() => {
    if (isRequired) {
      register(name, {
        validate: {
          selectedRequired: ({ selected }) => {
            return selected.length > 0 || translate['error.select_min_one']
          },
        },
      })
    }
  }, [register])

  useLayoutEffect(() => {
    setValue(name, {
      selected: selectedItems,
      available: availableItems,
    })

    trigger(name)
  }, [selectedItems, availableItems])

  return (
    <>
      <EuiFlexGroup gutterSize="none">
        <TransferListSide
          inputName={`${name}.available`}
          title={translate['message.available']}
          onChange={handledToggle(LIST.AVAILABLE)}
          itemsList={availableItems}
        />
        <TransferListActions actions={actions} />
        <TransferListSide
          inputName={`${name}.selected`}
          title={translate['message.checked']}
          onChange={handledToggle(LIST.SELECTED)}
          itemsList={selectedItems}
          error={errors[name]?.message as string}
        />
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiText>{message}</EuiText>
    </>
  )
}

export { TransferList }
