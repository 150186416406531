import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { Protect } from '@entities/user'
import { AddAttributeGroup, RemoveAttributeGroups } from '@features/attributes'
import { PERMISSIONS } from '@shared/api'
import { Search } from '@shared/ui'
import { noShadow } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'

export const AttributeGroupsHeader: ReactFc = () => {
  const translate = useTranslate(['action.find_group'])

  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={translate['action.find_group']} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSectionItem>
        <Protect permission={PERMISSIONS.CAN_ADD_OBJECT_GROUPS}>
          <RemoveAttributeGroups />
          <AddAttributeGroup />
        </Protect>
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
