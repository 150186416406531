import { $api } from '@shared/api'

import { type UseCategoryListApiQueryParams } from './types'

const useCategoriesListApi = (args: UseCategoryListApiQueryParams = {}) => {
  return $api.useQuery('get', '/categories/', {
    params: {
      query: {
        size: 100,
        ...args,
      },
    },
  })
}

export { useCategoriesListApi }
