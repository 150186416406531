import { EuiButtonIcon, EuiPageSidebar, EuiPanel, EuiSpacer } from '@elastic/eui'
import { useUserSettingsStore } from '@entities/user'
import {
  ROUTES,
  SIDEBAR_CLOSED_LOGO_WIDTH,
  SIDEBAR_CLOSED_MIN_WIDTH,
  SIDEBAR_OPEN_LOGO_WIDTH,
  SIDEBAR_OPEN_MIN_WIDTH,
  VERSION,
} from '@shared/config'
import { Logo } from '@shared/ui/icons'
import { positionAbsolute, positionRelative, textCenter, useStyles } from '@shared/ui/styles'
import { TextColor } from '@shared/ui/text-color'
import { useTranslate } from '@shared/ui/translate'
import { Link } from 'react-router'

import { SidebarNav } from './sidebar-nav'
import { getStyles } from './styles'

// TODO: Сделать через API, чтоб приходили только доступные пункты меню
export const Sidebar: ReactFc = () => {
  const isSidebarOpen = useUserSettingsStore.useIsSidebarOpen()
  const toggleSidebarOpen = useUserSettingsStore.useToggleSidebarOpen()

  const translate = useTranslate([
    'label.settings',
    'label.export',
    'label.import',
    'label.catalog',
  ])

  const styles = useStyles(getStyles)

  if (isSidebarOpen) {
    return (
      <EuiPageSidebar
        paddingSize="l"
        sticky
        minWidth={SIDEBAR_OPEN_MIN_WIDTH}
        css={[styles.sidebar, textCenter]}
      >
        <EuiPanel paddingSize="none" hasShadow={false} color="transparent" css={positionRelative}>
          <Link to="/">
            <Logo size={SIDEBAR_OPEN_LOGO_WIDTH} variant="full" />
          </Link>
          <EuiSpacer />
          <EuiButtonIcon
            iconType="menuLeft"
            color="text"
            css={[positionAbsolute, styles.toggle]}
            onClick={toggleSidebarOpen}
            aria-label="menuLeft"
          />
          <SidebarNav />
        </EuiPanel>

        <TextColor color="subdued">{VERSION}</TextColor>
      </EuiPageSidebar>
    )
  }

  return (
    <EuiPageSidebar minWidth={SIDEBAR_CLOSED_MIN_WIDTH} sticky css={[styles.sidebar, textCenter]}>
      <EuiSpacer size="l" />
      <Link to="/">
        <Logo size={SIDEBAR_CLOSED_LOGO_WIDTH} />
      </Link>
      <EuiSpacer />
      <EuiPanel paddingSize="none" color="transparent" grow={false}>
        <EuiButtonIcon
          iconType="menuRight"
          color="text"
          onClick={toggleSidebarOpen}
          aria-label="menuRight"
        />
      </EuiPanel>
      <EuiSpacer size="s" />
      <Link to={ROUTES.CATALOG.$path()}>
        <EuiButtonIcon iconType="package" color="text" aria-label={translate['label.catalog']} />
      </Link>
      <EuiSpacer size="xs" />
      {/* <Link to={ROUTES.DAM.$path()}>
        <EuiButtonIcon iconType="documents" color="text" />
      </Link> */}
      <EuiSpacer size="xs" />
      <Link to={ROUTES.SETTINGS.$path()}>
        <EuiButtonIcon
          aria-label={translate['label.settings']}
          iconType="gear"
          color="text"
          onClick={toggleSidebarOpen}
        />
      </Link>
      <EuiSpacer size="xs" />
      <Link to={ROUTES.EXPORT.$path()}>
        <EuiButtonIcon
          aria-label={translate['label.export']}
          iconType="exportAction"
          color="text"
          onClick={toggleSidebarOpen}
        />
      </Link>
      <EuiSpacer size="xs" />
      <Link to={ROUTES.IMPORT.$path()}>
        <EuiButtonIcon
          aria-label={translate['label.import']}
          iconType="importAction"
          color="text"
          onClick={toggleSidebarOpen}
        />
      </Link>
    </EuiPageSidebar>
  )
}
