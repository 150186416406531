import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { Protect } from '@entities/user'
import { AddCategory } from '@features/categories'
import { PERMISSIONS } from '@shared/api'
import { Search } from '@shared/ui'
import { noShadow } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'

const CategorizationHeader: ReactFc = () => {
  const translate = useTranslate(['action.find_category'])

  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={translate['action.find_category']} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <Protect permission={PERMISSIONS.CAN_ADD_OBJECT_GROUPS}>
            <AddCategory />
          </Protect>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { CategorizationHeader }
