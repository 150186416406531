export const TRANSLATE_MAPPING = {
  ru: {
    'action.actions': 'Действия',
    'action.add': 'Добавить',
    'action.add_attribute_group': 'Создание группы атрибутов',
    'action.add_category': 'Создать категорию',
    'action.add_file': 'Добавить файл',
    'action.add_group': 'Создать группу',
    'action.add_import_file': 'Новый файл импорта',
    'action.add_option': 'Создать опцию',
    'action.add_task': 'Добавить задачу',
    'action.all_fields': 'Все поля',
    'action.attributes_category_select': 'Выбрать категорию',
    'action.attributes_link': 'Привязать',
    'action.back': 'Назад',
    'action.back_main': 'Вернуться на главную',
    'action.cancel': 'Отмена',
    'action.change_password': 'Изменить пароль',
    'action.clear': 'Очистить',
    'action.continue_without_save': 'Продолжить без сохранения',
    'action.create': 'Создать',
    'action.daily': 'Ежедневно',
    'action.delete': 'Удалить',
    'action.delete_selected': 'Удалить выбранные',
    'action.download': 'Скачать',
    'action.download_description': 'Скачать описание',
    'action.download_template': 'Скачать шаблон',
    'action.edit': 'Редактировать',
    'action.edit_attribute': 'Редактировать атрибут',
    'action.edit_attribute_group': 'Редактирование группы атрибутов',
    'action.edit_attribute_option': 'Редактирование опции атрибута',
    'action.edit_category': 'Редактирование категории',
    'action.exclude_selected': 'Исключить выбранные',
    'action.export': 'Экспорт',
    'action.find_attribute': 'Найти атрибут',
    'action.find_category': 'Найти категорию',
    'action.find_filter': 'Найти фильтр',
    'action.find_group': 'Найти группу',
    'action.find_sku': 'Найти товар',
    'action.find_user': 'Найти пользователя',
    'action.hourly': 'Ежечасно',
    'action.import': 'Импортировать',
    'action.link_to_category': 'Привязать к категории',
    'action.login': 'Вход в систему',
    'action.login_button': 'Войти',
    'action.monthly': 'Ежемесячно',
    'action.new_attribute': 'Новый атрибут',
    'action.new_sku': 'Новый товар',
    'action.new_task': 'Новая задача',
    'action.new_user': 'Новый пользователь',
    'action.notify_changes': 'Оповестить об изменениях',
    'action.object_group_select': 'Выбрать группу',
    'action.restore': 'Восстановить',
    'action.restore_password': 'Забыли пароль?',
    'action.save': 'Сохранить',
    'action.select_date': 'Выбрать дату',
    'action.select_list': 'Выбрать список',
    'action.select_template': 'Выбрать шаблон',
    'action.select_trigger': 'Выбрать триггер',
    'action.start_after_validation': 'Начать импорт сразу после валидации',
    'action.start_export': 'Начать экспорт',
    'action.switch_user': 'Сменить профиль',
    'action.type_select': 'Выбрать тип',
    'action.version_comparison': 'Сравнение версий',
    'action.weekly': 'Еженедельно',
    'action.yearly': 'Ежегодно',
    'attribute_label.brand': 'Brand',
    'attribute_label.name': 'PN',
    'attribute_label.preview': 'Preview',
    'attribute_label.sku': 'SKU',
    'attribute_type.text': 'Текст короткий',
    'attribute_type.list': 'Список',
    'attribute_type.bool': 'Логический',
    'attribute_type.nested_list': 'Вложенный список',
    'attribute_type.number': 'Число целое',
    'attribute_type.file': 'Файл',
    'attribute_type.json': 'JSON',
    'attribute_type.date': 'Дата',
    'attribute_type.float': 'Число дробное',
    'attribute_type.long_text': 'Текст',
    'attribute_type_help.text.description':
      'Атрибут для ввода текста с ограничением в 255 символов',
    'attribute_type_help.text.tooltip':
      'Введите текстовое значение. Допустимая длина текста: до 255 символов',
    'attribute_type_help.list.description':
      'Атрибут для выбора одного значения из доступного списка',
    'attribute_type_help.list.tooltip': 'Выберите значение из предложенных вариантов',
    'attribute_type_help.bool.description':
      'Атрибут для выбора логического значения: true (Да) или false (Нет)',
    'attribute_type_help.bool.tooltip': 'Укажите логическое значение (true или false)',
    'attribute_type_help.number.description':
      'Атрибут для ввода значения типа integer (целое число)',
    'attribute_type_help.number.tooltip': 'Введите целое число. Допустимы только целые числа',
    'attribute_type_help.file.description': 'Атрибут для хранения ссылки на файл',
    'attribute_type_help.file.tooltip': 'Выберите и загрузите файл',
    'attribute_type_help.json.description':
      'Атрибут для ввода данных в формате JSON. Данные должны быть валидными',
    'attribute_type_help.json.tooltip': 'Введите данные в формате JSON. JSON должен быть валидным',
    'attribute_type_help.date.description':
      'Атрибут для ввода значения, соответствующего формату datetime',
    'attribute_type_help.date.tooltip': 'Укажите дату и время. Формат ввода: ГГГГ-ММ-ДД ЧЧ:ММ:СС',
    'attribute_type_help.float.description':
      'Атрибут для ввода значений типа float (числа с плавающей точкой)',
    'attribute_type_help.float.tooltip':
      'Введите число с плавающей точкой. Допустимы только числа с плавающей точкой',
    'attribute_type_help.long_text.description': 'Атрибут для ввода текста произвольной длины',
    'attribute_type_help.long_text.tooltip': 'Введите текст без ограничений длины',
    'attribute_type_help.nested_list.description': '',
    'attribute_type_help.nested_list.tooltip': '',
    'automatic_task_name.export_sku': 'Экспорт SKU',
    'automatic_task_name.import_sku': 'Импорт SKU',
    'catalog_view.cards': 'Сетка',
    'catalog_view.table': 'Таблица',
    'error.attribute_empty': 'Введите значение атрибута',
    'error.auth_incorrect': 'Вы ввели неправильный логин или пароль',
    'error.cron_day_of_month_format':
      'День месяца должен быть "*" или числом от 1 до 31, или "*/число", или "число/число"',
    'error.cron_day_of_month_number': 'День месяца должен быть числом',
    'error.cron_day_of_month_range': 'День месяца должен быть в диапазоне от 1 до 31',
    'error.cron_day_of_month_start_value': 'Некорректное начальное значение в шаге дней месяца',
    'error.cron_day_of_month_step': 'Некорректный шаг дней месяца',
    'error.cron_day_of_week_format':
      'День недели должен быть "*" или числом от 0 до 6, или "*/число", или "число/число"',
    'error.cron_day_of_week_number': 'День недели должен быть числом',
    'error.cron_day_of_week_range': 'День недели должен быть в диапазоне от 0 до 6',
    'error.cron_day_of_week_start_value': 'Некорректное начальное значение в шаге дней недели',
    'error.cron_day_of_week_step': 'Некорректный шаг дней недели',
    'error.cron_hour_format':
      'Час должен быть "*" или числом от 0 до 23, или "*/число", или "число/число"',
    'error.cron_hour_number': 'Час должен быть числом',
    'error.cron_hour_range': 'Час должен быть в диапазоне от 0 до 23',
    'error.cron_hour_start_value': 'Некорректное начальное значение в шаге часов',
    'error.cron_hour_step': 'Некорректный шаг часов',
    'error.cron_minute_format':
      'Минута должна быть "*" или числом от 0 до 59, или "*/число", или "число/число"',
    'error.cron_minute_number': 'Минута должна быть числом',
    'error.cron_minute_range': 'Минута должна быть в диапазоне от 0 до 59',
    'error.cron_minute_start_value': 'Некорректное начальное значение в шаге минут',
    'error.cron_minute_step': 'Некорректный шаг минут',
    'error.cron_month_format':
      'Месяц должен быть "*" или числом от 1 до 12, или "*/число", или "число/число"',
    'error.cron_month_number': 'Месяц должен быть числом',
    'error.cron_month_range': 'Месяц должен быть в диапазоне от 1 до 12',
    'error.cron_month_start_value': 'Некорректное начальное значение в шаге месяцев',
    'error.cron_month_step': 'Некорректный шаг месяцев',
    'error.enter_login': 'Введите логин',
    'error.enter_password': 'Введите пароль',
    'error.enter_user_name': 'Введите имя',
    'error.export_error': 'Ошибка экспорта',
    'error.json_incorrect': 'JSON содержит ошибки',
    'error.no_page':
      'Страница, которую вы ищете, не найдена, попробуйте начать навигацию с главной страницы',
    'error.not_empty': 'Нельзя удалить объект, пока в нём содержатся дочерние элементы',
    'error.permission': 'У вас нет прав для просмотра этого раздела',
    'error.select_min_one': 'Выберите минимум один элемент',
    'error.server': 'Ошибка сервера, попробуйте ещё раз',
    'error.user_name_format': 'Имя может содержать только буквы, цифры и символы @._+-, ',
    'error.user_name_max_length': 'Имя не должно превышать 150 символов',
    'error.user_name_min_length': 'Имя должно содержать минимум 3 символов',
    'error.user_password_common': 'Пароль не должен быть слишком простым и распространенным',
    'error.user_password_match': 'Пароли не совпадают',
    'error.user_password_max_length': 'Пароль может содержать максимум 150 символов',
    'error.user_password_min_length': 'Пароль должен содержать минимум 8 символов',
    'error.user_password_numeric': 'Пароль не может состоять только из цифр',
    'error.user_password_personal_info':
      'Пароль не должен быть слишком похож на другую вашу личную информацию',
    'field.attribute_type': 'Тип',
    'field.attributes_count': 'Количество атрибутов',
    'field.block_save_with_validation_errors': 'Блокировать сохранение при ошибке валидации',
    'field.catalogs': 'Каталоги',
    'field.category': 'Категория',
    'field.created_at': 'Создан',
    'field.created_by': 'Создатель',
    'field.crontab_schedule': 'Периодичность',
    'field.description': 'Описание',
    'field.email_list': 'Список электронных адресов',
    'field.forbidden_words': 'Запрещенные слова',
    'field.group': 'Группа',
    'field.id': 'ID',
    'field.is_brand': 'Бренд',
    'field.is_deleted': 'Удален',
    'field.is_editable': 'Редактируемый',
    'field.is_enabled': 'Активен',
    'field.is_featured': 'Показывать в карточке',
    'field.is_name': 'Название продукта',
    'field.is_preview': 'Превью',
    'field.is_public': 'Публичный',
    'field.is_required': 'Обязательный',
    'field.is_required_value': 'Обязательное значение',
    'field.is_send_notifications': 'Отправить уведомления',
    'field.is_sku': 'SKU',
    'field.last_launch_at': 'Последний запуск',
    'field.max_length': 'Максимальная длина',
    'field.max_numeric_value': 'Максимальное значение',
    'field.min_length': 'Минимальная длина',
    'field.min_numeric_value': 'Минимальное значение',
    'field.name': 'Название',
    'field.object_group': 'Группа',
    'field.parent': 'Родительская категория',
    'field.skus_count': 'Количество SKU',
    'field.slug': 'Slug',
    'field.start_at': 'Время начала',
    'field.status': 'Статус',
    'field.task_rules': 'Правила',
    'field.task_type': 'Тип',
    'field.trigger': 'Триггер',
    'field.updated_at': 'Обновлен',
    'field.use_validators': 'Использовать валидатор',
    'field.uuid': 'UUID',
    'field.validated_attribute': 'Валидируемый атрибут',
    'field.weight': 'Вес',
    'label.attribute': 'Атрибут',
    'label.attribute_groups': 'Группы атрибутов',
    'label.attributes': 'Атрибуты',
    'label.automatic_tasks': 'Автоматизация',
    'label.attributes_in_category': 'Атрибутов в категории',
    'label.attributes_in_group': 'Атрибутов в группе',
    'label.attributes_root_category': 'Корневая категория',
    'label.attribute_group': 'Группа атрибута',
    'label.attribute_group_name': 'Название группы',
    'label.attribute_name': 'Название атрибута',
    'label.attribute_type': 'Тип атрибута',
    'label.catalog': 'Каталог',
    'label.categorization': 'Категоризация',
    'label.categories': 'Категории',
    'label.category_filter': 'Фильтр по категориям',
    'label.category': 'Категория',
    'label.category_name': 'Название категории',
    'label.checked': 'Выбранные',
    'label.confirm_password': 'Подтверждение пароля',
    'label.create_date': 'Дата создания',
    'label.days': 'Дни',
    'label.days_of_week': 'Дни недели',
    'label.draft': 'Черновик',
    'label.email_list': 'Список электронных адресов',
    'label.enabled': 'Активен',
    'label.exit': 'Выход',
    'label.export': 'Экспорт',
    'label.files': 'Файлы',
    'label.filters': 'Фильтры',
    'label.fullness': 'Заполненность',
    'label.hours': 'Часы',
    'label.id': 'ID',
    'label.images_files': 'Изображения и файлы',
    'label.last_30_days': 'Последние 30 дней',
    'label.last_month': 'В прошлом месяце',
    'label.login': 'Логин',
    'label.menu': 'Меню',
    'label.minutes': 'Минуты',
    'label.months': 'Месяцы',
    'label.name': 'Название',
    'label.now': 'Сейчас',
    'label.options_list': 'Список опций',
    'label.order': 'Позиция',
    'label.other': 'Прочее',
    'label.pagination': 'Навигация по страницам',
    'label.parent_category': 'Родительская категория',
    'label.password': 'Пароль',
    'label.permissions': 'Права доступа',
    'label.permissions_denied': 'Недостаточно прав',
    'label.print_version': 'Версия для печати',
    'label.previous': 'Было',
    'label.profile': 'Профиль',
    'label.progress': 'Прогресс',
    'label.progress_0_25': 'от 0 до 25%',
    'label.progress_25_50': 'от 25% до 50%',
    'label.progress_50_75': 'от 50% до 75%',
    'label.progress_75_100': 'от 75% до 100%',
    'label.public': 'Публичный',
    'label.public_catalog_link': 'Ссылка в публичном каталоге',
    'label.remove_all_columns': 'Убрать все колонки',
    'label.remove_checked_columns': 'Убрать отмеченные колонки',
    'label.repeatability': 'Периодичность',
    'label.resolution': 'Разрешение',
    'label.select_all_columns': 'Выбрать все колонки',
    'label.select_checked_columns': 'Выбрать отмеченные колонки',
    'label.select_period': 'Выбранный период',
    'label.send_notification': 'Отправить уведомление',
    'label.settings': 'Настройки',
    'label.size': 'Размер',
    'label.skus': 'Товары',
    'label.sku_in_category': 'Товаров в категории',
    'label.sku_name': 'Название товара',
    'label.sku_sku': 'Артикул товара',
    'label.start_date': 'Дата начала',
    'label.this_month': 'В этом месяце',
    'label.this_week': 'На этой неделе',
    'label.this_year': 'В этом году',
    'label.today': 'Сегодня',
    'label.trigger': 'Триггер',
    'label.type': 'Тип',
    'label.update_date': 'Дата обновления',
    'label.users': 'Пользователи',
    'label.user': 'Пользователь',
    'label.validators': 'Валидаторы',
    'label.validator': 'Валидатор',
    'label.import': 'Импорт',
    'label.user_name': 'Имя пользователя',
    'label.value': 'Значение',
    'label.value_contain': 'Значение содержит',
    'label.value_equal': 'Значения равны',
    'label.value_exist_false': 'Нет значения',
    'label.value_exist_true': 'Есть значение',
    'label.value_not_contain': 'Значение не содержит',
    'label.value_not_equal': 'Значения не равны',
    'label.version': 'Версия',
    'label.version_history': 'История изменений',
    'label.warning': 'Предупреждение',
    'label.yesterday': 'Вчера',
    'message.available': 'Доступные',
    'message.category_required': 'Не выбрана категория',
    'message.checked': 'Выбранные',
    'message.create_date': 'Дата создания',
    'message.created': 'Создано',
    'message.done': 'Готово',
    'message.empty': 'Ничего не найдено',
    'message.empty_catalogs': 'Каталоги отсутствуют',
    'message.empty_data': 'Данные отсутствуют',
    'message.no_categories': 'Категории отсутствуют',
    'message.empty_files_list': 'Файлы отсутствуют',
    'message.empty_name': 'Без названия',
    'message.empty_value': '–',
    'message.enter_category': 'Выберите категорию',
    'message.enter_name': 'Введите название',
    'message.enter_sku': 'Введите артикул',
    'message.enter_type': 'Укажите тип',
    'message.error': 'Ошибка',
    'message.export_time':
      'Процесс экспорта может занять некоторое время, в зависимости от количества данных',
    'message.file_upload': 'Перенесите сюда файл для загрузки',
    'message.file_upload_create_at': 'Загружен',
    'message.import': 'Импорт',
    'message.import_date': 'Дата импорта',
    'message.import_log': 'Лог импорта',
    'message.import_type': 'Тип импорта',
    'message.import_user': 'Инициатор импорта',
    'message.initiator': 'Инициатор',
    'message.is_featured':
      'Если включено, данный атрибут будет отображаться в карточке товара при его просмотре',
    'message.last_launch': 'Дата последнего запуска',
    'message.link_after_publication': 'Ссылка появится после публикации',
    'message.loading': 'Загрузка…',
    'message.login_to_continue': 'Авторизуйтесь, чтобы продолжить',
    'message.hidden_password':
      'Пароли хранятся в зашифрованном виде, поэтому нет возможности посмотреть пароль этого пользователя',
    'message.removed': 'Удалено',
    'message.saved': 'Сохранено',
    'message.select_catalog': 'Выберите каталог',
    'message.type': 'Тип',
    'message.update_date': 'Дата обновления',
    'message.unsaved_changes': 'Все несохранённые данные будут потеряны. Продолжить?',
    'notification.attribute_create_success': 'Атрибут успешно создан',
    'notification.attribute_group_create_success': 'Группа атрибутов успешно создана',
    'notification.attribute_group_remove_success': 'Группа атрибутов успешно удалена',
    'notification.attribute_group_update_success': 'Группа атрибутов успешно обновлена',
    'notification.attribute_option_create_success': 'Опция атрибута успешно создана',
    'notification.attribute_options_remove_success': 'Опции атрибута успешно удалены',
    'notification.attribute_options_update_success': 'Опция атрибута успешно обновлена',
    'notification.attribute_update_success': 'Атрибут успешно обновлен',
    'notification.category_create_success': 'Категория успешно создана',
    'notification.category_remove_success': 'Категория успешно удалена',
    'notification.category_update_success': 'Категория успешно обновлена',
    'notification.not_found': 'Не найдено',
    'notification.permissions_error': 'У вас нет прав для просмотра этого раздела',
    'notification.server_error': 'Произошла ошибка на сервере, попробуйте ещё раз',
    'notification.sku_create_success': 'Товар успешно создан',
    'notification.sku_form_error': 'Проверьте, все ли поля формы заполнены корректно',
    'notification.sku_update_attributes_success': 'Атрибуты успешно обновлены',
    'notification.task_create_success': 'Задача успешно создана',
    'notification.task_remove_success': 'Задача успешно удалена',
    'notification.task_update_success': 'Задача успешно обновлена',
    'notification.user_create_success': 'Пользователь успешно создан',
    'notification.validator_update_success': 'Валидатор успешно обновлен',
    'task_status.in_progress': 'В процессе',
    'task_status.planned': 'Запланирована',
    'value_filter.filled': 'Заполненные',
    'value_filter.unfilled': 'Незаполненные',
    'euiTablePagination.rowsPerPage': 'Элементов на странице',
    'euiTablePagination.rowsPerPageOption': '{rowsPerPage}',
    'euiComboBoxOptionsList.createCustomOption': 'Добавить {searchValue} как новую опцию',
    'euiComboBoxOptionsList.allOptionsSelected': 'Вы выбрали все опции',
    'euiComboBoxOptionsList.noAvailableOptions': 'Нет доступных опций',
    'euiGlobalToastList.clearAllToastsButtonDisplayText': 'Очистить всё',
  },
  en: {
    'action.actions': 'Actions',
    'action.add': 'Add',
    'action.add_attribute_group': 'Create attribute group',
    'action.add_category': 'Create category',
    'action.add_file': 'Add file',
    'action.add_group': 'Create group',
    'action.add_import_file': 'New import file',
    'action.add_option': 'Create option',
    'action.add_task': 'Add task',
    'action.all_fields': 'All fields',
    'action.attributes_category_select': 'Select category',
    'action.attributes_link': 'Link',
    'action.back': 'Back',
    'action.back_main': 'Return to main page',
    'action.cancel': 'Cancel',
    'action.change_password': 'Change password',
    'action.clear': 'Clear',
    'action.continue_without_save': 'Continue without saving',
    'action.create': 'Create',
    'action.daily': 'Daily',
    'action.delete': 'Delete',
    'action.delete_selected': 'Delete selected',
    'action.download': 'Download',
    'action.download_description': 'Download description',
    'action.download_template': 'Download template',
    'action.edit': 'Edit',
    'action.edit_attribute': 'Edit attribute',
    'action.edit_attribute_group': 'Edit attribute group',
    'action.edit_attribute_option': 'Edit attribute option',
    'action.edit_category': 'Edit category',
    'action.exclude_selected': 'Exclude selected',
    'action.export': 'Export',
    'action.find_attribute': 'Find attribute',
    'action.find_category': 'Find category',
    'action.find_filter': 'Find filter',
    'action.find_group': 'Find group',
    'action.find_sku': 'Find product',
    'action.find_user': 'Find user',
    'action.hourly': 'Hourly',
    'action.import': 'Import',
    'action.link_to_category': 'Link to category',
    'action.login': 'Login',
    'action.login_button': 'Sign in',
    'action.monthly': 'Monthly',
    'action.new_attribute': 'New attribute',
    'action.new_sku': 'New product',
    'action.new_task': 'New task',
    'action.new_user': 'New user',
    'action.notify_changes': 'Notify about changes',
    'action.object_group_select': 'Select group',
    'action.restore': 'Restore',
    'action.restore_password': 'Forgot password?',
    'action.save': 'Save',
    'action.select_date': 'Select date',
    'action.select_list': 'Select list',
    'action.select_template': 'Select template',
    'action.select_trigger': 'Select trigger',
    'action.start_after_validation': 'Start import immediately after validation',
    'action.start_export': 'Start export',
    'action.switch_user': 'Switch profile',
    'action.type_select': 'Select type',
    'action.version_comparison': 'Version comparison',
    'action.weekly': 'Weekly',
    'action.yearly': 'Yearly',
    'attribute_label.brand': 'Brand',
    'attribute_label.name': 'PN',
    'attribute_label.preview': 'Preview',
    'attribute_label.sku': 'SKU',
    'attribute_type.text': 'Short text',
    'attribute_type.list': 'List',
    'attribute_type.bool': 'Boolean',
    'attribute_type.nested_list': 'Nested list',
    'attribute_type.number': 'Integer',
    'attribute_type.file': 'File',
    'attribute_type.json': 'JSON',
    'attribute_type.date': 'Date',
    'attribute_type.float': 'Float',
    'attribute_type.long_text': 'Text',
    'attribute_type_help.text.description':
      'Attribute for entering text with a 255 character limit',
    'attribute_type_help.text.tooltip':
      'Enter a text value. Allowed text length: up to 255 characters',
    'attribute_type_help.list.description':
      'Attribute for selecting one value from an available list',
    'attribute_type_help.list.tooltip': 'Select a value from the provided options',
    'attribute_type_help.bool.description':
      'Attribute for selecting a boolean value: true (Yes) or false (No)',
    'attribute_type_help.bool.tooltip': 'Specify a boolean value (true or false)',
    'attribute_type_help.number.description': 'Attribute for entering an integer value',
    'attribute_type_help.number.tooltip': 'Enter an integer. Only whole numbers are allowed',
    'attribute_type_help.file.description': 'Attribute for storing a file reference',
    'attribute_type_help.file.tooltip': 'Select and upload a file',
    'attribute_type_help.json.description':
      'Attribute for entering data in JSON format. Data must be valid',
    'attribute_type_help.json.tooltip': 'Enter data in JSON format. JSON must be valid',
    'attribute_type_help.date.description':
      'Attribute for entering a value corresponding to the datetime format',
    'attribute_type_help.date.tooltip': 'Specify date and time. Input format: YYYY-MM-DD HH:MM:SS',
    'attribute_type_help.float.description':
      'Attribute for entering float values (floating-point numbers)',
    'attribute_type_help.float.tooltip':
      'Enter a floating-point number. Only floating-point numbers are allowed',
    'attribute_type_help.long_text.description': 'Attribute for entering text of arbitrary length',
    'attribute_type_help.long_text.tooltip': 'Enter text without length restrictions',
    'attribute_type_help.nested_list.description': '',
    'attribute_type_help.nested_list.tooltip': '',
    'automatic_task_name.export_sku': 'SKU Export',
    'automatic_task_name.import_sku': 'SKU Import',
    'catalog_view.cards': 'Grid',
    'catalog_view.table': 'Table',
    'error.attribute_empty': 'Enter attribute value',
    'error.auth_incorrect': 'Incorrect login or password',
    'error.cron_day_of_month_format':
      'Day of month must be "*" or number 1-31, or "*/number", or "number/number"',
    'error.cron_day_of_month_number': 'Day of month must be a number',
    'error.cron_day_of_month_range': 'Day of month must be between 1-31',
    'error.cron_day_of_month_start_value': 'Invalid start value in day of month step',
    'error.cron_day_of_month_step': 'Invalid day of month step',
    'error.cron_day_of_week_format':
      'Day of week must be "*" or number 0-6, or "*/number", or "number/number"',
    'error.cron_day_of_week_number': 'Day of week must be a number',
    'error.cron_day_of_week_range': 'Day of week must be between 0-6',
    'error.cron_day_of_week_start_value': 'Invalid start value in day of week step',
    'error.cron_day_of_week_step': 'Invalid day of week step',
    'error.cron_hour_format': 'Hour must be "*" or number 0-23, or "*/number", or "number/number"',
    'error.cron_hour_number': 'Hour must be a number',
    'error.cron_hour_range': 'Hour must be between 0-23',
    'error.cron_hour_start_value': 'Invalid start value in hour step',
    'error.cron_hour_step': 'Invalid hour step',
    'error.cron_minute_format':
      'Minute must be "*" or number 0-59, or "*/number", or "number/number"',
    'error.cron_minute_number': 'Minute must be a number',
    'error.cron_minute_range': 'Minute must be between 0-59',
    'error.cron_minute_start_value': 'Invalid start value in minute step',
    'error.cron_minute_step': 'Invalid minute step',
    'error.cron_month_format':
      'Month must be "*" or number 1-12, or "*/number", or "number/number"',
    'error.cron_month_number': 'Month must be a number',
    'error.cron_month_range': 'Month must be between 1-12',
    'error.cron_month_start_value': 'Invalid start value in month step',
    'error.cron_month_step': 'Invalid month step',
    'error.enter_login': 'Enter login',
    'error.enter_password': 'Enter password',
    'error.enter_user_name': 'Enter name',
    'error.export_error': 'Export error',
    'error.json_incorrect': 'JSON contains errors',
    'error.no_page': 'Page not found, try starting navigation from the main page',
    'error.not_empty': 'Cannot delete object while it contains child elements',
    'error.permission': "You don't have permission to view this section",
    'error.select_min_one': 'Select at least one item',
    'error.server': 'Server error, try again',
    'error.user_name_format': 'Name can only contain letters, numbers, and symbols @._+-',
    'error.user_name_max_length': 'Name cannot exceed 150 characters',
    'error.user_name_min_length': 'Name must be at least 3 characters',
    'error.user_password_common': 'Password cannot be too simple or common',
    'error.user_password_match': "Passwords don't match",
    'error.user_password_max_length': 'Password can be maximum 150 characters',
    'error.user_password_min_length': 'Password must be at least 8 characters',
    'error.user_password_numeric': 'Password cannot consist only of numbers',
    'error.user_password_personal_info':
      "Password shouldn't be too similar to your personal information",
    'field.attribute_type': 'Type',
    'field.attributes_count': 'Attributes count',
    'field.block_save_with_validation_errors': 'Block saving with validation errors',
    'field.catalogs': 'Catalogs',
    'field.category': 'Category',
    'field.created_at': 'Created at',
    'field.created_by': 'Created by',
    'field.crontab_schedule': 'Schedule frequency',
    'field.description': 'Description',
    'field.email_list': 'Email list',
    'field.forbidden_words': 'Forbidden words',
    'field.group': 'Group',
    'field.id': 'ID',
    'field.is_brand': 'Brand',
    'field.is_deleted': 'Deleted',
    'field.is_editable': 'Editable',
    'field.is_enabled': 'Enabled',
    'field.is_featured': 'Show in card',
    'field.is_name': 'Product name',
    'field.is_preview': 'Preview',
    'field.is_public': 'Public',
    'field.is_required': 'Required',
    'field.is_required_value': 'Required value',
    'field.is_send_notifications': 'Send notifications',
    'field.is_sku': 'SKU',
    'field.last_launch_at': 'Last launch',
    'field.max_length': 'Maximum length',
    'field.max_numeric_value': 'Maximum value',
    'field.min_length': 'Minimum length',
    'field.min_numeric_value': 'Minimum value',
    'field.name': 'Name',
    'field.object_group': 'Group',
    'field.parent': 'Parent category',
    'field.skus_count': 'SKU count',
    'field.slug': 'Slug',
    'field.start_at': 'Start time',
    'field.status': 'Status',
    'field.task_rules': 'Rules',
    'field.task_type': 'Type',
    'field.trigger': 'Trigger',
    'field.updated_at': 'Updated at',
    'field.use_validators': 'Use validator',
    'field.uuid': 'UUID',
    'field.validated_attribute': 'Validated attribute',
    'field.weight': 'Weight',
    'label.attribute': 'Attribute',
    'label.attribute_groups': 'Attribute groups',
    'label.attributes': 'Attributes',
    'label.automatic_tasks': 'Automation',
    'label.attributes_in_category': 'Attributes in category',
    'label.attributes_in_group': 'Attributes in group',
    'label.attributes_root_category': 'Root category',
    'label.attribute_group': 'Attribute group',
    'label.attribute_group_name': 'Group name',
    'label.attribute_name': 'Attribute name',
    'label.attribute_type': 'Attribute type',
    'label.catalog': 'Catalog',
    'label.categorization': 'Categorization',
    'label.categories': 'Categories',
    'label.category_filter': 'Category filter',
    'label.category': 'Category',
    'label.category_name': 'Category name',
    'label.checked': 'Selected',
    'label.confirm_password': 'Confirm password',
    'label.create_date': 'Creation date',
    'label.days': 'Days',
    'label.days_of_week': 'Days of week',
    'label.draft': 'Draft',
    'label.email_list': 'Email list',
    'label.enabled': 'Enabled',
    'label.exit': 'Exit',
    'label.export': 'Export',
    'label.files': 'Files',
    'label.filters': 'Filters',
    'label.fullness': 'Completeness',
    'label.hours': 'Hours',
    'label.id': 'ID',
    'label.images_files': 'Images and files',
    'label.last_30_days': 'Last 30 days',
    'label.last_month': 'Last month',
    'label.login': 'Login',
    'label.menu': 'Menu',
    'label.minutes': 'Minutes',
    'label.months': 'Months',
    'label.name': 'Name',
    'label.now': 'Now',
    'label.options_list': 'Options list',
    'label.order': 'Position',
    'label.other': 'Other',
    'label.pagination': 'Pagination',
    'label.parent_category': 'Parent category',
    'label.password': 'Password',
    'label.permissions': 'Permissions',
    'label.permissions_denied': 'Permission denied',
    'label.print_version': 'Print version',
    'label.previous': 'Previous',
    'label.profile': 'Profile',
    'label.progress': 'Progress',
    'label.progress_0_25': '0-25%',
    'label.progress_25_50': '25-50%',
    'label.progress_50_75': '50-75%',
    'label.progress_75_100': '75-100%',
    'label.public': 'Public',
    'label.public_catalog_link': 'Link in public catalog',
    'label.remove_all_columns': 'Hide all columns',
    'label.remove_checked_columns': 'Hide selected columns',
    'label.repeatability': 'Frequency',
    'label.resolution': 'Resolution',
    'label.select_all_columns': 'Select all columns',
    'label.select_checked_columns': 'Select checked columns',
    'label.select_period': 'Selected period',
    'label.send_notification': 'Send notification',
    'label.settings': 'Settings',
    'label.size': 'Size',
    'label.skus': 'Products',
    'label.sku_in_category': 'Products in category',
    'label.sku_name': 'Product name',
    'label.sku_sku': 'Product SKU',
    'label.start_date': 'Start date',
    'label.this_month': 'This month',
    'label.this_week': 'This week',
    'label.this_year': 'This year',
    'label.today': 'Today',
    'label.trigger': 'Trigger',
    'label.type': 'Type',
    'label.update_date': 'Update date',
    'label.users': 'Users',
    'label.user': 'User',
    'label.validators': 'Validators',
    'label.validator': 'Validator',
    'label.import': 'Import',
    'label.user_name': 'Username',
    'label.value': 'Value',
    'label.value_contain': 'Value contains',
    'label.value_equal': 'Values equal',
    'label.value_exist_false': 'No value',
    'label.value_exist_true': 'Has value',
    'label.value_not_contain': "Value doesn't contain",
    'label.value_not_equal': 'Values not equal',
    'label.version': 'Version',
    'label.version_history': 'Version history',
    'label.warning': 'Warning',
    'label.yesterday': 'Yesterday',
    'message.available': 'Available',
    'message.category_required': 'No category selected',
    'message.checked': 'Selected',
    'message.create_date': 'Creation date',
    'message.created': 'Created',
    'message.done': 'Done',
    'message.empty': 'Nothing found',
    'message.empty_catalogs': 'No catalogs available',
    'message.empty_data': 'No data available',
    'message.no_categories': 'No categories available',
    'message.empty_files_list': 'No files available',
    'message.empty_name': 'Untitled',
    'message.empty_value': '–',
    'message.enter_category': 'Select category',
    'message.enter_name': 'Enter name',
    'message.enter_sku': 'Enter SKU',
    'message.enter_type': 'Specify type',
    'message.error': 'Error',
    'message.export_time': 'Export process may take some time depending on data volume',
    'message.file_upload': 'Drag file here to upload',
    'message.file_upload_create_at': 'Uploaded',
    'message.import': 'Import',
    'message.import_date': 'Import date',
    'message.import_log': 'Import log',
    'message.import_type': 'Import type',
    'message.import_user': 'Import initiator',
    'message.initiator': 'Initiator',
    'message.is_featured': 'If enabled, this attribute will be shown in product card view',
    'message.last_launch': 'Last run date',
    'message.link_after_publication': 'Link will appear after publication',
    'message.loading': 'Loading…',
    'message.login_to_continue': 'Login to continue',
    'message.hidden_password':
      "Passwords are stored in encrypted form, so there is no way to view this user's password",
    'message.removed': 'Removed',
    'message.saved': 'Saved',
    'message.select_catalog': 'Select catalog',
    'message.type': 'Type',
    'message.update_date': 'Update date',
    'message.unsaved_changes': 'Unsaved changes will be lost. Continue?',
    'notification.attribute_create_success': 'Attribute created successfully',
    'notification.attribute_group_create_success': 'Attribute group created successfully',
    'notification.attribute_group_remove_success': 'Attribute group deleted successfully',
    'notification.attribute_group_update_success': 'Attribute group updated successfully',
    'notification.attribute_option_create_success': 'Attribute option created successfully',
    'notification.attribute_options_remove_success': 'Attribute options deleted successfully',
    'notification.attribute_options_update_success': 'Attribute option updated successfully',
    'notification.attribute_update_success': 'Attribute updated successfully',
    'notification.category_create_success': 'Category created successfully',
    'notification.category_remove_success': 'Category deleted successfully',
    'notification.category_update_success': 'Category updated successfully',
    'notification.not_found': 'Not found',
    'notification.permissions_error': "You don't have permission to view this section",
    'notification.server_error': 'Server error occurred, try again',
    'notification.sku_create_success': 'Product created successfully',
    'notification.sku_form_error': 'Check if all form fields are filled correctly',
    'notification.sku_update_attributes_success': 'Attributes updated successfully',
    'notification.task_create_success': 'Task created successfully',
    'notification.task_remove_success': 'Task deleted successfully',
    'notification.task_update_success': 'Task updated successfully',
    'notification.user_create_success': 'User created successfully',
    'notification.validator_update_success': 'Validator updated successfully',
    'task_status.in_progress': 'In progress',
    'task_status.planned': 'Planned',
    'value_filter.filled': 'Filled',
    'value_filter.unfilled': 'Unfilled',
    'euiTablePagination.rowsPerPage': 'Rows per page',
    'euiTablePagination.rowsPerPageOption': '{rowsPerPage} rows',
    'euiComboBoxOptionsList.createCustomOption': 'Add {searchValue} as a custom option',
    'euiComboBoxOptionsList.allOptionsSelected': "You've selected all available options",
    'euiComboBoxOptionsList.noAvailableOptions': "There aren't any options available",
    'euiGlobalToastList.clearAllToastsButtonDisplayText': 'Clear all',
  },
}
