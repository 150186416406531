import { AttributeFormField } from '@entities/attributes'
import { useCategoryApi } from '@entities/categories'
import { CATEGORY_FIELD_NAMES, CATEGORY_FIELDS, ROUTES } from '@shared/config'
import { getLocalDate } from '@shared/lib'
import { InfoPageRow } from '@shared/ui'
import { ComboBox, SelectInput } from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'
import { useTypedParams } from 'react-router-typesafe-routes'

import { useInfoCategory } from '../model'

const CategoryInfoRows: ReactFc<Obj<boolean>> = ({ isSubmitting }) => {
  const { id: categoryId } = useTypedParams(ROUTES.SETTINGS.CATEGORIZATION.CATEGORY)
  const { data: category, isLoading: isCategoryLoading } = useCategoryApi(categoryId)

  const { isCatalogOptionsLoading, catalogOptions, isCategoryOptionsLoading, categoryOptions } =
    useInfoCategory()

  const translate = useTranslate(['message.empty_value', 'message.empty_catalogs'])

  const isDisabled = isCategoryLoading || isSubmitting

  const rowsAttributes = category?.attributes.map((attribute) => {
    return {
      name: attribute.name,
      require: true,
      field: (
        <AttributeFormField
          attribute={{ ...attribute, is_required: true }}
          disablePlaceholder
          isSubmitting={isCategoryLoading || isDisabled}
        />
      ),
    }
  })

  const rowsConfig = [
    {
      name: CATEGORY_FIELDS.OBJECT_GROUP,
      field: (
        <ComboBox
          name={CATEGORY_FIELDS.OBJECT_GROUP}
          inputProps={{
            options: catalogOptions,
            isLoading: isCatalogOptionsLoading,
            isClearable: false,
            isDisabled: isDisabled,
          }}
          registerOptions={{
            required: {
              value: true,
              message: translate['message.empty_catalogs'],
            },
          }}
        />
      ),
    },
    {
      name: CATEGORY_FIELDS.PARENT,
      field: (
        <SelectInput
          name={CATEGORY_FIELDS.PARENT}
          inputProps={{
            options: categoryOptions,
            isLoading: isCategoryOptionsLoading,
            disabled: isDisabled,
          }}
        />
      ),
    },
    {
      name: CATEGORY_FIELDS.UUID,
      field: <>{category?.uuid}</>,
    },
    {
      name: CATEGORY_FIELDS.CREATED_BY,
      field: <>{category?.created_by || translate['message.empty_value']}</>,
    },
    {
      name: CATEGORY_FIELDS.CREATED_AT,
      field: <>{getLocalDate(category?.created_at) || translate['message.empty_value']}</>,
    },
    {
      name: CATEGORY_FIELDS.UPDATED_AT,
      field: <>{getLocalDate(category?.updated_at) || translate['message.empty_value']}</>,
    },
  ]

  const attributeRows =
    rowsAttributes?.map(({ name, field }) => {
      return <InfoPageRow key={name} label={name} field={field} />
    }) || []

  const rows = rowsConfig.map(({ name, field }) => {
    return <InfoPageRow key={name} label={CATEGORY_FIELD_NAMES[name] || ''} field={field} />
  })

  return [...attributeRows, ...rows]
}

export { CategoryInfoRows }
