import { useValidatorApi } from '@entities/validators'
import { VALIDATOR_FIELD_NAMES, VALIDATOR_FIELDS } from '@shared/config'
import { getLocalDate } from '@shared/lib'
import { InfoPageRow } from '@shared/ui'
import { CheckboxInput, TextAreaInput, TextInput } from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'

import { type ValidatorInfoRowsProps } from './types'

const ValidatorInfoRows: ReactFc<ValidatorInfoRowsProps> = ({ id: validatorId, isSubmitting }) => {
  const translate = useTranslate(['message.empty_value'])
  const { data: validator, isLoading: isValidatorLoading } = useValidatorApi(validatorId)
  const isDisabled = isValidatorLoading || isSubmitting

  const rowsConfig = [
    {
      name: VALIDATOR_FIELDS.VALIDATORS_ATTRIBUTE_NAME,
      field: <>{validator?.validated_attribute_name}</>,
    },
    {
      name: VALIDATOR_FIELDS.IS_ENABLED,
      field: (
        <CheckboxInput name={VALIDATOR_FIELDS.IS_ENABLED} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: VALIDATOR_FIELDS.IS_DELETED,
      field: (
        <CheckboxInput name={VALIDATOR_FIELDS.IS_DELETED} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: VALIDATOR_FIELDS.USE_VALIDATORS,
      field: (
        <CheckboxInput
          name={VALIDATOR_FIELDS.USE_VALIDATORS}
          inputProps={{ disabled: isDisabled }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.BLOCK_SAVE_WITH_VALIDATION_ERRORS,
      field: (
        <CheckboxInput
          name={VALIDATOR_FIELDS.BLOCK_SAVE_WITH_VALIDATION_ERRORS}
          inputProps={{ disabled: isDisabled }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.WEIGHT,
      field: (
        <TextInput
          name={VALIDATOR_FIELDS.WEIGHT}
          inputProps={{ disabled: isDisabled, compressed: true }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.IS_REQUIRED_VALUE,
      field: (
        <CheckboxInput
          name={VALIDATOR_FIELDS.IS_REQUIRED_VALUE}
          inputProps={{ disabled: isDisabled }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.MIN_LENGTH,
      field: (
        <TextInput
          name={VALIDATOR_FIELDS.MIN_LENGTH}
          inputProps={{ disabled: isDisabled, compressed: true }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.MAX_LENGTH,
      field: (
        <TextInput
          name={VALIDATOR_FIELDS.MAX_LENGTH}
          inputProps={{ disabled: isDisabled, compressed: true }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.MIN_NUMERIC_VALUE,
      field: (
        <TextInput
          name={VALIDATOR_FIELDS.MIN_NUMERIC_VALUE}
          inputProps={{ disabled: isDisabled, compressed: true }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.MAX_NUMERIC_VALUE,
      field: (
        <TextInput
          name={VALIDATOR_FIELDS.MAX_NUMERIC_VALUE}
          inputProps={{ disabled: isDisabled, compressed: true }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.FORBIDDEN_WORDS,
      field: (
        <TextAreaInput
          name={VALIDATOR_FIELDS.FORBIDDEN_WORDS}
          inputProps={{ disabled: isDisabled }}
        />
      ),
    },
    {
      name: VALIDATOR_FIELDS.UUID,
      field: <>{validator?.uuid}</>,
    },
    {
      name: VALIDATOR_FIELDS.ID,
      field: <>{validator?.id}</>,
    },
    {
      name: VALIDATOR_FIELDS.CREATED_BY,
      field: <>{validator?.created_by || translate['message.empty_value']}</>,
    },
    {
      name: VALIDATOR_FIELDS.CREATED_AT,
      field: <>{getLocalDate(validator?.created_at) || translate['message.empty_value']}</>,
    },
    {
      name: VALIDATOR_FIELDS.UPDATED_AT,
      field: <>{getLocalDate(validator?.updated_at) || translate['message.empty_value']}</>,
    },
  ]

  const rows = rowsConfig.map(({ name, field }) => {
    return <InfoPageRow key={name} label={VALIDATOR_FIELD_NAMES[name]!} field={field} />
  })

  return rows
}

export { ValidatorInfoRows }
