import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { Search } from '@shared/ui'
import { noShadow } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'
import { UsersActions } from '@widgets/settings'

const UsersHeader: ReactFc = () => {
  const translate = useTranslate(['action.find_user'])

  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={translate['action.find_user']} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <UsersActions />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { UsersHeader }
