import { type ApiAttribute } from '@shared/api/types'
import { PAGE_TITLES } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'
import { type UseQueryResult } from '@tanstack/react-query'

const useAttributeTitle = (item: UseQueryResult<ApiAttribute>) => {
  const { isSuccess, data } = item
  const translate = useTranslate(['message.empty_name'])

  if (isSuccess) {
    const name = data?.name

    if (name) {
      return name
    }

    return translate['message.empty_name']
  }

  return PAGE_TITLES.LOADING
}

export { useAttributeTitle }
