import { $api } from '@shared/api'

import { type ValidatorsListQuery } from '../types'

export const useValidatorsListApi = (args: ValidatorsListQuery = {}) => {
  return $api.useQuery('get', '/validators/', {
    params: {
      query: {
        ...args,
      },
    },
  })
}
