import { Translate } from '@shared/ui/translate'

import { type Category } from '../types'
import { CategoriesCatalog } from '../ui'

const renderCategoriesCatalogs: RenderColumn<Category, 'object_group'> = (catalogs) => {
  if (!catalogs || catalogs?.length === 0) {
    return <Translate token="message.empty_value" />
  }

  return catalogs.map((catalog, index, array) => {
    if (!catalog) {
      return null
    }

    return (
      <>
        <CategoriesCatalog key={catalog} id={catalog} />
        {index < array.length - 1 && ', '}
      </>
    )
  })
}

export { renderCategoriesCatalogs }
