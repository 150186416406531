import { VALUE_FILTER_NAME } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'

const useValueFilterOptions = () => {
  const allTokens = Object.values(VALUE_FILTER_NAME)
  const translate = useTranslate(allTokens)

  return Object.entries(VALUE_FILTER_NAME).map(([id, label]) => {
    return { label: translate[label], data: { id } }
  })
}

export { useValueFilterOptions }
