import {
  useUpdateUserApi,
  useUpdateUserFieldValue,
  useUserApi,
  useUserInfoFields,
} from '@entities/users'
import { ROUTES } from '@shared/config'
import { useForm } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

const useUpdateUser = () => {
  const { id: userId } = useTypedParams(ROUTES.SETTINGS.USERS.USER)
  const { userFields = [] } = useUserInfoFields(userId)
  const { mutate: updateUser, isPending: isUserSubmitting } = useUpdateUserApi()
  const { data: user } = useUserApi(userId)

  const userFieldsFormValues: Record<string, string> = userFields?.reduce(
    (result, { name, value }) => {
      return { ...result, [name]: value }
    },
    {},
  )

  userFieldsFormValues.username = user?.username || ''

  const methods = useForm<Record<string, string>>({ values: userFieldsFormValues })

  const { isSubmitting: isUserFieldValuesSubmitting, updateUserFieldValue } =
    useUpdateUserFieldValue(userId)

  const {
    formState: { isDirty, dirtyFields },
  } = methods

  const handleUpdateUserFieldValues = methods.handleSubmit(async (data: Record<string, string>) => {
    const dirtyFieldsCopy = { ...dirtyFields }

    if (dirtyFieldsCopy.username) {
      updateUser({
        params: {
          path: {
            id: userId,
          },
        },
        body: {
          username: data.username,
        },
      })

      delete dirtyFieldsCopy.username
    }

    Object.keys(dirtyFieldsCopy).forEach((id) => {
      updateUserFieldValue({ fieldValueId: parseInt(id), newValue: data[id] })
    })
  })

  return {
    isSaveButtonActive: isDirty,
    handleUpdateUserFieldValues,
    isUserFieldValuesSubmitting: isUserFieldValuesSubmitting || isUserSubmitting,
    methods,
  }
}

export { useUpdateUser }
