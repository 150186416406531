import { useAddAttributeApi } from '@entities/attributes'
import { API_ATTRIBUTE_TYPE, API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { useNotificationsStore } from '@shared/ui/notifications'
import { NOTIFICATION } from '@shared/ui/notifications/ui/notification'
import { useTranslate } from '@shared/ui/translate'
import { useForm } from 'react-hook-form'

import { type AddAttributeFormInputs, type UseAddAttributeArgs } from './types'

const useAddAttribute = ({ onClose }: UseAddAttributeArgs) => {
  const addAttributeMutation = useAddAttributeApi()
  const addNotification = useNotificationsStore.useAddNotification()
  const translate = useTranslate(['error.server'])

  const methods = useForm<AddAttributeFormInputs>({
    defaultValues: { type: API_ATTRIBUTE_TYPE.TEXT },
  })

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_LIST })
    onClose()
    addNotification(NOTIFICATION.ATTRIBUTE_CREATE_SUCCESS)
  }

  const onError = () => {
    methods.setError('root', {
      type: 'manual',
      message: translate['error.server'],
    })
  }

  const addAttribute = methods.handleSubmit(({ name, type, group }) => {
    addAttributeMutation.mutate(
      {
        body: {
          name,
          attribute_type: type,
          object_group: group ? Number(group) : undefined,
        } as ApiAttribute,
      },
      { onSuccess, onError },
    )
  })

  return {
    handleModalClose,
    methods,
    addAttribute,
    isSubmitting: addAttributeMutation.isPending,
  }
}

export { useAddAttribute }
