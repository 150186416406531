import { EuiContext } from '@elastic/eui'
import { $api } from '@shared/api'
import { type TranslateMapping } from '@shared/ui/translate/types'
import moment from 'moment'

import { type TranslateMapperProps } from './types'

const TranslateMapper: ReactFc<TranslateMapperProps> = ({ language, children }) => {
  const { data: mapping } = $api.useQuery('get', '/translations/', {
    headers: {
      'Accept-Language': language,
    },
  })

  moment.updateLocale(language, {})

  return <EuiContext i18n={{ mapping: mapping as TranslateMapping }}>{children}</EuiContext>
}

export { TranslateMapper }
