import { EuiDatePicker, EuiFormRow } from '@elastic/eui'
import { userSettingsStore } from '@entities/user/store/user-settings-store'
import { DATE_FORMAT } from '@shared/config'
import { isFieldError } from '@shared/lib'
import { type Moment } from 'moment'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'

import { type DatePickerProps } from './types'

const DatePicker: ReactFc<DatePickerProps> = ({ name, registerOptions, label, inputProps }) => {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext()

  const language = userSettingsStore.getState().language

  const { ref } = register(name, registerOptions)
  const error = errors?.[name]
  const selectedDate = watch(name)

  const handleChange = (date: Moment | null) => {
    setValue(name, date ? date.toISOString() : null, { shouldDirty: true })
  }

  return (
    <EuiFormRow label={label} isInvalid={!!error} error={isFieldError(error) && error?.message}>
      <EuiDatePicker
        selected={selectedDate ? moment(selectedDate) : null} // Устанавливаем выбранную дату
        locale={language}
        dateFormat={DATE_FORMAT}
        onChange={handleChange}
        onClear={() => {
          return handleChange(null)
        }}
        inputRef={ref}
        {...inputProps}
      />
    </EuiFormRow>
  )
}

export { DatePicker }
