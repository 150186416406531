import { type EuiBasicTableColumn } from '@elastic/eui'
import { useTranslate } from '@shared/ui/translate'

import { renderCategoriesCatalogs, renderCategoriesName, renderCategoriesParent } from '../lib'
import { type Category } from '../types'

const useCategoriesColumns = (): EuiBasicTableColumn<Category>[] => {
  const translate = useTranslate([
    'label.name',
    'label.catalog',
    'label.parent_category',
    'label.attributes_in_category',
    'label.sku_in_category',
  ])

  return [
    {
      field: 'name',
      name: translate['label.name'],
      render: renderCategoriesName,
    },
    {
      field: 'object_group',
      name: translate['label.catalog'],
      render: renderCategoriesCatalogs,
    },
    {
      field: 'parent',
      name: translate['label.parent_category'],
      render: renderCategoriesParent,
    },
    {
      field: 'attributes_count',
      name: translate['label.attributes_in_category'],
    },
    {
      field: 'skus_count',
      name: translate['label.sku_in_category'],
    },
  ]
}

export { useCategoriesColumns }
