import { useAttributeOptionsApi } from '@entities/attributes'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { ROUTES } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { useTypedParams } from 'react-router-typesafe-routes'

const useRemoveAttributeOptions = (optionsIds?: number[]) => {
  const { id: attributeId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE)
  const addNotification = useNotificationsStore.useAddNotification()

  const { hasParam, getParamValues } = useRouterParams()
  const isDisabledButton = !hasParam('selected')

  const optionsForRemove = optionsIds
    ? optionsIds
    : getParamValues('selected').map((groupId) => {
        return parseInt(groupId)
      })

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_OPTIONS_LIST(attributeId) })
    addNotification(NOTIFICATION.ATTRIBUTE_OPTIONS_REMOVE_SUCCESS)
  }

  const removeOption = useAttributeOptionsApi.updateOption()

  const removeOptionsHandler = async () => {
    await Promise.all(
      optionsForRemove.map((id) => {
        return removeOption.mutateAsync({
          params: {
            path: {
              id,
            },
          },
          body: {
            is_deleted: true,
          },
        })
      }),
    )

    onSuccess()
  }

  return {
    isDisabledButton,
    isSubmitting: removeOption.isPending,
    removeOptionsHandler,
  }
}

export { useRemoveAttributeOptions }
