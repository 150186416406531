import { useAttributeApi, useUpdateAttribute } from '@entities/attributes'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { ROUTES } from '@shared/config'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useTypedParams } from 'react-router-typesafe-routes'

const useUpdateAttributeInfo = () => {
  const { id: attributeId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE)
  const { data: attribute } = useAttributeApi(attributeId)
  const methods = useForm<ApiAttribute>({ values: attribute })
  const navigate = useNavigate()
  const addNotification = useNotificationsStore.useAddNotification()
  const updateAttributeMutation = useUpdateAttribute()

  const {
    formState: { isDirty },
  } = methods

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [API_QUERY_KEY.ATTRIBUTE_LIST, attributeId],
    })

    methods.reset(methods.getValues())

    const { is_deleted, is_enabled } = methods.getValues()

    if (is_deleted || !is_enabled) {
      navigate(ROUTES.SETTINGS.ATTRIBUTES.$path())
    }

    addNotification(NOTIFICATION.ATTRIBUTE_UPDATE_SUCCESS)
  }

  const updateAttribute = methods.handleSubmit((data: ApiAttribute) => {
    updateAttributeMutation.mutate(
      {
        params: {
          path: {
            id: attributeId,
          },
        },
        body: data,
      },
      { onSuccess },
    )
  })

  return {
    isSaveButtonActive: isDirty,
    updateAttribute,
    isAttributeSubmitting: updateAttributeMutation.isPending,
    methods,
  }
}

export { useUpdateAttributeInfo }
