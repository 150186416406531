import { EuiForm } from '@elastic/eui'
import { API_ATTRIBUTE_MODEL, useAttributeListApi } from '@shared/api'
import { PARAMS } from '@shared/config'
import { FormError, SelectInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'

import { useAddSku, useSkuSelectOptions } from '../model'
import { type AddSkuProps } from './types'

const AddSku: ReactFc<AddSkuProps> = ({ isOpen, onClose }) => {
  const { handleModalClose, methods, handleSubmit, isSubmitting } = useAddSku(onClose)

  const { data: isNameAttributeData } = useAttributeListApi({
    [PARAMS.MODEL]: API_ATTRIBUTE_MODEL.SKU,
    [PARAMS.IS_NAME]: true,
  })

  const { data: isSkuAttributeData } = useAttributeListApi({
    [PARAMS.MODEL]: API_ATTRIBUTE_MODEL.SKU,
    [PARAMS.IS_SKU]: true,
  })

  const isNameLabel = isNameAttributeData?.results[0].name
  const isSkuLabel = isSkuAttributeData?.results[0].name

  const {
    catalogDefaultOption,
    categoryDefaultOption,
    isCatalogOptionsLoading,
    catalogOptions,
    isCategoryOptionsLoading,
    categoryOptions,
  } = useSkuSelectOptions()

  const translate = useTranslate([
    'action.new_sku',
    'label.sku_name',
    'label.sku_sku',
    'label.catalog',
    'label.category',
    'message.enter_name',
    'message.enter_sku',
    'message.select_catalog',
    'message.enter_category',
  ])

  return (
    <FormProvider {...methods}>
      <Modal
        title={translate['action.new_sku']}
        onClose={handleModalClose}
        onSubmit={handleSubmit}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <TextInput
            name="value_name"
            placeholder={isNameLabel}
            registerOptions={{ required: true }}
            inputProps={{
              isLoading: isSubmitting,
            }}
            isRequired
          />

          <TextInput
            name="value_sku"
            placeholder={isSkuLabel}
            registerOptions={{ required: true }}
            inputProps={{
              isLoading: isSubmitting,
            }}
            isRequired
          />

          <SelectInput
            name="object_group_id"
            placeholder={translate['message.select_catalog']}
            label={translate['label.catalog']}
            registerOptions={{ required: translate['message.select_catalog'] }}
            inputProps={{
              defaultValue: catalogDefaultOption,
              options: catalogOptions,
              isLoading: isCatalogOptionsLoading,
            }}
            isRequired
          />

          <SelectInput
            name="category_id"
            placeholder={translate['message.enter_category']}
            label={translate['label.category']}
            registerOptions={{ required: translate['message.enter_category'] }}
            inputProps={{
              defaultValue: categoryDefaultOption,
              options: categoryOptions,
              isLoading: isCategoryOptionsLoading,
            }}
            isRequired
          />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddSku }
