import { EuiPanel, EuiText } from '@elastic/eui'
import { useAutomaticTaskApi } from '@entities/automatic_tasks'
import { ROUTES } from '@shared/config'
import { InfoPageCategory } from '@shared/ui'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

import { AutomaticTaskInfoRows } from './automatic-task-info-rows'
import { type AutomaticTaskInfoProps } from './types'

const AutomaticTaskInfo: ReactFc<AutomaticTaskInfoProps> = ({ methods, isTaskSubmitting }) => {
  const { id: taskId } = useTypedParams(ROUTES.SETTINGS.AUTOMATIC_TASKS.AUTOMATIC_TASK)
  const { data: task, isLoading: isTaskLoading } = useAutomaticTaskApi(taskId)
  const translate = useTranslate(['message.empty_data', 'action.all_fields'])

  // TODO: добавить скелетон
  if (!task || isTaskLoading) {
    return (
      <EuiPanel>
        <EuiText textAlign="center">{translate['message.empty_data']}</EuiText>
      </EuiPanel>
    )
  }

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <FormProvider {...methods}>
        <InfoPageCategory id={taskId} categoryName={translate['action.all_fields']}>
          <AutomaticTaskInfoRows id={taskId} isSubmitting={isTaskSubmitting} />
        </InfoPageCategory>
      </FormProvider>
    </EuiPanel>
  )
}

export { AutomaticTaskInfo }
