import { htmlIdGenerator } from '@elastic/eui'
import { noOp } from '@shared/lib'
import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { create } from 'zustand'

import { type NotificationsInitialValues, type NotificationsStore } from './types'

const initialValues: NotificationsInitialValues = {
  notifications: [],
  canLeave: true,
  onLeave: noOp,
}

const notificationsStore = create<NotificationsStore>()((set) => {
  return {
    ...initialValues,

    addNotification: (notification) => {
      set((state) => {
        return {
          notifications: [
            ...state.notifications,
            {
              ...notification,
              id: notification.id + htmlIdGenerator()(),
            },
          ],
        }
      })
    },

    removeNotification: ({ id }) => {
      set((state) => {
        return {
          notifications: state.notifications.filter((notification) => {
            return notification.id !== id
          }),
        }
      })
    },

    setCanLeave: (canLeave: boolean) => {
      return set({ canLeave })
    },

    setOnLeave: (onLeave) => {
      set({
        onLeave: () => {
          onLeave()
          set({ canLeave: true })
        },
      })
    },
  }
})

const useNotificationsStore = createSelectorHooks(notificationsStore)

export { notificationsStore, useNotificationsStore }
