import { EuiFlexItem, EuiIconTip } from '@elastic/eui'
import { TextInput } from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'

import { type LoginInputProps } from './types'

const LoginInput: ReactFc<LoginInputProps> = ({ isSubmitting, inputProps, hasLabel = false }) => {
  const translate = useTranslate([
    'error.enter_user_name',
    'error.user_name_min_length',
    'error.user_name_max_length',
    'error.user_name_format',
    'label.user_name',
  ])

  const usernameValidateOptions = {
    required: translate['error.enter_user_name'],
    minLength: { value: 3, message: translate['error.user_name_min_length'] },
    maxLength: { value: 150, message: translate['error.user_name_max_length'] },
    validate: {
      nameFormat: (value: string) => {
        return /^[a-zA-Z0-9@._+-]+$/.test(value) || translate['error.user_name_format']
      },
    },
  }

  const usernameTooltip = (
    <>
      • Не более 150 символов
      <br />• Только буквы, цифры и символы @/./+/-/
    </>
  )

  return (
    <EuiFlexItem>
      <TextInput
        name="username"
        placeholder={translate['label.user_name']}
        registerOptions={usernameValidateOptions}
        hasLabel={hasLabel}
        inputProps={{
          ...inputProps,
          disabled: isSubmitting,
          autoComplete: 'username',
          append: [<EuiIconTip key="username" content={usernameTooltip} />],
        }}
        isRequired
      />
    </EuiFlexItem>
  )
}

export { LoginInput }
