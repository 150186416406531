import { useTranslate } from '@shared/ui/translate'
import { cloneDeep } from 'lodash'
import { type ChangeEvent, useEffect, useState } from 'react'

import { LIST, type TransferListItem } from '../types'
import { type MoveAllArgs, type MoveCheckedArgs, type UseTransferListArgs } from './types'

const getCheckboxFieldName = (name: string) => {
  const lastDotIndex = name.lastIndexOf('.')

  if (lastDotIndex !== -1) {
    return name.slice(lastDotIndex + 1, name.length).split('-')[1]
  }

  return name.split('-')[1]
}

const useTransferList = ({
  itemsList,
  selectedItems: selectedItemsExternal,
  isLoading = false,
}: UseTransferListArgs) => {
  const [availableItems, setAvailableItems] = useState<TransferListItem[]>([])
  const [selectedItems, setSelectedItems] = useState<TransferListItem[]>([])

  const translate = useTranslate([
    'label.select_all_columns',
    'label.select_checked_columns',
    'label.remove_checked_columns',
    'label.remove_all_columns',
  ])

  useEffect(() => {
    if (!itemsList && !selectedItemsExternal) {
      return
    }

    if (!itemsList && selectedItemsExternal) {
      setSelectedItems(cloneDeep(selectedItemsExternal))

      return
    }

    if (selectedItemsExternal) {
      const newAvailableItems = itemsList?.filter((item) => {
        return !selectedItemsExternal.find(({ name }) => {
          return name === item.name
        })
      })

      setAvailableItems(newAvailableItems || [])
      setSelectedItems(cloneDeep(selectedItemsExternal))

      return
    }

    setAvailableItems(itemsList ? cloneDeep(itemsList) : [])
  }, [isLoading])

  const handledToggle = (list: LIST) => {
    return ({ target }: ChangeEvent<HTMLInputElement>) => {
      const newItemsList = [...(list === LIST.SELECTED ? selectedItems : availableItems)]
      const setList = list === LIST.SELECTED ? setSelectedItems : setAvailableItems

      const { name, checked } = target

      const index = newItemsList.findIndex((item) => {
        return item.name === getCheckboxFieldName(name)
      })

      newItemsList[index].isChecked = checked

      setList(newItemsList)
    }
  }

  const hasChecked = (itemsList: TransferListItem[]) => {
    const indexOfChecked = itemsList.findIndex(({ isChecked }) => {
      return isChecked
    })

    return indexOfChecked > -1
  }

  const moveAll = ({ shouldSelect }: MoveAllArgs) => {
    return () => {
      setSelectedItems(shouldSelect ? cloneDeep(itemsList || []) : [])
      setAvailableItems(!shouldSelect ? cloneDeep(itemsList || []) : [])
    }
  }

  const moveChecked = ({
    sourceList,
    targetList,
    setSourceList,
    setTargetList,
  }: MoveCheckedArgs) => {
    return () => {
      const checkedItems = sourceList
        .filter(({ isChecked }) => {
          return isChecked
        })
        .map((item) => {
          return { ...item, isChecked: false }
        })

      const newValueMoveFrom = sourceList.filter(({ isChecked }) => {
        return !isChecked
      })

      const newValueMoveto = [...targetList, ...checkedItems].sort((prev, next) => {
        return prev.label.localeCompare(next.label)
      })

      setTargetList(newValueMoveto)
      setSourceList(newValueMoveFrom)
    }
  }

  const actions = [
    {
      isDisabled: !availableItems.length || isLoading,
      iconType: 'doubleArrowRight',
      ariaLabel: translate['label.select_all_columns'],
      onClick: moveAll({ shouldSelect: true }),
    },
    {
      isDisabled: !hasChecked(availableItems) || isLoading,
      iconType: 'arrowRight',
      ariaLabel: translate['label.select_checked_columns'],
      onClick: moveChecked({
        sourceList: availableItems,
        targetList: selectedItems,
        setSourceList: setAvailableItems,
        setTargetList: setSelectedItems,
      }),
    },
    {
      isDisabled: !hasChecked(selectedItems) || isLoading,
      iconType: 'arrowLeft',
      ariaLabel: translate['label.remove_checked_columns'],
      onClick: moveChecked({
        sourceList: selectedItems,
        targetList: availableItems,
        setSourceList: setSelectedItems,
        setTargetList: setAvailableItems,
      }),
    },
    {
      isDisabled: !selectedItems.length || isLoading,
      iconType: 'doubleArrowLeft',
      ariaLabel: translate['label.remove_all_columns'],
      onClick: moveAll({ shouldSelect: false }),
    },
  ]

  return { selectedItems, availableItems, actions, handledToggle }
}

export { useTransferList }
