import { EuiButton, EuiForm } from '@elastic/eui'
import { FormError, PasswordInput, TextInput } from '@shared/ui/form'
import { textLeft } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'

import { useAuthForm } from '../model'

export const AuthForm = () => {
  const { methods, handleSubmit, isSubmitting } = useAuthForm()
  const translate = useTranslate(['label.login', 'action.login_button', 'error.enter_login'])

  return (
    <FormProvider {...methods}>
      <EuiForm component="form" onSubmit={handleSubmit} css={textLeft}>
        <TextInput
          name="username"
          placeholder={translate['label.login']}
          icon="user"
          registerOptions={{ required: translate['error.enter_login'] }}
          inputProps={{
            isLoading: isSubmitting,
          }}
        />
        <PasswordInput name="password" inputProps={{ isLoading: isSubmitting }} />

        <EuiButton type="submit" fill fullWidth isLoading={isSubmitting}>
          {translate['action.login_button']}
        </EuiButton>

        <FormError />
      </EuiForm>
    </FormProvider>
  )
}
