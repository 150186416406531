import { useAutomaticTaskApi } from '@entities/automatic_tasks'
import { useUpdateAutomaticTask } from '@features/automatic-tasks'
import { PAGE_TITLES, ROUTES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'
import { AutomaticTaskHeader, AutomaticTaskInfo } from '@widgets/settings'
import { useTypedParams } from 'react-router-typesafe-routes'

export const AutomaticTaskPage = () => {
  const { id: taskId } = useTypedParams(ROUTES.SETTINGS.AUTOMATIC_TASKS.AUTOMATIC_TASK)
  const { data: task } = useAutomaticTaskApi(taskId)

  const {
    isSaveButtonActive,
    updateAutomaticTask,
    isLoading: isAutomaticTaskSubmitting,
    methods,
  } = useUpdateAutomaticTask({ taskId })

  const translate = useTranslate([PAGE_TITLES.SETTINGS_AUTOMATIC_TASKS])
  useMeta({ title: `${translate[PAGE_TITLES.SETTINGS_AUTOMATIC_TASKS]} ${task?.name}` })

  return (
    <Layout
      header={
        <AutomaticTaskHeader
          updateAutomaticTask={updateAutomaticTask}
          isAutomaticTaskSubmitting={isAutomaticTaskSubmitting}
          isSaveButtonActive={isSaveButtonActive}
        />
      }
    >
      <AutomaticTaskInfo methods={methods} isTaskSubmitting={isAutomaticTaskSubmitting} />
    </Layout>
  )
}
