import {
  API_ATTRIBUTE_LIST,
  API_QUERY_KEY,
  buildRequestApi,
  HTTP_METHOD,
  queryClient,
} from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { ROUTES } from '@shared/config'
import { useMutation } from '@tanstack/react-query'
import { useTypedParams } from 'react-router-typesafe-routes'

export const useAddAttributeToGroup = () => {
  const { id: attributeGroupId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP)

  const mutation = useMutation({
    mutationFn: (attributeId: number) => {
      const addObjectGroup = buildRequestApi<ApiAttribute>(
        `${API_ATTRIBUTE_LIST}${attributeId}/`,
        'Ошибка добавления атрибута в группу',
        {
          method: HTTP_METHOD.PATCH,
          body: {
            object_group: attributeGroupId,
          },
        },
      )

      return addObjectGroup()
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: API_QUERY_KEY.ATTRIBUTE_LIST,
      })

      mutation.reset()
    },
  })

  const addToGroup = (id: number) => {
    return () => {
      mutation.mutate(id)
    }
  }

  const isProcessed = (id: number) => {
    return mutation.variables === id
  }

  const isPending = mutation.isPending

  return {
    isPending,
    addToGroup,
    isProcessed,
  }
}
