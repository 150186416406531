import { EuiFormRow, useEuiTheme } from '@elastic/eui'
import { userSettingsStore } from '@entities/user/store/user-settings-store'
import Editor, { loader } from '@monaco-editor/react'
import { ELEMENTS_S_WIDTH } from '@shared/config'
import { isFieldError } from '@shared/lib'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslate } from '../translate'
import { type JsonInputProps } from './types'

const JsonInput: ReactFc<JsonInputProps> = ({ name, placeholder, registerOptions, inputProps }) => {
  const euiTheme = useEuiTheme()
  const translate = useTranslate(['error.json_incorrect'])
  const theme = euiTheme.colorMode === 'DARK' ? 'vs-dark' : undefined
  const language = userSettingsStore.getState().language

  loader.config({ 'vs/nls': { availableLanguages: { '*': language } } })

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const error = errors?.[name]

  const validateJson = (value: string) => {
    try {
      if (value) {
        JSON.parse(value)
      }

      return true
    } catch {
      return translate['error.json_incorrect']
    }
  }

  return (
    <EuiFormRow
      label={placeholder}
      isInvalid={!!error}
      error={isFieldError(error) && error.message}
      fullWidth
    >
      <Controller
        name={name}
        control={control}
        rules={{ ...registerOptions, validate: validateJson }}
        render={({ field: { onChange, value } }) => {
          return (
            <Editor
              width="100%"
              height={ELEMENTS_S_WIDTH}
              defaultLanguage="json"
              value={value}
              onChange={(value) => {
                return onChange(value)
              }}
              theme={theme}
              {...inputProps}
            />
          )
        }}
      />
    </EuiFormRow>
  )
}

export { JsonInput }
