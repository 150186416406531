import { DATE_FORMAT, DATE_RANGE_LABEL, PARAMS } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'
import moment from 'moment'

const useParamProgressOptions = () => {
  const translate = useTranslate([
    'label.progress_0_25',
    'label.progress_25_50',
    'label.progress_50_75',
    'label.progress_75_100',
  ])

  return [
    {
      value: 'progress_0_25',
      text: translate['label.progress_0_25'],
      data: [
        {
          param: PARAMS.PROGRESS_MIN,
          value: '0',
        },
        {
          param: PARAMS.PROGRESS_MAX,
          value: '25',
        },
      ],
    },
    {
      value: 'progress_25_50',
      text: translate['label.progress_25_50'],
      data: [
        {
          param: PARAMS.PROGRESS_MIN,
          value: '25',
        },
        {
          param: PARAMS.PROGRESS_MAX,
          value: '50',
        },
      ],
    },
    {
      value: 'progress_50_75',
      text: translate['label.progress_50_75'],
      data: [
        {
          param: PARAMS.PROGRESS_MIN,
          value: '50',
        },
        {
          param: PARAMS.PROGRESS_MAX,
          value: '75',
        },
      ],
    },
    {
      value: 'progress_75_100',
      text: translate['label.progress_75_100'],
      data: [
        {
          param: PARAMS.PROGRESS_MIN,
          value: '75',
        },
        {
          param: PARAMS.PROGRESS_MAX,
          value: '100',
        },
      ],
    },
  ]
}

const useParamPublicOptions = () => {
  const translate = useTranslate(['label.public', 'label.draft'])

  return [
    {
      value: 'public',
      text: translate['label.public'],
      data: [
        {
          param: PARAMS.PUBLIC,
          value: 'true',
        },
      ],
    },
    {
      value: 'draft',
      text: translate['label.draft'],
      data: [
        {
          param: PARAMS.PUBLIC,
          value: 'false',
        },
      ],
    },
  ]
}

const useParamDateOptions = (id: string) => {
  const translate = useTranslate([
    'label.today',
    'label.yesterday',
    'label.this_week',
    'label.this_month',
    'label.last_month',
    'label.last_30_days',
    'label.this_year',
    'label.select_period',
  ])

  return [
    {
      value: 'tooday',
      text: translate['label.today'],
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('day').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'yesterday',
      text: translate['label.yesterday'],
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().subtract(1, 'days').format(DATE_FORMAT),
        },
        {
          param: id + PARAMS.BEFORE,
          value: moment().startOf('day').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'this-week',
      text: translate['label.this_week'],
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('isoWeek').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'this-month',
      text: translate['label.this_month'],
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('month').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'last-month',
      text: translate['label.last_month'],
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT),
        },
        {
          param: id + PARAMS.BEFORE,
          value: moment().startOf('month').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'last-30-days',
      text: translate['label.last_30_days'],
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().subtract(1, 'months').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: 'this-year',
      text: translate['label.this_year'],
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('year').format(DATE_FORMAT),
        },
      ],
    },
    {
      value: DATE_RANGE_LABEL,
      text: translate['label.select_period'],
      data: [
        {
          param: id + PARAMS.AFTER,
          value: moment().startOf('month').format(DATE_FORMAT),
        },
        {
          param: id + PARAMS.BEFORE,
          value: moment().format(DATE_FORMAT),
        },
      ],
    },
  ]
}

const useAttributeBaseOptions = () => {
  const translate = useTranslate(['label.value_exist_true', 'label.value_exist_false'])

  return [
    {
      value: 'value-exist-true',
      text: translate['label.value_exist_true'],
      data: {
        filterPostfix: PARAMS.ATTRIBUTE_VALUE_ISNULL,
        filterValues: 'false',
      },
    },
    {
      value: 'value-exist-false',
      text: translate['label.value_exist_false'],
      data: {
        filterPostfix: PARAMS.ATTRIBUTE_VALUE_ISNULL,
        filterValues: 'true',
      },
    },
  ]
}

const useAttributeTextOptions = () => {
  const translate = useTranslate([
    'label.value_equal',
    'label.value_not_equal',
    'label.value_contain',
    'label.value_not_contain',
  ])

  return [
    {
      value: 'value-equal',
      text: translate['label.value_equal'],
      data: {
        filterPostfix: PARAMS.ATTRIBUTE_VALUE_EQUAL,
        filterValues: '',
      },
    },
    {
      value: 'value-not-equal',
      text: translate['label.value_not_equal'],
      data: {
        filterPostfix: PARAMS.ATTRIBUTE_VALUE_NOT_EQUAL,
        filterValues: '',
      },
    },
    {
      value: 'value-contain',
      text: translate['label.value_contain'],
      data: {
        filterPostfix: PARAMS.ATTRIBUTE_VALUE_CONTAIN,
        filterValues: '',
      },
    },
    {
      value: 'value-not-contain',
      text: translate['label.value_not_contain'],
      data: {
        filterPostfix: PARAMS.ATTRIBUTE_VALUE_NOT_CONTAIN,
        filterValues: '',
      },
    },
  ]
}

const useAttributeDateOptions = () => {
  const translate = useTranslate(['label.select_period'])

  return [
    {
      value: DATE_RANGE_LABEL,
      text: translate['label.select_period'],
      data: {
        filterPostfix: PARAMS.ATTRIBUTE_AFTER,
        filterValues: moment().startOf('month').format(DATE_FORMAT),
      },
    },
  ]
}

export {
  useParamProgressOptions,
  useParamPublicOptions,
  useParamDateOptions,
  useAttributeBaseOptions,
  useAttributeTextOptions,
  useAttributeDateOptions,
}
