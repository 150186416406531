import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { AttributeFormField } from '@entities/attributes'

import { SkuAttributeFile } from './sku-attribute-file'
import { SkuAttributeName } from './sku-attribute-name'
import { type SkuAttributeProps } from './types'

const SkuAttribute: ReactFc<SkuAttributeProps> = ({ attribute, isSubmitting }) => {
  const isFile = attribute.files

  return (
    <EuiFlexGroup alignItems="flexStart" justifyContent="spaceBetween">
      <EuiFlexItem grow={3}>
        <SkuAttributeName {...attribute} />
      </EuiFlexItem>

      <EuiFlexItem grow={7}>
        <AttributeFormField
          attribute={attribute}
          disablePlaceholder
          isCompressed
          isSubmitting={isSubmitting}
        />

        {isFile && attribute.files.length > 0 && <SkuAttributeFile {...attribute} />}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export { SkuAttribute }
