import { $api, API_QUERY_KEY, queryClient } from '@shared/api'
import { ROUTES } from '@shared/config'
import { useTypedParams } from 'react-router-typesafe-routes'

const useRestoreSku = (versionId: number, closeModal: EmptyCallback) => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const restoreVersionMutation = $api.useMutation('post', '/sku_history/rollback-sku-history/')

  const restoreVersion = async () => {
    await restoreVersionMutation.mutateAsync({
      body: {
        version: versionId,
        sku_id: skuId,
      },
    })

    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.SKU(skuId) })
    closeModal()
  }

  const isSubmitting = restoreVersionMutation.isPending

  return {
    restoreVersion,
    isSubmitting,
  }
}

export { useRestoreSku }
