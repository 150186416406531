import { useEuiI18n } from '@elastic/eui'
import { DEFAULT_LANGUAGE } from '@shared/config'
import { TRANSLATE_MAPPING } from '@shared/config/translate'

import { type TranslateToken, type TranslationResult } from './types'

const useTranslate = <T extends TranslateToken[]>(tokens: T) => {
  const defaultValues = tokens.map((token) => {
    return (TRANSLATE_MAPPING[DEFAULT_LANGUAGE] as Record<TranslateToken, string>)[token]
  })

  const translations = useEuiI18n(tokens, defaultValues)

  return tokens.reduce((acc, token, index) => {
    acc[token as keyof typeof acc] = translations[index]

    return acc
  }, {} as TranslationResult<T>)
}

export { useTranslate }
