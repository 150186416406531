import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false, // Отключает автоматический перезапрос данных при монтировании компонента
      retryOnMount: false, // Запрещает повторные попытки запроса при монтировании компонента в случае ошибки
      refetchOnReconnect: false, // Отключает автоматический перезапрос данных при восстановлении сетевого соединения
      refetchInterval: false, // Отключает автоматический перезапрос данных с интервалом времени
      retry: 1, // Количество автоматических повторов запроса при неудаче (по умолчанию 1 попытка)
    },
  },
})
