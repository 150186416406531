import { EuiButton, EuiEmptyPrompt, EuiPageSection } from '@elastic/eui'
import { PAGE_TITLES, ROUTES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { minHViewport } from '@shared/ui/styles'
import { Translate, useTranslate } from '@shared/ui/translate'
import { Link } from 'react-router'

export const PermissionErrorPage: ReactFc = () => {
  const translate = useTranslate([PAGE_TITLES.PERMISSION_ERROR])
  const title = translate[PAGE_TITLES.PERMISSION_ERROR]
  useMeta({ title })

  return (
    <Layout>
      <EuiPageSection alignment="center" grow css={minHViewport}>
        <EuiEmptyPrompt
          title={<h2>{title}</h2>}
          body={
            <p>
              <Translate token="error.permission" />
            </p>
          }
          actions={
            <Link to={ROUTES.LOGOUT.$path()}>
              <EuiButton fill>
                <Translate token="action.switch_user" />
              </EuiButton>
            </Link>
          }
        />
      </EuiPageSection>
    </Layout>
  )
}
