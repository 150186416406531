import { EuiFlexGroup, EuiSpacer } from '@elastic/eui'
import { useUpdateAttributeFiles } from '@entities/attributes'
import { FileCard } from '@entities/files'
import { type ApiFile } from '@shared/api/types'
import { useNotificationsStore } from '@shared/ui/notifications'

import { type SkuAttributeFileProps } from './types'

const SkuAttributeFile: ReactFc<SkuAttributeFileProps> = (attribute) => {
  // Сохранение
  const { updateAttributeFiles, isRemoving } = useUpdateAttributeFiles()

  const canLeave = useNotificationsStore.useCanLeave()
  const setOnLeave = useNotificationsStore.useSetOnLeave()

  const buildRemoveHandler = (file: ApiFile) => {
    if (canLeave) {
      return updateAttributeFiles({ attribute, file })
    }

    return () => {
      setOnLeave(updateAttributeFiles({ attribute, file }))
    }
  }

  return (
    <>
      <EuiSpacer size="s" />
      <EuiFlexGroup gutterSize="s" wrap>
        {attribute.files.map((file) => {
          return (
            <FileCard
              key={file.id}
              {...file}
              onRemove={buildRemoveHandler(file)}
              isRemoving={isRemoving}
              isEditable={attribute.is_editable}
            />
          )
        })}
      </EuiFlexGroup>
      <EuiSpacer />
    </>
  )
}

export { SkuAttributeFile }
