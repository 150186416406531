import { useUpdateAttributeFiles, useUpdateObjectAttributes } from '@entities/attributes'
import { useSkuApi, useSkuDefaultValues } from '@entities/catalog'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { ROUTES } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { useForm } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

const useUpdateAttribute = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { data: sku } = useSkuApi(skuId)
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm()

  // Заполняем значения атрибутов
  useSkuDefaultValues({ id: skuId, methods })

  // Отслеживаем изменения всех полей формы
  const watchedFields = methods.watch()

  const changed = Object.keys(watchedFields).filter((field) => {
    return methods.formState.dirtyFields[field]
  })

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const onSuccess = () => {
    closeModal()
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.SKU(skuId) })
  }

  const { handleUpdateAttribute, isSubmitting } = useUpdateObjectAttributes(skuId, onSuccess)

  const updateAttribute = methods.handleSubmit(() => {
    changed.map(async (id) => {
      const attributeId = parseInt(id)

      const attribute = sku?.attributes.find(({ id }) => {
        return id === attributeId
      })

      try {
        await handleUpdateAttribute(
          attributeId,
          watchedFields[attributeId],
          attribute?.value_id,
          attribute?.attribute_type,
        )
      } catch (error) {
        methods.setError(`${attributeId}`, {
          type: 'manual',
          message: `${error}`,
        })
      }
    })
  })

  // Удаляем файл
  const { updateAttributeFiles, isRemoving } = useUpdateAttributeFiles()

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    updateAttribute,
    updateAttributeFiles,
    isRemoving,
    isSubmitting,
  }
}

export { useUpdateAttribute }
