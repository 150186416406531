import { EuiSkeletonLoading, EuiSkeletonText, EuiText, EuiTreeView } from '@elastic/eui'
import { useCategoriesListApi } from '@entities/categories'
import { useTranslate } from '@shared/ui/translate'

import { useCategoryFilter } from '../model'
import { type CategoryFilterProps } from '../types'
import { treeWrapper } from './styles'

export const CategoryFilter: ReactFc<CategoryFilterProps> = ({ getTreeItems }) => {
  const { isLoading } = useCategoriesListApi()
  const { treeItems, search } = useCategoryFilter()

  const translate = useTranslate([
    'message.empty',
    'message.no_categories',
    'label.category_filter',
  ])

  if (!isLoading && treeItems.length === 0) {
    if (search === '') {
      return (
        <EuiText size="s" color="subdued">
          {translate['message.no_categories']}
        </EuiText>
      )
    }

    return (
      <EuiText size="s" color="subdued">
        {translate['message.empty']}
      </EuiText>
    )
  }

  return (
    <EuiSkeletonLoading
      isLoading={isLoading}
      contentAriaLabel={translate['label.category_filter']}
      loadingContent={<EuiSkeletonText size="m" lines={6} />}
      css={treeWrapper}
      loadedContent={
        <EuiTreeView
          key={search} // Добавляем ключ для перерисовки компонента во время поиска
          showExpansionArrows
          items={getTreeItems(treeItems)}
        />
      }
    />
  )
}
