import { ProgressWithStatus } from '@entities/catalog'
import { useTranslate } from '@shared/ui/translate'

import { FilesCardStatus } from './files-card-status'
import { FILE_STATUS, type FilesCardDownloadInfoProps } from './types'

const FilesCardDownloadInfo: ReactFc<FilesCardDownloadInfoProps> = ({ status, progress }) => {
  const translate = useTranslate(['message.done', 'message.error'])

  if (status === FILE_STATUS.COMPLETED_SUCCESS) {
    return (
      <FilesCardStatus
        type="checkInCircleFilled"
        color="success"
        text={translate['message.done']}
      />
    )
  }

  if (status === FILE_STATUS.COMPLETED_ERROR) {
    return <FilesCardStatus type="warning" color="danger" text={translate['message.error']} />
  }

  return <ProgressWithStatus {...{ progress, status }} />
}

export { FilesCardDownloadInfo }
