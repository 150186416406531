import { $api, API_QUERY_KEY, queryClient, useAttributeListApi } from '@shared/api'
import { useRouterParams } from '@shared/hooks'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'

const useRemoveAttributeGroups = (groupIds?: number[]) => {
  const addNotification = useNotificationsStore.useAddNotification()

  const { hasParam, getParamValues } = useRouterParams()
  const isDisabledButton = !hasParam('selected')

  const groupsForRemove = groupIds
    ? groupIds
    : getParamValues('selected').map((groupId) => {
        return parseInt(groupId)
      })

  const attributesInGroups = useAttributeListApi({
    object_group: groupsForRemove,
  })

  const clearAttributes = $api.useMutation('patch', '/attributes/{id}/', {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_LIST })
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_GROUP_LIST })
    },
  })

  const removeGroups = $api.useMutation('patch', '/object_groups/{id}/', {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_GROUP_LIST })
      addNotification(NOTIFICATION.ATTRIBUTE_GROUP_REMOVE_SUCCESS)
    },
  })

  // TODO: Заменить когда появится эндпоинт удаления группы атрибутов
  const removeFromGroups = () => {
    attributesInGroups.data?.results.map(({ id }) => {
      clearAttributes.mutate({
        params: {
          path: {
            id,
          },
        },
        body: {
          object_group: null,
        },
      })
    })

    groupsForRemove.map((id) => {
      removeGroups.mutate({
        params: {
          path: {
            id,
          },
        },
        body: {
          is_deleted: true,
        },
      })
    })
  }

  return {
    isDisabledButton,
    isSubmitting: removeGroups.isPending || clearAttributes.isPending,
    removeFromGroups,
  }
}

export { useRemoveAttributeGroups }
