import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiSkuHistory } from '@shared/api/types'
import { formatDate } from '@shared/lib'
import { Translate } from '@shared/ui/translate'

const SKU_HISTORY_COLUMNS: EuiBasicTableColumn<ApiSkuHistory>[] = [
  {
    field: 'version',
    name: Translate({ token: 'label.version' }),
    width: '150px',
  },
  {
    field: 'attribute',
    name: Translate({ token: 'label.attribute' }),
  },
  {
    field: 'changed_at',
    name: Translate({ token: 'label.create_date' }),
    render: formatDate,
  },
  {
    field: 'changed_by',
    name: Translate({ token: 'label.user' }),
  },
]

export { SKU_HISTORY_COLUMNS }
