import { EuiFieldSearch } from '@elastic/eui'
import { useTranslate } from '@shared/ui/translate'
import { useDebounce } from '@uidotdev/usehooks'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { type FilterFields } from '../../types'
import { type FilterAttributeProps } from '../types'

const FilterContain: ReactFc<FilterAttributeProps> = ({ attribute }) => {
  const { slug } = attribute
  const { getValues, setValue } = useFormContext<FilterFields>()
  const currentField = getValues(`filters.${slug}`)?.filterParams[0]
  const [searchTerm, setSearchTerm] = useState<string>()
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const translate = useTranslate(['label.value'])

  useEffect(() => {
    if (debouncedSearchTerm !== currentField.value) {
      setValue(`filters.${slug}.filterParams`, [
        {
          param: currentField.param,
          value: debouncedSearchTerm,
        },
      ])
    }
  }, [debouncedSearchTerm, currentField.value])

  return (
    <EuiFieldSearch
      placeholder={translate['label.value']}
      onSearch={(value) => {
        setSearchTerm(value)
      }}
      incremental
      compressed
    />
  )
}

export { FilterContain }
