import { $api } from '@shared/api'
import _ from 'lodash'

const useUserPermissions = () => {
  const { isLoading, isError, data } = $api.useQuery('get', '/user_settings/')

  return {
    isLoading,
    isError,
    permissions: data?.permissions,
  }
}

export { useUserPermissions }
