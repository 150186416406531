import { PermissionProvider, privateLoader } from '@entities/user'
import { CatalogPage, SkuPage, SkuPagePrint } from '@pages/catalog'
import { ErrorPage, PermissionErrorPage } from '@pages/error'
import * as Export from '@pages/export'
import { ImportPage } from '@pages/import'
import { LoginPage } from '@pages/login'
import { LogoutPage } from '@pages/logout'
import * as Settings from '@pages/settings'
import { PERMISSIONS } from '@shared/api'
import { PAGE_TITLES, ROUTES } from '@shared/config'
import { redirectLoader } from '@shared/lib'
import { Translate } from '@shared/ui/translate'
import { createBrowserRouter } from 'react-router'

import { Base } from './base'

export const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    loader: privateLoader,
    element: (
      <PermissionProvider>
        <Base />
      </PermissionProvider>
    ),
    children: [
      {
        index: true,
        loader: redirectLoader(ROUTES.CATALOG.$path()),
      },
      {
        path: ROUTES.CATALOG.$path(),
        handle: {
          crumb: Translate({ token: PAGE_TITLES.CATALOG }),
          permission: PERMISSIONS.CAN_VIEW_CATALOG,
        },
        children: [
          {
            index: true,
            Component: CatalogPage,
            handle: {
              disableBack: true,
            },
          },
          {
            path: ROUTES.CATALOG.SKU.$path(),
            Component: SkuPage,
            handle: {
              hasDynamicTitle: true,
            },
          },
        ],
      },
      {
        path: ROUTES.SETTINGS.$path(),
        handle: {
          crumb: Translate({ token: PAGE_TITLES.SETTINGS }),
        },
        children: [
          {
            index: true,
            loader: redirectLoader(ROUTES.SETTINGS.ATTRIBUTES.$path()),
            handle: {
              permission: PERMISSIONS.CAN_VIEW_ATTRIBUTES,
            },
          },
          {
            path: ROUTES.SETTINGS.ATTRIBUTES.$path(),
            handle: {
              crumb: Translate({ token: PAGE_TITLES.SETTINGS_ATTRIBUTES }),
            },
            children: [
              {
                index: true,
                Component: Settings.AttributesPage,
                handle: {
                  disableBack: true,
                },
              },
              {
                Component: Settings.AttributePage,
                path: ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE.$path(),
                handle: {
                  hasDynamicTitle: true,
                },
              },
            ],
          },
          {
            path: ROUTES.SETTINGS.ATTRIBUTE_GROUPS.$path(),
            handle: {
              crumb: Translate({ token: PAGE_TITLES.SETTINGS_ATTRIBUTE_GROUPS }),
              permission: PERMISSIONS.CAN_VIEW_OBJECT_GROUPS,
            },
            children: [
              {
                index: true,
                Component: Settings.AttributeGroupsPage,
                handle: {
                  disableBack: true,
                },
              },
              {
                Component: Settings.AttributeGroupPage,
                path: ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP.$path(),
                handle: {
                  hasDynamicTitle: true,
                },
              },
            ],
          },
          {
            path: ROUTES.SETTINGS.VALIDATORS.$path(),
            handle: {
              crumb: Translate({ token: PAGE_TITLES.SETTINGS_VALIDATORS }),
            },
            children: [
              {
                index: true,
                Component: Settings.ValidatorsPage,
                handle: {
                  disableBack: true,
                },
              },
              {
                Component: Settings.ValidatorPage,
                path: ROUTES.SETTINGS.VALIDATORS.VALIDATOR.$path(),
                handle: {
                  hasDynamicTitle: true,
                },
              },
            ],
          },
          {
            path: ROUTES.SETTINGS.USERS.$path(),
            handle: {
              crumb: Translate({ token: PAGE_TITLES.SETTINGS_USERS }),
            },
            children: [
              {
                index: true,
                Component: Settings.UsersPage,
                handle: {
                  disableBack: true,
                },
              },
              {
                Component: Settings.UserPage,
                path: ROUTES.SETTINGS.USERS.USER.$path(),
                handle: {
                  hasDynamicTitle: true,
                },
              },
            ],
          },
          {
            path: ROUTES.SETTINGS.CATEGORIZATION.$path(),
            handle: {
              crumb: Translate({ token: PAGE_TITLES.SETTINGS_CATEGORIZATION }),
            },
            children: [
              {
                index: true,
                Component: Settings.CategorizationPage,
                handle: {
                  disableBack: true,
                },
              },
              {
                Component: Settings.CategoryPage,
                path: ROUTES.SETTINGS.CATEGORIZATION.CATEGORY.$path(),
                handle: {
                  hasDynamicTitle: true,
                },
              },
            ],
          },
          // {
          //   path: ROUTES.SETTINGS.CLUSTERING.$path(),
          //   Component: Settings.ClusteringPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.CLUSTERING.title,
          //     disableBack: true,
          //   },
          // },
          // {
          //   path: ROUTES.SETTINGS.CURRENCIES_AND_PRICES.$path(),
          //   Component: Settings.CurrenciesAndPricesPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.CURRENCIES_AND_PRICES.title,
          //     disableBack: true,
          //   },
          // },
          // {
          //   path: ROUTES.SETTINGS.INFO_MODELS.$path(),
          //   Component: Settings.InfoModelsPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.INFO_MODELS.title,
          //     disableBack: true,
          //   },
          // },
          // {
          //   path: ROUTES.SETTINGS.RELATED_PRODUCTS.$path(),
          //   Component: Settings.RelatedProductsPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.RELATED_PRODUCTS.title,
          //     disableBack: true,
          //   },
          // },
          // {
          //   path: ROUTES.SETTINGS.RULES.$path(),
          //   Component: Settings.RulesPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.RULES.title,
          //     disableBack: true,
          //   },
          // },
          // {
          //   path: ROUTES.SETTINGS.TAXONOMY.$path(),
          //   Component: Settings.TaxonomyPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.TAXONOMY.title,
          //     disableBack: true,
          //   },
          // },
          // {
          //   path: ROUTES.SETTINGS.TEMPLATES.$path(),
          //   Component: Settings.TemplatesPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.TEMPLATES.title,
          //     disableBack: true,
          //   },
          // },
          // {
          //   path: ROUTES.SETTINGS.UNITS.$path(),
          //   Component: Settings.UnitsPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.UNITS.title,
          //     disableBack: true,
          //   },
          // },
          // {
          //   path: ROUTES.SETTINGS.VALUE_LISTS.$path(),
          //   Component: Settings.ValueListsPage,
          //   handle: {
          //     crumb: PAGE_TITLES.SETTINGS.VALUE_LISTS.title,
          //     disableBack: true,
          //   },
          // },
          {
            path: ROUTES.SETTINGS.AUTOMATIC_TASKS.$path(),
            handle: {
              crumb: Translate({ token: PAGE_TITLES.SETTINGS_AUTOMATIC_TASKS }),
            },
            children: [
              {
                index: true,
                Component: Settings.AutomaticTasksPage,
                handle: {
                  disableBack: true,
                },
              },
              {
                Component: Settings.AutomaticTaskPage,
                path: ROUTES.SETTINGS.AUTOMATIC_TASKS.AUTOMATIC_TASK.$path(),
                handle: {
                  hasDynamicTitle: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.EXPORT.$path(),
        handle: {
          crumb: Translate({ token: PAGE_TITLES.EXPORT }),
        },
        children: [
          {
            index: true,
            loader: redirectLoader(ROUTES.EXPORT.FILES.$path()),
          },
          {
            path: ROUTES.EXPORT.FILES.$path(),
            Component: Export.FilesPage,
            handle: {
              crumb: Translate({ token: PAGE_TITLES.EXPORT_FILES }),
              disableBack: true,
            },
          },
        ],
      },
      {
        path: ROUTES.IMPORT.$path(),
        handle: {
          crumb: Translate({ token: PAGE_TITLES.IMPORT }),
        },
        Component: ImportPage,
      },
      {
        path: ROUTES.PERMISSION_ERROR.$path(),
        Component: PermissionErrorPage,
      },
      {
        path: ROUTES.ERROR.$path(),
        Component: ErrorPage,
      },
    ],
  },
  {
    path: ROUTES.LOGIN.$path(),
    Component: LoginPage,
  },
  {
    path: ROUTES.LOGOUT.$path(),
    Component: LogoutPage,
  },
  {
    path: ROUTES.CATALOG.SKU.PRINT.$path(),
    Component: SkuPagePrint,
  },
])
