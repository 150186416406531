import { useAddAttributeValue } from '@entities/attributes'
import {
  $api,
  API_ATTRIBUTE_MODEL,
  API_QUERY_KEY,
  queryClient,
  useAttributeListApi,
} from '@shared/api'
import { useDialog } from '@shared/hooks'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { useTranslate } from '@shared/ui/translate'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { type CategoryFormInputs } from './types'
import { useCategoryForm } from './use-category-form'

export const useAddCategory = () => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm<CategoryFormInputs>()
  const translate = useTranslate(['error.server'])

  const { isCatalogOptionsLoading, catalogOptions } = useCategoryForm()

  const addNotification = useNotificationsStore.useAddNotification()

  const add = $api.useMutation('post', '/categories/')
  const addCategoryName = useAddAttributeValue()

  useEffect(() => {
    if (catalogOptions && catalogOptions.length === 1) {
      methods.reset({
        object_group: [catalogOptions[0]],
      })
    }
  }, [isCatalogOptionsLoading])

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const categoryAttributes = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.CATEGORY,
  })

  const categoryAttributesWithName = categoryAttributes.data?.results.find((attribute) => {
    return attribute.is_name
  })

  const onError = () => {
    methods.setError('root', {
      type: 'manual',
      message: translate['error.server'],
    })
  }

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.CATEGORY_LIST })
    closeModal()
    addNotification(NOTIFICATION.CATEGORY_CREATE_SUCCESS)
  }

  const onSubmit = async ({ name, object_group, parent }: CategoryFormInputs) => {
    const newCategory = await add.mutateAsync(
      {
        body: {
          object_group: object_group.map(({ value }) => {
            if (!value) {
              return null
            }

            return value
          }),
          parent,
        },
      },
      { onError },
    )

    if (categoryAttributesWithName?.id) {
      await addCategoryName.mutateAsync(
        {
          body: {
            object_id: newCategory.id,
            attribute: categoryAttributesWithName?.id,
            value: name,
          },
        },
        { onError },
      )
    }

    onSuccess()
  }

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    addCategory: methods.handleSubmit(onSubmit),
    isSubmitting: add.isPending,
  }
}
