import { type AddAttributesListArgs } from './types'

export const addAttributesList = ({ groups, attributes }: AddAttributesListArgs) => {
  const groupAttributesMap = attributes.reduce(
    (acc, attr) => {
      const groupId = attr.object_group

      if (groupId) {
        if (!acc[groupId]) acc[groupId] = []
        acc[groupId].push(attr)
      }

      return acc
    },
    {} as Record<string, typeof attributes>,
  )

  return groups.map((group) => {
    return {
      ...group,
      attributesInGroup: groupAttributesMap[group.id] || [],
    }
  })
}
