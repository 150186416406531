import { EuiButtonEmpty } from '@elastic/eui'
import { AddTaskModal } from '@features/automatic-tasks'
import { useDialog } from '@shared/hooks'
import { Translate } from '@shared/ui/translate'

const AddAutomaticTask: ReactFc = () => {
  const { isOpen, handleOpenDialog, handleCloseDialog } = useDialog()

  return (
    <>
      <EuiButtonEmpty
        iconType="plusInCircle"
        color="primary"
        iconSide="right"
        onClick={handleOpenDialog}
        size="s"
      >
        <Translate token="action.add_task" />
      </EuiButtonEmpty>
      <AddTaskModal isOpen={isOpen} onClose={handleCloseDialog} />
    </>
  )
}

export { AddAutomaticTask }
