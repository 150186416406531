import { EuiBadge, EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { useGroupsListApi } from '@entities/groups'
import { useUserApi } from '@entities/users'
import { API_GROUP_TYPE } from '@shared/api'
import { useDialog } from '@shared/hooks'
import { useTranslate } from '@shared/ui/translate'

import { PermissionsEditorModal } from './permissions-editor-modal'
import { type UserPermissionsEditorProps } from './types'

const UserPermissionsEditor: ReactFc<UserPermissionsEditorProps> = ({ userId }) => {
  const { data: user } = useUserApi(userId)
  const { data: rolesData } = useGroupsListApi({ type: API_GROUP_TYPE.ROLE })
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()
  const translate = useTranslate(['message.empty_value', 'action.edit'])

  const roles = rolesData?.results || []
  // @ts-ignore TODO: схема не соответствует действительности
  const userRoles = user?.roles || []

  // @ts-ignore TODO: схема не соответствует действительности
  const userRolesNames: string[] = userRoles.map(({ name }) => {
    return name
  })

  // @ts-ignore TODO: схема не соответствует действительности
  const userRolesIds = userRoles.map(({ id }) => {
    return id
  })

  const renderRoles = () => {
    if (!userRolesNames.length) {
      return translate['message.empty_value']
    }

    return userRolesNames.map((name) => {
      return (
        <EuiFlexItem>
          <EuiBadge key={name}>{name}</EuiBadge>
        </EuiFlexItem>
      )
    })
  }

  return (
    <>
      <EuiFlexGroup gutterSize="m">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center" gutterSize="s" wrap>
            {renderRoles()}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiButtonIcon
          iconType="pencil"
          onClick={showModal}
          aria-label={translate['action.edit']}
        />
      </EuiFlexGroup>
      <PermissionsEditorModal
        isOpen={isOpen}
        onClose={closeModal}
        userRoles={userRolesIds}
        roles={roles}
        userId={userId}
      />
    </>
  )
}

export { UserPermissionsEditor }
