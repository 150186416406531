import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'
import { Progress } from '@shared/ui/progress'
import { TextColor } from '@shared/ui/text-color'
import { useTranslate } from '@shared/ui/translate'

import { type ProgressWithStatusProps } from './types'

export const ProgressWithStatus: ReactFc<ProgressWithStatusProps> = ({
  progress = 0,
  status,
  variant,
}) => {
  const translate = useTranslate(['label.progress'])

  const label = (
    <EuiText size="xs">
      <TextColor color="subdued">{translate['label.progress']}</TextColor>
    </EuiText>
  )

  if (variant === 'full') {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <Progress value={progress} label={label} />

          <EuiSpacer size="s" />

          <EuiText size="xs">
            <TextColor color="subdued">{status}</TextColor>
          </EuiText>

          <EuiSpacer size="s" />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <Progress value={progress} />

        <EuiText size="xs">
          <TextColor color="lightShade">{status}</TextColor>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
