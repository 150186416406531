import { EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'
import { LogoutMessage } from '@features/auth'
import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { SimpleLayout } from '@shared/ui/layouts'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'

export const LogoutPage: ReactFc = () => {
  const translate = useTranslate([PAGE_TITLES.LOGOUT])
  useMeta({ title: translate[PAGE_TITLES.LOGOUT] })

  return (
    <Layout>
      <SimpleLayout>
        <EuiTitle size="xs">
          <EuiText>Выход из системы</EuiText>
        </EuiTitle>
        <EuiSpacer size="m" />

        <LogoutMessage />

        <EuiSpacer size="xl" />
      </SimpleLayout>
    </Layout>
  )
}
