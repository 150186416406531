import { type CategoryFilterTree } from '@entities/categories/types'
import { CategoryFilterCheckbox } from '@shared/ui'
import { useTranslate } from '@shared/ui/translate'

import { type AttributesCategoryFilterTreeProps } from './types'

export const AttributesCategoryFilterTree = ({
  items,
  rootAttributesCount,
  hasRoot = false,
}: AttributesCategoryFilterTreeProps): CategoryFilterTree[] => {
  const translate = useTranslate(['label.attributes_root_category'])

  const categoryFilterTree = items.map((item) => {
    const { id, name, childNodes, isExpanded, children, attributes_count } = item

    return {
      id: `category_${id}`,
      label: (
        <CategoryFilterCheckbox
          value={id}
          label={name}
          count={attributes_count}
          childNodes={childNodes}
        />
      ),
      isExpanded: isExpanded,
      children: children && AttributesCategoryFilterTree({ items: children }),
    }
  })

  if (hasRoot) {
    categoryFilterTree.unshift({
      id: 'category_root',
      label: (
        <CategoryFilterCheckbox
          value="true"
          label={translate['label.attributes_root_category']}
          count={rootAttributesCount || 0}
          isRoot
        />
      ),
      isExpanded: false,
      children: undefined,
    })
  }

  return categoryFilterTree
}
