import { EuiHeaderLink } from '@elastic/eui'
import { Translate } from '@shared/ui/translate'

import { useRemoveAttributeOptions } from '../model'

const RemoveAttributeOptions: ReactFc = () => {
  const { isDisabledButton, isSubmitting, removeOptionsHandler } = useRemoveAttributeOptions()

  return (
    <EuiHeaderLink
      iconType="trash"
      color="danger"
      iconSide="right"
      onClick={removeOptionsHandler}
      isLoading={isSubmitting}
      disabled={isDisabledButton}
    >
      <Translate token="action.delete_selected" />
    </EuiHeaderLink>
  )
}

export { RemoveAttributeOptions }
