import { EuiContextMenuItem, EuiIcon } from '@elastic/eui'
import { Translate } from '@shared/ui/translate'

import { useRemoveAttributeOptions } from '../model'
import { type MenuItemRemoveAttributeGroupProps } from './types'

const MenuItemRemoveAttributeOption: ReactFc<MenuItemRemoveAttributeGroupProps> = ({
  id,
  closeContextMenu,
}) => {
  const { removeOptionsHandler, isSubmitting } = useRemoveAttributeOptions([id])

  const onClick = () => {
    removeOptionsHandler()

    if (closeContextMenu) {
      closeContextMenu()
    }
  }

  return (
    <EuiContextMenuItem
      icon={<EuiIcon type="trash" color="danger" />}
      size="s"
      onClick={onClick}
      disabled={isSubmitting}
    >
      <Translate token="action.delete" />
    </EuiContextMenuItem>
  )
}

export { MenuItemRemoveAttributeOption }
