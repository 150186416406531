import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiAttributeOption } from '@shared/api/types'
import { getDateColumnRender } from '@shared/lib'
import { useTranslate } from '@shared/ui/translate'

const useAttributeOptionsColumns = (): EuiBasicTableColumn<ApiAttributeOption>[] => {
  const translate = useTranslate(['label.name', 'field.created_at', 'field.updated_at'])

  return [
    {
      field: 'value',
      name: translate['label.name'],
    },
    {
      field: 'created_at',
      name: translate['field.created_at'],
      render: getDateColumnRender<ApiAttributeOption, 'created_at'>(),
    },
    {
      field: 'updated_at',
      name: translate['field.updated_at'],
      render: getDateColumnRender<ApiAttributeOption, 'updated_at'>(),
    },
  ]
}

export { useAttributeOptionsColumns }
