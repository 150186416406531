import { EuiForm } from '@elastic/eui'
import { FormError, NumberInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'

import { useUpdateAttributeGroup } from '../model'
import { type UpdateAttributeGroupModalProps } from './types'

const UpdateAttributeGroupModal: ReactFc<UpdateAttributeGroupModalProps> = ({
  attributeGroup,
  isOpen,
  closeModal,
}) => {
  const { methods, updateGroup, isSubmitting } = useUpdateAttributeGroup(attributeGroup, closeModal)

  const translate = useTranslate([
    'action.edit_attribute_group',
    'action.save',
    'label.attribute_group_name',
    'label.order',
    'message.enter_name',
  ])

  return (
    <FormProvider {...methods}>
      <Modal
        title={translate['action.edit_attribute_group']}
        submitButtonText={translate['action.save']}
        onClose={closeModal}
        onSubmit={updateGroup}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <TextInput
            name="name"
            placeholder={translate['label.attribute_group_name']}
            registerOptions={{ required: translate['message.enter_name'] }}
            inputProps={{
              disabled: isSubmitting,
            }}
            isRequired
          />
          <NumberInput
            name="order"
            placeholder={translate['label.order']}
            inputProps={{
              disabled: isSubmitting,
            }}
          />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { UpdateAttributeGroupModal }
