import { type AttributeGroup } from '@entities/attributes/types'
import { MenuItemRemoveAttributeGroup, UpdateAttributeGroupModal } from '@features/attributes'
import { useDialog } from '@shared/hooks'
import { MenuItemDialog, TableContextMenu } from '@shared/ui/context-menu'
import { Translate } from '@shared/ui/translate'

const GroupsContextMenu = (props: AttributeGroup) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()

  return (
    <>
      <TableContextMenu>
        <MenuItemDialog onClick={showModal}>
          <Translate token="action.edit" />
        </MenuItemDialog>
        <MenuItemRemoveAttributeGroup id={props.id} />
      </TableContextMenu>

      <UpdateAttributeGroupModal attributeGroup={props} isOpen={isOpen} closeModal={closeModal} />
    </>
  )
}

export { GroupsContextMenu }
