import { EuiForm, EuiHeaderLink } from '@elastic/eui'
import { ROUTES } from '@shared/config'
import { FormError, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

import { useAddAttributeOption } from '../model'

const AddAttributeOption: ReactFc = () => {
  const { id: attributeId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE)

  const { isOpen, showModal, closeModal, methods, addOption, isSubmitting } =
    useAddAttributeOption(attributeId)

  const translate = useTranslate(['action.add_option', 'label.name', 'message.enter_name'])

  return (
    <>
      <EuiHeaderLink iconType="plusInCircle" color="primary" iconSide="right" onClick={showModal}>
        {translate['action.add_option']}
      </EuiHeaderLink>

      <FormProvider {...methods}>
        <Modal
          title={translate['action.add_option']}
          onClose={closeModal}
          onSubmit={addOption}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <EuiForm component="form">
            <TextInput
              name="name"
              placeholder={translate['label.name']}
              registerOptions={{ required: translate['message.enter_name'] }}
              inputProps={{
                disabled: isSubmitting,
              }}
              isRequired
            />

            <FormError />
          </EuiForm>
        </Modal>
      </FormProvider>
    </>
  )
}

export { AddAttributeOption }
