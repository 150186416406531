import { EuiLoadingSpinner, EuiText } from '@elastic/eui'
import { $api } from '@shared/api'
import { ROUTES } from '@shared/config'
import { Translate } from '@shared/ui/translate'
import { Navigate } from 'react-router'

export const LogoutMessage: ReactFc = () => {
  const { isSuccess, isError } = $api.useQuery('post', '/logout/')

  if (isSuccess) {
    return <Navigate to={ROUTES.LOGIN.$path()} />
  }

  if (isError) {
    return (
      <EuiText size="xs">
        <Translate token="error.server" />
      </EuiText>
    )
  }

  return <EuiLoadingSpinner size="s" />
}
