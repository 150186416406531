import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { Translate } from '@shared/ui/translate'

import { type CreateCatalogSelectOptionsArgs } from './types'

export const createCatalogSelectOptions = ({
  catalogs,
  isLoading,
  hasCatalogs,
}: CreateCatalogSelectOptionsArgs) => {
  if (!isLoading && !hasCatalogs) {
    return [{ value: 'none', text: Translate({ token: 'message.empty_catalogs' }) }]
  }

  return catalogs.map((catalog) => {
    const name =
      `${extractAttribute(catalog, ATTRIBUTE_FLAGS.NAME)?.value}` ||
      Translate({ token: 'message.empty_name' })

    return {
      value: catalog.id,
      text: name,
    }
  })
}
