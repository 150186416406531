import { EuiLink, EuiText } from '@elastic/eui'
import { Translate } from '@shared/ui/translate'

import { FILE_STATUS, type FilesCardNameProps } from './types'

const FilesCardName: ReactFc<FilesCardNameProps> = ({ file_url, status, file_name }) => {
  const isDisabled = status !== FILE_STATUS.COMPLETED_SUCCESS
  const linkProps = isDisabled ? { disabled: true } : { href: file_url, target: '_blank' }

  if (!file_name) {
    return (
      <EuiText size="m" color="danger">
        <Translate token="error.export_error" />
      </EuiText>
    )
  }

  return <EuiLink {...linkProps}>{file_name}</EuiLink>
}

export { FilesCardName }
