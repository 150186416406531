import { EuiButtonIcon } from '@elastic/eui'
import { useTranslate } from '@shared/ui/translate'

import { useCatalogFilters } from '../model'

const RemoveFilter: ReactFc<{ id: number | string }> = ({ id }) => {
  const { removeFilter } = useCatalogFilters()
  const translate = useTranslate(['action.delete'])

  return (
    <EuiButtonIcon
      iconType="errorFilled"
      size="s"
      color="danger"
      onClick={removeFilter(id.toString())}
      aria-label={translate['action.delete']}
    />
  )
}

export { RemoveFilter }
