import { type AttributeGroup } from '@entities/attributes/types'
import { API_OBJECT_GROUP } from '@shared/api'
import { OTHER_ATTRIBUTE_GROUP_ID } from '@shared/config'

import { type AddOtherGroupArgs } from './types'

/**
 * Добавляет группу "Прочее" к списку групп атрибутов.
 *
 * @param groups - Список существующих групп атрибутов.
 * @param attributes - Список атрибутов, которые могут быть добавлены в группу "Прочее".
 * @returns Обновленный список групп атрибутов с добавленной группой "Прочее", если есть подходящие атрибуты.
 */
const addOtherGroup: AddOtherGroupArgs = (groups, attributes, name) => {
  // Собираем атрибуты для группы "Прочее"
  const otherAttributes =
    attributes.filter((attribute) => {
      return attribute.object_group === null
    }) || []

  if (otherAttributes.length > 0) {
    // Создаем группу "Прочее"
    const otherGroup: AttributeGroup = {
      id: OTHER_ATTRIBUTE_GROUP_ID, // Уникальный идентификатор для системной группы
      name,
      attributes: [],
      attributesInGroup: otherAttributes,
      attributes_count: otherAttributes.length,
      created_by: null,
      is_editable: false,
      uuid: '-1',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_enabled: true,
      is_deleted: false,
      type: API_OBJECT_GROUP.ATTRIBUTE,
      order: groups.length + 1, // Порядок отображения
      group: null,
      href: '',
    }

    // Добавляем группу "Прочее" к существующим группам
    return [...groups, otherGroup]
  }

  // Если нет атрибутов для группы "Прочее", возвращаем исходные группы
  return groups
}

export { addOtherGroup }
