import { API_ATTRIBUTE_TYPE } from '@shared/api'

import { type AttributeTypeHelp } from './types'

export const ATTRIBUTE_TYPE_HELP: AttributeTypeHelp = {
  [API_ATTRIBUTE_TYPE.TEXT]: {
    DESCRIPTION: 'attribute_type_help.text.description',
    TOOLTIP: 'attribute_type_help.text.tooltip',
  },
  [API_ATTRIBUTE_TYPE.LIST]: {
    DESCRIPTION: 'attribute_type_help.list.description',
    TOOLTIP: 'attribute_type_help.list.tooltip',
  },
  [API_ATTRIBUTE_TYPE.BOOL]: {
    DESCRIPTION: 'attribute_type_help.bool.description',
    TOOLTIP: 'attribute_type_help.bool.tooltip',
  },
  [API_ATTRIBUTE_TYPE.NUMBER]: {
    DESCRIPTION: 'attribute_type_help.number.description',
    TOOLTIP: 'attribute_type_help.number.tooltip',
  },
  [API_ATTRIBUTE_TYPE.FILE]: {
    DESCRIPTION: 'attribute_type_help.file.description',
    TOOLTIP: 'attribute_type_help.file.tooltip',
  },
  [API_ATTRIBUTE_TYPE.JSON]: {
    DESCRIPTION: 'attribute_type_help.json.description',
    TOOLTIP: 'attribute_type_help.json.tooltip',
  },
  [API_ATTRIBUTE_TYPE.DATE]: {
    DESCRIPTION: 'attribute_type_help.date.description',
    TOOLTIP: 'attribute_type_help.date.tooltip',
  },
  [API_ATTRIBUTE_TYPE.FLOAT]: {
    DESCRIPTION: 'attribute_type_help.float.description',
    TOOLTIP: 'attribute_type_help.float.tooltip',
  },
  [API_ATTRIBUTE_TYPE.LONG_TEXT]: {
    DESCRIPTION: 'attribute_type_help.long_text.description',
    TOOLTIP: 'attribute_type_help.long_text.tooltip',
  },
  // TODO: Добавить, когда появится на сервере
  [API_ATTRIBUTE_TYPE.NESTED_LIST]: {
    DESCRIPTION: 'attribute_type_help.nested_list.description',
    TOOLTIP: 'attribute_type_help.nested_list.tooltip',
  },
}
