import { ROUTES } from '@shared/config'
import { NOTIFICATION, notificationsStore } from '@shared/ui/notifications'
import { Translate } from '@shared/ui/translate'

const errorHandler = async (response: Response) => {
  // Ошибка авторизации
  if (response.status === 401) {
    // Перенаправляем на страницу логина
    if (window.location.pathname !== ROUTES.LOGIN.$path()) {
      window.location.href = ROUTES.LOGIN.$path()
    }

    return undefined
  }

  // Ошибка доступа
  if (response.status === 403) {
    notificationsStore.getState().addNotification(NOTIFICATION.PERMISSIONS_ERROR)

    return undefined
  }

  // Не найдено
  if (response.status === 404) {
    notificationsStore.getState().addNotification(NOTIFICATION.NOT_FOUND)

    return undefined
  }

  // Ошибка сервера
  if (response.status >= 500) {
    notificationsStore.getState().addNotification(NOTIFICATION.SERVER_ERROR)

    return undefined
  }

  // Прочие ошибки
  if (response.status >= 400) {
    notificationsStore.getState().addNotification({
      id: 'error-message',
      title: Translate({ token: 'message.error' }),
      color: 'danger',
    })
  }

  return undefined
}

export { errorHandler }
