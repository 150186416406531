import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { CATALOG_TABLE_IMAGE_SIZE } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'

import { type SkuPrintDocumentProps } from './types'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  title: {
    paddingTop: 10,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  subtitle: {
    paddingTop: 5,
    fontWeight: 'bold',
  },
  text: {
    fontSize: '12px',
  },
  image: {
    width: CATALOG_TABLE_IMAGE_SIZE,
  },
})

export const SkuPrintDocument: ReactFc<SkuPrintDocumentProps> = ({ attributesGroupsList }) => {
  const translate = useTranslate(['message.empty_value'])

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        {attributesGroupsList.map((attributesGroup) => {
          return (
            <View key={attributesGroup.id}>
              <Text style={styles.title}>{attributesGroup.name}</Text>

              {attributesGroup.attributes.map((attribute) => {
                if (attribute.files) {
                  return (
                    <View key={attribute.name} style={styles.text}>
                      <Text style={styles.subtitle}>{attribute.name}: </Text>
                      {attribute.files.map((file) => {
                        if (file.preview) {
                          return (
                            <Link key={file.id} src={file.file}>
                              <Image key={file.id} src={file.preview} style={styles.image} />
                            </Link>
                          )
                        }

                        return (
                          <Link key={file.id} src={file.file}>
                            <Text style={styles.subtitle}>{file.name}</Text>
                          </Link>
                        )
                      })}
                    </View>
                  )
                }

                return (
                  <View key={attribute.name} style={styles.text}>
                    <Text style={styles.subtitle}>{attribute.name}:</Text>
                    <Text>
                      {attribute.value ? `${attribute.value}` : translate['message.empty_value']}
                    </Text>
                  </View>
                )
              })}
            </View>
          )
        })}
      </Page>
    </Document>
  )
}
