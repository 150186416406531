import { useUserApi } from '@entities/users'
import { useUpdateUser } from '@features/user'
import { PAGE_TITLES, ROUTES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { useTranslate } from '@shared/ui/translate'
import { UserHeader, UserInfo } from '@widgets/settings'
import { useTypedParams } from 'react-router-typesafe-routes'

const UserPage: ReactFc = () => {
  const { id: userId } = useTypedParams(ROUTES.SETTINGS.USERS.USER)
  const { data: user } = useUserApi(userId)

  const { isSaveButtonActive, handleUpdateUserFieldValues, isUserFieldValuesSubmitting, methods } =
    useUpdateUser()

  const translate = useTranslate([PAGE_TITLES.SETTINGS_USER])
  useMeta({ title: `${translate[PAGE_TITLES.SETTINGS_USER]} ${user?.username}` })

  return (
    <Layout
      header={
        <UserHeader
          onUpdateUserFieldValues={handleUpdateUserFieldValues}
          isUserFieldValuesSubmitting={isUserFieldValuesSubmitting}
          isSaveButtonActive={isSaveButtonActive}
        />
      }
    >
      <UserInfo methods={methods} isUserSubmitting={isUserFieldValuesSubmitting} />
    </Layout>
  )
}

export { UserPage }
