import { ELEMENTS_L_WIDTH } from '@shared/config'
import { Modal } from '@shared/ui/modal'
import { Table } from '@shared/ui/table'
import { useTranslate } from '@shared/ui/translate'

import { useRestoreSku } from '../model'
import { type SkuHistoryModalProps } from './types'

const SkuHistoryModal: ReactFc<SkuHistoryModalProps> = ({ version, isOpen, closeModal }) => {
  const { restoreVersion, isSubmitting } = useRestoreSku(version.version, closeModal)

  const translate = useTranslate([
    'label.attribute',
    'label.previous',
    'label.now',
    'action.version_comparison',
    'action.restore',
  ])

  const items = [{ ...version }]

  const columns = [
    {
      field: 'attribute',
      name: translate['label.attribute'],
    },
    {
      field: 'old_changed_value',
      name: translate['label.previous'],
    },
    {
      field: 'new_changed_value',
      name: translate['label.now'],
    },
  ]

  return (
    <Modal
      title={translate['action.version_comparison']}
      submitButtonText={translate['action.restore']}
      onClose={closeModal}
      onSubmit={restoreVersion}
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      maxWidth={ELEMENTS_L_WIDTH}
    >
      <Table columns={columns} items={items} />
    </Modal>
  )
}

export { SkuHistoryModal }
