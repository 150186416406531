import { $api, API_QUERY_KEY, ATTRIBUTE_FLAGS, extractAttribute, queryClient } from '@shared/api'
import { type ApiObjectGroup, type ApiObjectGroupRetrieve } from '@shared/api/types'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { useTranslate } from '@shared/ui/translate'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { type UpdateAttributeGroupFormInputs } from './types'

export const useUpdateAttributeGroup = (
  attributeGroup: ApiObjectGroup | ApiObjectGroupRetrieve,
  closeModal: EmptyCallback,
) => {
  const methods = useForm<UpdateAttributeGroupFormInputs>()
  const addNotification = useNotificationsStore.useAddNotification()
  const translate = useTranslate(['error.server'])

  const nameAttribute = extractAttribute(attributeGroup, ATTRIBUTE_FLAGS.NAME)
  const name = `${nameAttribute?.value}`
  const nameValueId = nameAttribute?.value_id

  useEffect(() => {
    methods.reset({
      name,
      order: attributeGroup.order || undefined,
    })
  }, [attributeGroup])

  const update = $api.useMutation('patch', '/object_group_value/{id}/', {
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: translate['error.server'],
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_GROUP_LIST })
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_GROUP(attributeGroup.id) })
      closeModal()
      methods.reset()
      addNotification(NOTIFICATION.ATTRIBUTE_GROUP_UPDATE_SUCCESS)
    },
  })

  const updateGroup = methods.handleSubmit(({ name, order }) => {
    update.mutate({
      params: {
        path: {
          id: attributeGroup.id,
        },
      },
      body: {
        order,
        value_id: nameValueId?.toString(),
        value: name,
      },
    })
  })

  const isSubmitting = update.isPending

  return {
    methods,
    updateGroup,
    isSubmitting,
  }
}
