import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { type ApiObjectWithAttributes } from '@shared/api/types'
import { PAGE_TITLES } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'
import { type UseQueryResult } from '@tanstack/react-query'

export const useTitle = (item: UseQueryResult<ApiObjectWithAttributes>) => {
  const { isSuccess, data } = item
  const translate = useTranslate(['message.empty_name'])

  if (isSuccess) {
    const name = extractAttribute(data, ATTRIBUTE_FLAGS.NAME)?.value

    if (name) {
      return `${name}`
    }

    return translate['message.empty_name']
  }

  return PAGE_TITLES.LOADING
}
