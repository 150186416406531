import { EuiFilterGroup, EuiSelect } from '@elastic/eui'
import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { PARAMS } from '@shared/config'
import { useTranslate } from '@shared/ui/translate'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  useAttributeBaseOptions,
  useAttributeDateOptions,
  useAttributeTextOptions,
} from '../../model'
import { type FilterFields } from '../../types'
import { RemoveFilter } from '../remove-filter'
import { type FilterAttributeProps } from '../types'
import { FilterContain } from './filter-contain'
import { FilterDateRange } from './filter-date-range'
import { FilterEqual } from './filter-equal'

const FilterAttribute: ReactFc<FilterAttributeProps> = ({ attribute }) => {
  const { slug } = attribute
  const { getValues, setValue, watch, unregister } = useFormContext<FilterFields>()
  const translate = useTranslate(['message.loading'])
  const attributeBaseOptions = useAttributeBaseOptions()
  const attributeDateOptions = useAttributeDateOptions()
  const attributeTextOptions = useAttributeTextOptions()

  const attributeType = attribute?.attribute_type

  const isDate = attributeType === API_ATTRIBUTE_TYPE.DATE

  const options = [
    ...attributeBaseOptions,
    ...(isDate ? attributeDateOptions : []),
    ...(attributeType === API_ATTRIBUTE_TYPE.TEXT ? attributeTextOptions : []),
  ]

  // Когда фильтр появляется на экране задаём дефолтные значения: атрибут заполнен у sku
  const isValueEmpty = !getValues(`filters.${slug}`)

  useEffect(() => {
    if (isValueEmpty) {
      setValue(`filters.${slug}.filterParams`, [
        {
          param: slug + PARAMS.ATTRIBUTE_VALUE_ISNULL,
          value: 'false',
        },
      ])
    }

    return () => {
      if (isValueEmpty) {
        unregister(`filters.${slug}`)
      }
    }
  }, [])

  const currentFilter = watch(`filters.${slug}`)?.filterParams[0]

  const isAttributeFilterEqual =
    currentFilter?.param?.includes(PARAMS.ATTRIBUTE_VALUE_EQUAL) ||
    currentFilter?.param?.includes(PARAMS.ATTRIBUTE_VALUE_NOT_EQUAL)

  const isAttributeFilterContain =
    currentFilter?.param?.includes(PARAMS.ATTRIBUTE_VALUE_CONTAIN) ||
    currentFilter?.param?.includes(PARAMS.ATTRIBUTE_VALUE_NOT_CONTAIN)

  const isAttributeFilterDateRange =
    currentFilter?.param?.includes(PARAMS.ATTRIBUTE_AFTER) ||
    currentFilter?.param?.includes(PARAMS.ATTRIBUTE_BEFORE)

  if (!slug) {
    return null
  }

  const valueList = options.filter((option) => {
    if (currentFilter) {
      return currentFilter.param?.includes(option.data.filterPostfix)
    }

    return null
  })

  const selectValue =
    valueList.length > 1
      ? valueList.find((option) => {
          return currentFilter.value === option.data.filterValues
        })?.value
      : valueList[0]?.value

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const option = options.find((option) => {
      return option.value === event.target.value
    })?.data

    setValue(`filters.${slug}.filterParams`, [
      {
        param: slug + option?.filterPostfix,
        value: option?.filterValues,
      },
    ])
  }

  return (
    <EuiFilterGroup compressed>
      <EuiSelect
        prepend={attribute?.name || translate['message.loading']}
        options={options}
        name={`filters.${slug}.filterPostfix`}
        value={selectValue}
        onChange={handleSelectChange}
        compressed
      />

      {isAttributeFilterEqual && <FilterEqual attribute={attribute} />}

      {isAttributeFilterContain && <FilterContain attribute={attribute} />}

      {isAttributeFilterDateRange && <FilterDateRange attribute={attribute} />}

      <RemoveFilter id={slug} />
    </EuiFilterGroup>
  )
}

export { FilterAttribute }
