import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import {
  CatalogActions,
  CatalogFiltersSelect,
  CatalogSidebarToggle,
  CatalogViewToggle,
} from '@features/catalog'
import { Search } from '@shared/ui'
import { noShadow, useStyles } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'

import { CatalogHeaderUtility } from './catalog-header-utility'
import { getStyles } from './styles'

export const CatalogHeader: ReactFc = () => {
  const styles = useStyles(getStyles)
  const translate = useTranslate(['action.find_sku'])

  return (
    <>
      <EuiHeader css={[styles.header, noShadow]}>
        <EuiHeaderSection css={styles.headerSection}>
          <EuiHeaderSectionItem>
            <CatalogSidebarToggle />
          </EuiHeaderSectionItem>

          <EuiHeaderSectionItem>
            <Search searchParam="search" placeholder={translate['action.find_sku']} />
          </EuiHeaderSectionItem>

          <EuiHeaderSectionItem>
            <CatalogFiltersSelect />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>

        <EuiHeaderSection css={styles.headerSection}>
          <EuiHeaderSectionItem>
            <CatalogViewToggle />
          </EuiHeaderSectionItem>

          <EuiHeaderSectionItem>
            <CatalogActions />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>

      <CatalogHeaderUtility />
    </>
  )
}
