import { useAutomaticTaskApi, useUpdateAutomaticTaskApi } from '@entities/automatic_tasks'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAutomaticTask } from '@shared/api/types'
import { getDirtyFields } from '@shared/lib'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import _ from 'lodash'
import { useForm } from 'react-hook-form'

import { type UseUpdateAutomaticTaskArgs } from './types'

const useUpdateAutomaticTask = ({ taskId, onSuccessCallback }: UseUpdateAutomaticTaskArgs) => {
  const addNotification = useNotificationsStore.useAddNotification()
  const { data: task, isLoading: isTaskLoading } = useAutomaticTaskApi(taskId)

  const methods = useForm<ApiAutomaticTask>({ values: task })

  const { mutate: updateAutomaticTask, isPending } = useUpdateAutomaticTaskApi()

  const isLoading = isTaskLoading || isPending

  const {
    formState: { isDirty },
  } = methods

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.AUTOMATIC_TASK(taskId) })
    addNotification(NOTIFICATION.TASK_UPDATE_SUCCESS)

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const handleUpdateAutomaticTask = methods.handleSubmit(async (taskData: ApiAutomaticTask) => {
    return updateAutomaticTask(
      {
        params: {
          path: {
            id: taskId,
          },
        },
        body: getDirtyFields(task, taskData),
      },
      { onSuccess },
    )
  })

  return {
    isSaveButtonActive: isDirty,
    updateAutomaticTask: handleUpdateAutomaticTask,
    isLoading,
    methods,
  }
}

export { useUpdateAutomaticTask }
