import { CATALOG_VIEW } from '@shared/config'
import { createSelectorHooks, type ZustandHookSelectors } from 'auto-zustand-selectors-hook'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { type UserSettingsProps, type UserSettingsStore } from './types'

const initialValues: UserSettingsProps = {
  language: '',
  isSidebarOpen: true,
  isCatalogSidebarOpen: true,
  catalog: null,
  catalogView: CATALOG_VIEW.CARDS,
  isSkuSidebarOpen: true,
  attributeOrder: [],
}

const userSettingsStore = create<UserSettingsStore>()(
  persist(
    (set, get) => {
      return {
        ...initialValues,

        changeLanguage: (language) => {
          return set({ language })
        },

        toggleSidebarOpen: () => {
          return set({ isSidebarOpen: !get().isSidebarOpen })
        },

        toggleCatalogSidebarOpen: () => {
          return set({ isCatalogSidebarOpen: !get().isCatalogSidebarOpen })
        },

        setCatalog: (catalogId) => {
          return set({ catalog: catalogId })
        },

        toggleCatalogView: (view) => {
          return set({ catalogView: view })
        },

        toggleSkuSidebarOpen: () => {
          return set({ isSkuSidebarOpen: !get().isSkuSidebarOpen })
        },

        setAttributeOrder: (order) => {
          return set({ attributeOrder: order })
        },

        reset: () => {
          return set(initialValues)
        },
      }
    },
    {
      name: 'user-settings',
      storage: createJSONStorage(() => {
        return localStorage
      }),
    },
  ),
)

const useUserSettingsStore = createSelectorHooks(userSettingsStore) as typeof userSettingsStore &
  ZustandHookSelectors<UserSettingsStore>

export { userSettingsStore, useUserSettingsStore }
