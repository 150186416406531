import { ROUTES } from '@shared/config'
import { useTitle } from '@shared/hooks'
import { useTypedParams } from 'react-router-typesafe-routes'

import { useSkuApi } from '../api'

export const useSkuTitle = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const sku = useSkuApi(skuId)

  return useTitle(sku)
}
