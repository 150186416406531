import { useTranslate } from '@shared/ui/translate'

import { SkuAttributesTab } from './sku-attribute-tab'
import { SkuCategoriesTab } from './sku-categories-tab'
import { SkuFilesTab } from './sku-files-tab'
import { SkuHistoryTab } from './sku-history-tab'

const useSkuTabsList = () => {
  const translate = useTranslate([
    'label.attributes',
    'label.images_files',
    'label.categories',
    'label.version_history',
  ])

  return [
    {
      id: 'attributes',
      name: translate['label.attributes'],
      content: <SkuAttributesTab />,
    },
    {
      id: 'imagesAndFiles',
      name: translate['label.images_files'],
      content: <SkuFilesTab />,
    },
    {
      id: 'categories',
      name: translate['label.categories'],
      content: <SkuCategoriesTab />,
    },
    {
      id: 'history',
      name: translate['label.version_history'],
      content: <SkuHistoryTab />,
    },
  ]
}

export { useSkuTabsList }
