import { EuiForm } from '@elastic/eui'
import { useSkuListApi } from '@entities/catalog'
import { useAttributeListApi } from '@shared/api'
import { ELEMENTS_L_WIDTH } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { TransferList, type TransferListItem } from '@shared/ui'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'

import { type AddSkuProps, type ExportProductsFieldValues } from './types'

const ExportSkus: ReactFc<AddSkuProps> = ({ isOpen, onClose }) => {
  const { params } = useRouterParams()
  const translate = useTranslate(['action.export', 'action.start_export', 'message.export_time'])

  const [isExport, setIsExport] = useState(false)
  const exportColumns = useRef<number[]>([])

  const { isSuccess } = useSkuListApi({
    ...params,
    _export: isExport ? isExport : undefined,
    _attributes: exportColumns.current || undefined,
  })

  const { isLoading: isAttributesLoading, data: { results: attributes = [] } = {} } =
    useAttributeListApi()

  const methods = useForm<ExportProductsFieldValues>()

  const {
    formState: { errors },
  } = methods

  const selectableListItems = attributes.map(({ id, name }) => {
    return {
      name: id.toString(),
      label: name,
      isChecked: false,
    }
  })

  const transferListName = 'сolumnsToExport'

  useEffect(() => {
    if (isExport && isSuccess) {
      setIsExport(false)
      exportColumns.current = []

      onClose()
    }
  }, [isSuccess])

  const getSelectedColumnsParam = (selectedItems: TransferListItem[]) => {
    return selectedItems.map(({ name }) => {
      return parseInt(name)
    })
  }

  const onSubmit: SubmitHandler<ExportProductsFieldValues> = ({
    сolumnsToExport: { selected },
  }) => {
    if (selectableListItems.length !== selected.length) {
      exportColumns.current = getSelectedColumnsParam(selected)
    }

    setIsExport(true)
  }

  return (
    <FormProvider {...methods}>
      <Modal
        title={translate['action.export']}
        onClose={onClose}
        onSubmit={methods.handleSubmit(onSubmit)}
        isOpen={isOpen}
        isSubmitting={false}
        isSubmitButtonDisabled={!!errors[transferListName]?.message}
        submitButtonText={translate['action.start_export']}
        maxWidth={ELEMENTS_L_WIDTH}
      >
        <EuiForm component="form" fullWidth>
          <TransferList
            name={transferListName}
            itemsList={selectableListItems}
            selectedItems={selectableListItems}
            isLoading={isAttributesLoading}
            message={translate['message.export_time']}
            isRequired
          />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { ExportSkus }
