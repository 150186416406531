import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSkeletonRectangle,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'
import { useSkuApi } from '@entities/catalog'
import { DATE_FORMAT_FULL, ELEMENTS_M_WIDTH, ROUTES } from '@shared/config'
import { useStyles } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'
import moment from 'moment'
import { useTypedParams } from 'react-router-typesafe-routes'

import { getStyles } from './styles'

const SkuDates = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { data, isLoading } = useSkuApi(skuId)
  const styles = useStyles(getStyles)
  const translate = useTranslate(['label.create_date', 'label.update_date', 'message.empty_value'])

  const createdAt = !isLoading
    ? moment(data?.created_at).format(DATE_FORMAT_FULL)
    : translate['message.empty_value']

  const updatedAt = !isLoading
    ? moment(data?.updated_at).format(DATE_FORMAT_FULL)
    : translate['message.empty_value']

  return (
    <EuiSkeletonRectangle width={ELEMENTS_M_WIDTH} isLoading={isLoading} css={styles.dates}>
      <EuiPanel paddingSize="xs" color="transparent" hasShadow={false}>
        <EuiFlexGroup alignItems="center" gutterSize="m">
          <EuiFlexItem grow={false}>
            <EuiText size="s">
              {translate['label.create_date']}: {createdAt}
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText size="s">
              {translate['label.update_date']}: {updatedAt}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="xs" />
      </EuiPanel>
    </EuiSkeletonRectangle>
  )
}

export { SkuDates }
