import { EuiForm } from '@elastic/eui'
import { useCategoryOptions } from '@entities/categories'
import { FormError, SelectInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { useTranslate } from '@shared/ui/translate'
import { Controller, FormProvider } from 'react-hook-form'

import { useLinkAttributeToCategory } from '../model'
import {
  type LinkAttributeToCategoryOnSubmitArg,
  type LinkAttributeToCategoryProps,
} from '../types'

const LinkAttributeToCategory: ReactFc<LinkAttributeToCategoryProps> = ({ isOpen, onClose }) => {
  const { categoryOptions, isLoading: isCategoryOptionsLoading } = useCategoryOptions()
  const { methods, updateAttributes, isSubmitting } = useLinkAttributeToCategory()
  const addNotification = useNotificationsStore.useAddNotification()

  const translate = useTranslate([
    'action.new_attribute',
    'action.attributes_link',
    'action.attributes_category_select',
    'message.category_required',
  ])

  const onSubmit = async (data: LinkAttributeToCategoryOnSubmitArg) => {
    await updateAttributes(data)
    methods.reset()
    onClose()
    addNotification(NOTIFICATION.ATTRIBUTE_UPDATE_SUCCESS)
  }

  return (
    <FormProvider {...methods}>
      <Modal
        title={translate['action.new_attribute']}
        submitButtonText={translate['action.attributes_link']}
        onClose={onClose}
        onSubmit={methods.handleSubmit(onSubmit)}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <Controller
            name="category"
            control={methods.control}
            rules={{ required: translate['message.category_required'] }}
            render={() => {
              return (
                <SelectInput
                  name="category"
                  placeholder={translate['action.attributes_category_select']}
                  label={translate['action.attributes_category_select']}
                  inputProps={{
                    options: categoryOptions,
                    isLoading: isCategoryOptionsLoading,
                  }}
                />
              )
            }}
          />
          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { LinkAttributeToCategory }
