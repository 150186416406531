import {
  EuiContextMenuItem,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderLink,
  EuiIcon,
  EuiPopover,
  EuiPopoverFooter,
} from '@elastic/eui'
import { ROUTES } from '@shared/config'
import { useToggle } from '@shared/hooks'
import { Translate } from '@shared/ui/translate'

export const UserHeaderMenu: ReactFc = () => {
  const [isMenuOpen, toggleMenu] = useToggle()

  const userHeaderButton = (
    <EuiHeaderLink iconType="arrowDown" iconSide="right" color="text" onClick={toggleMenu}>
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem>
          <EuiIcon type="user" />
        </EuiFlexItem>
        <EuiFlexItem>
          <Translate token="label.profile" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiHeaderLink>
  )

  {
    /*
    TODO: Раскомментировать, когда будут добавляться пункты
    const styles = useStyles(getStyles)
    const userLinksList = [
      <EuiContextMenuItem key="user" icon="user">
        Профиль
      </EuiContextMenuItem>,
      <EuiContextMenuItem key="bell" icon="bell">
        Уведомления <EuiTextColor css={styles.notificationsCount}>(+12)</EuiTextColor>
      </EuiContextMenuItem>,
      <EuiContextMenuItem key="eye" icon="eye">
        Измененные товары
      </EuiContextMenuItem>,
    ]
  */
  }

  return (
    <EuiPopover
      id="userContextMenuPopover"
      button={userHeaderButton}
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
      panelPaddingSize="none"
      anchorPosition="downRight"
    >
      {/*
        TODO: Раскомментировать, когда будут добавляться пункты
        <EuiContextMenuPanel items={userLinksList} css={styles.menu} />
      */}
      <EuiPopoverFooter>
        <EuiContextMenuItem key="eye" icon="exit" href={ROUTES.LOGOUT.$path()}>
          <Translate token="label.exit" />
        </EuiContextMenuItem>
      </EuiPopoverFooter>
    </EuiPopover>
  )
}
