import { EuiFilterGroup } from '@elastic/eui'
import { PARAMS } from '@shared/config'
import { FilterPopover } from '@shared/ui'
import { useTranslate } from '@shared/ui/translate'

import { useCatalogFiltersList } from '../model'

const CatalogFiltersSelect: ReactFc = () => {
  const options = useCatalogFiltersList()
  const translate = useTranslate(['label.filters', 'action.find_filter'])

  if (options?.length === 0) {
    return null
  }

  return (
    <EuiFilterGroup compressed>
      <FilterPopover
        label={translate['label.filters']}
        param={PARAMS.FILTERS}
        options={options}
        searchable
        searchProps={{
          placeholder: translate['action.find_filter'],
        }}
      />
    </EuiFilterGroup>
  )
}

export { CatalogFiltersSelect }
