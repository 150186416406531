import { $api } from '@shared/api'

import { type UserFieldsQuery } from '../types'

const useUserFieldsListApi = (args: UserFieldsQuery = {}) => {
  return $api.useQuery('get', '/user_fields/', {
    params: {
      query: {
        size: 100,
        ...args,
      },
    },
  })
}

export { useUserFieldsListApi }
