import '@app/app.css'
import 'moment/dist/locale/ru'

import { EuiThemeProvider } from '@elastic/eui'
import { queryClient } from '@shared/api'
import { baseTheme } from '@shared/config'
import { Notifications } from '@shared/ui/notifications'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router'

import { router } from './router'
import { TranslateProvider } from './translate-provider'

console.info('Version:', APP_VERSION)

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <EuiThemeProvider modify={baseTheme}>
        <TranslateProvider>
          <RouterProvider router={router} />
          <Notifications />
        </TranslateProvider>
      </EuiThemeProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export { App }
