import { $api } from '@shared/api'

import { type SkuHistoryListQuery } from './types'

export const useSkuHistoryApi = {
  list: (queryArgs: SkuHistoryListQuery) => {
    return $api.useQuery('get', '/sku_history/', {
      params: {
        query: {
          ...queryArgs,
        },
      },
    })
  },
}
