import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { useUserPermissions } from '@entities/user'
import { AddAttribute, LinkAttributeToCategory } from '@features/attributes'
import { PERMISSIONS } from '@shared/api'
import { useDialog, useRouterParams } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'
import { useTranslate } from '@shared/ui/translate'

const AttributeActions: ReactFc = () => {
  const { getParamValues } = useRouterParams()
  const attributeIds = getParamValues('selected')

  const translate = useTranslate([
    'action.new_attribute',
    'action.link_to_category',
    'action.actions',
  ])

  const {
    isOpen: isAddAttributeModalOpen,
    handleOpenDialog: showAddAttributeModal,
    handleCloseDialog: closeAddAttributeModal,
  } = useDialog()

  const {
    isOpen: isLinkToCategoryModalOpen,
    handleOpenDialog: showLinkToCategoryModal,
    handleCloseDialog: closeLinkToCategoryModal,
  } = useDialog()

  const { permissions } = useUserPermissions()

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        ...(permissions?.[PERMISSIONS.CAN_ADD_ATTRIBUTES]
          ? [
              {
                name: translate['action.new_attribute'],
                icon: 'plus',
                onClick: showAddAttributeModal,
              },
            ]
          : []),
        {
          name: translate['action.link_to_category'],
          icon: 'symlink',
          onClick: showLinkToCategoryModal,
          disabled: attributeIds.length === 0,
        },
        // TODO: раскоментировать, когда будет готов функционал
        // {
        //   name: 'Пересчитать прогресс товаров',
        //   icon: 'refresh',
        // },
        // {
        //   name: 'Создать отчет по атрибутам',
        //   icon: 'visPie',
        // },
        // {
        //   name: 'Создать отчет по ошибкам',
        //   icon: 'visLine',
        // },
      ],
    },
  ]

  return (
    <>
      <ContextMenu
        menuButtonText={translate['action.actions']}
        panels={panels}
        endIcon="arrowDown"
      />
      <AddAttribute isOpen={isAddAttributeModalOpen} onClose={closeAddAttributeModal} />
      <LinkAttributeToCategory
        isOpen={isLinkToCategoryModalOpen}
        onClose={closeLinkToCategoryModal}
      />
    </>
  )
}

export { AttributeActions }
