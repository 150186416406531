import { useObjectGroupApi } from '@shared/api'
import { ROUTES } from '@shared/config'
import { useMeta, useTitle } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AttributeGroupHeader, AttributeGroupTable } from '@widgets/settings'
import { useTypedParams } from 'react-router-typesafe-routes'

export const AttributeGroupPage: ReactFc = () => {
  const { id: attributeGroupId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP)
  const attributeGroupQuery = useObjectGroupApi(attributeGroupId)

  const attributeGroupTitle = useTitle(attributeGroupQuery)

  useMeta({ title: attributeGroupTitle })

  return (
    <Layout header={<AttributeGroupHeader />}>
      <AttributeGroupTable />
    </Layout>
  )
}
