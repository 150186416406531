import { API_ATTRIBUTE_TYPE } from '@shared/api'
import {
  CheckboxInput,
  DatePicker,
  JsonInput,
  NumberInput,
  TextAreaInput,
  TextInput,
} from '@shared/ui/form'
import { useTranslate } from '@shared/ui/translate'
import { type RegisterOptions } from 'react-hook-form'

import { AttributeFile } from './attribute-file'
import { AttributeList } from './attribute-list'
import { type AttributeFormFieldInputProps, type AttributeFormFieldProps } from './types'

const AttributeFormField: ReactFc<AttributeFormFieldProps> = (props) => {
  const translate = useTranslate(['error.attribute_empty'])
  const { attribute, disablePlaceholder = false, isCompressed = false, isSubmitting } = props

  const attributeId = attribute.id.toString()
  const placeholder = disablePlaceholder ? '' : attribute.name

  const registerOptions: RegisterOptions = {
    ...(attribute.is_required && { required: translate['error.attribute_empty'] }),
  }

  const inputProps: AttributeFormFieldInputProps = {
    required: attribute.is_required,
    disabled: !attribute.is_editable || isSubmitting,
  }

  switch (attribute.attribute_type) {
    case API_ATTRIBUTE_TYPE.TEXT:
      return (
        <TextInput
          name={attributeId}
          placeholder={placeholder}
          registerOptions={registerOptions}
          inputProps={{
            compressed: isCompressed,
            ...inputProps,
          }}
        />
      )
    case API_ATTRIBUTE_TYPE.LONG_TEXT:
      return (
        <TextAreaInput
          name={attributeId}
          placeholder={placeholder}
          registerOptions={registerOptions}
          inputProps={{
            fullWidth: true,
            ...inputProps,
          }}
        />
      )
    case API_ATTRIBUTE_TYPE.NUMBER:
      return (
        <NumberInput
          name={attributeId}
          placeholder={placeholder}
          registerOptions={registerOptions}
          inputProps={{
            step: 1, // Делает возможным ввод только целых чисел
            ...inputProps,
          }}
        />
      )
    case API_ATTRIBUTE_TYPE.FLOAT:
      return (
        <NumberInput
          name={attributeId}
          placeholder={placeholder}
          registerOptions={registerOptions}
          inputProps={inputProps}
        />
      )
    case API_ATTRIBUTE_TYPE.LIST:
      return (
        <AttributeList
          placeholder={placeholder}
          inputProps={{
            compressed: isCompressed,
            ...inputProps,
          }}
          {...props}
        />
      )
    case API_ATTRIBUTE_TYPE.BOOL:
      return <CheckboxInput name={attributeId} label={placeholder} inputProps={inputProps} />
    case API_ATTRIBUTE_TYPE.NESTED_LIST:
      return null
    case API_ATTRIBUTE_TYPE.FILE:
      return <AttributeFile placeholder={placeholder} inputProps={inputProps} {...props} />
    case API_ATTRIBUTE_TYPE.JSON:
      return (
        <JsonInput
          name={attributeId}
          placeholder={placeholder}
          registerOptions={registerOptions}
          inputProps={{
            options: {
              minimap: {
                enabled: false,
              },
              readOnly: inputProps.disabled,
            },
          }}
        />
      )
    case API_ATTRIBUTE_TYPE.DATE:
      return (
        <DatePicker
          name={attributeId}
          registerOptions={registerOptions}
          inputProps={{ ...inputProps, placeholder: placeholder }}
        />
      )
    default:
      return null
  }
}

export { AttributeFormField }
