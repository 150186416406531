import { useCategoryApi } from '@entities/categories'
import { ROUTES } from '@shared/config'
import { useMeta, useTitle } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { CategoryTabs } from '@widgets/settings'
import { useTypedParams } from 'react-router-typesafe-routes'

const CategoryPage: ReactFc = () => {
  const { id: categoryId } = useTypedParams(ROUTES.SETTINGS.CATEGORIZATION.CATEGORY)
  const category = useCategoryApi(categoryId)
  useMeta({ title: useTitle(category) })

  return (
    <Layout
      pageBodyProps={{
        paddingSize: 'm',
      }}
    >
      <CategoryTabs />
    </Layout>
  )
}

export { CategoryPage }
