import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiValidator } from '@shared/api/types'
import { getDateColumnRender } from '@shared/lib'
import { useTranslate } from '@shared/ui/translate'

import { renderValidatorName } from '../lib'

const useValidatorsColumns = (): EuiBasicTableColumn<ApiValidator>[] => {
  const translate = useTranslate([
    'label.id',
    'label.attribute',
    'label.create_date',
    'label.update_date',
  ])

  return [
    {
      field: 'id',
      name: translate['label.id'],
      width: '120px',
    },
    {
      field: 'validated_attribute_name',
      name: translate['label.attribute'],
      render: renderValidatorName,
    },
    {
      field: 'created_at',
      name: translate['label.create_date'],
      render: getDateColumnRender<ApiValidator, 'created_at'>(),
    },
    {
      field: 'updated_at',
      name: translate['label.update_date'],
      render: getDateColumnRender<ApiValidator, 'updated_at'>(),
    },
  ]
}

export { useValidatorsColumns }
