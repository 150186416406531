import { EuiPanel } from '@elastic/eui'
import { useUserApi } from '@entities/users'
import { ROUTES } from '@shared/config'
import { InfoPageCategory } from '@shared/ui'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'
import { useTypedParams } from 'react-router-typesafe-routes'

import { type UserInfoProps } from './types'
import { UserInfoRows } from './user-info-rows'

const UserInfo: ReactFc<UserInfoProps> = ({ methods, isUserSubmitting }) => {
  const { id: userId } = useTypedParams(ROUTES.SETTINGS.USERS.USER)
  const { data: user, isLoading: isUserLoading } = useUserApi(userId)
  const translate = useTranslate(['action.all_fields'])

  // TODO: реализовать обработку кейса, когда валидатор не получен
  if (!user) {
    if (!isUserLoading) {
      console.error('Не удалось получить валидатор.')
    }

    return null
  }

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <FormProvider {...methods}>
        <InfoPageCategory id={userId} categoryName={translate['action.all_fields']}>
          <UserInfoRows id={userId} isSubmitting={isUserSubmitting} />
        </InfoPageCategory>
      </FormProvider>
    </EuiPanel>
  )
}

export { UserInfo }
