import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPageSection } from '@elastic/eui'
import { useAttributeGroupColumns } from '@entities/attributes'
import { AddAttributeToCategory, RemoveAttributeFromCategory } from '@features/attributes'
import { API_ATTRIBUTE_MODEL, useAttributeListApi, type UseAttributeListApiArgs } from '@shared/api'
import { type ApiAttributeList } from '@shared/api/types'
import { PARAMS, ROUTES } from '@shared/config'
import { Search } from '@shared/ui'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'
import { useTranslate } from '@shared/ui/translate'
import { useSearchParams } from 'react-router'
import { useTypedParams } from 'react-router-typesafe-routes'

const CategoryAttributesTab: ReactFc = () => {
  const { id: categoryId } = useTypedParams(ROUTES.SETTINGS.CATEGORIZATION.CATEGORY)
  const { sorting, sortList, onTableChange } = useSortTable<ApiAttributeList>()
  const { selection } = useSelectionTable<ApiAttributeList>()
  const columns = useAttributeGroupColumns()
  const translate = useTranslate(['action.find_attribute'])

  // Поиск по атрибутам
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  // Список атрибутов в категории
  const { isLoading, data: attributes } = useAttributeListApi({
    [PARAMS.MODEL]: API_ATTRIBUTE_MODEL.SKU,
    [PARAMS.CATEGORY]: [categoryId],
    search,
  } as UseAttributeListApiArgs)

  const attributeList = attributes?.results || []

  return (
    <EuiPageSection paddingSize="none">
      <EuiFlexGroup alignItems="stretch">
        <EuiFlexItem grow={false}>
          <Search searchParam="search" placeholder={translate['action.find_attribute']} />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup justifyContent="flexEnd" gutterSize="none">
            <RemoveAttributeFromCategory />
            <AddAttributeToCategory />
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule margin="s" />

      <Table
        columns={columns}
        items={sortList(attributeList)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}

export { CategoryAttributesTab }
