import { AUTOMATIC_TASK_NAME, type AUTOMATIC_TASK_TYPE } from '@shared/config'
import { Translate } from '@shared/ui/translate'

const getAutomaticTaskTypeOptions = () => {
  return Object.keys(AUTOMATIC_TASK_NAME).map((key) => {
    return {
      value: parseInt(key),
      inputDisplay: Translate({
        token: AUTOMATIC_TASK_NAME?.[parseInt(key) as AUTOMATIC_TASK_TYPE],
      }),
    }
  })
}

export { getAutomaticTaskTypeOptions }
