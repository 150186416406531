import { SKU_HISTORY_COLUMNS, useSkuHistoryApi } from '@entities/catalog'
import { SkuHistoryRestore } from '@features/catalog/sku-history'
import { ROUTES } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { Pagination, usePagination } from '@shared/ui'
import { Table } from '@shared/ui/table'
import { useTypedParams } from 'react-router-typesafe-routes'

const SkuHistoryTab: ReactFc = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const { params } = useRouterParams()

  const { isLoading, data } = useSkuHistoryApi.list({
    sku_id: skuId,
    ...params,
  })

  const paginationProps = usePagination(data?.count)

  const historyList = data?.results || []

  const columnsWithAction = [
    ...SKU_HISTORY_COLUMNS,
    {
      width: '40px',
      actions: [
        {
          render: SkuHistoryRestore,
        },
      ],
    },
  ]

  return (
    <>
      <Table columns={columnsWithAction} items={historyList} loading={isLoading} />
      <Pagination {...paginationProps} />
    </>
  )
}

export { SkuHistoryTab }
