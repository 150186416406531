import { EuiFlexItem, EuiPanel, EuiSkeletonLoading, EuiText } from '@elastic/eui'
import { SkuCard, SkuCardSkeleton } from '@entities/catalog'
import { renderMultiple } from '@shared/lib'
import { Translate } from '@shared/ui/translate'

import { grid } from './styles'
import { type CatalogCardsProps } from './types'

export const CatalogCards: ReactFc<CatalogCardsProps> = ({ skuList, isLoading }) => {
  if (!isLoading && skuList.length === 0) {
    return (
      <EuiPanel hasShadow={false} borderRadius="none" paddingSize="s" grow={false}>
        <EuiText size="s" textAlign="center">
          <Translate token="message.empty" />
        </EuiText>
      </EuiPanel>
    )
  }

  const catalogCardsContent = skuList.map((item) => {
    return (
      <EuiFlexItem key={item.id}>
        <SkuCard {...item} />
      </EuiFlexItem>
    )
  })

  return (
    <EuiSkeletonLoading
      isLoading={isLoading}
      loadingContent={<>{renderMultiple(SkuCardSkeleton, 25)}</>}
      loadedContent={catalogCardsContent}
      css={grid}
    />
  )
}
