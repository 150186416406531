import { ATTRIBUTE_FLAGS } from '@shared/api'
import { ATTRIBUTE_LABEL } from '@shared/config'

import { type GetAttributeNameLabel } from './types'

const getAttributeNameLabel: GetAttributeNameLabel = (attribute) => {
  if (attribute[ATTRIBUTE_FLAGS.NAME]) {
    return ATTRIBUTE_LABEL.NAME
  }

  if (attribute[ATTRIBUTE_FLAGS.BRAND]) {
    return ATTRIBUTE_LABEL.BRAND
  }

  if (attribute[ATTRIBUTE_FLAGS.SKU]) {
    return ATTRIBUTE_LABEL.SKU
  }

  if (attribute[ATTRIBUTE_FLAGS.PREVIEW]) {
    return ATTRIBUTE_LABEL.PREVIEW
  }

  return ''
}

export { getAttributeNameLabel }
