import { useTranslate } from '@shared/ui/translate'

const useCronValidators = () => {
  const translate = useTranslate([
    'error.cron_minute_format',
    'error.cron_minute_start_value',
    'error.cron_minute_step',
    'error.cron_minute_number',
    'error.cron_minute_range',
    'error.cron_hour_format',
    'error.cron_hour_start_value',
    'error.cron_hour_step',
    'error.cron_hour_number',
    'error.cron_hour_range',
    'error.cron_day_of_week_format',
    'error.cron_day_of_week_start_value',
    'error.cron_day_of_week_step',
    'error.cron_day_of_week_number',
    'error.cron_day_of_week_range',
    'error.cron_day_of_month_format',
    'error.cron_day_of_month_start_value',
    'error.cron_day_of_month_step',
    'error.cron_day_of_month_number',
    'error.cron_day_of_month_range',
    'error.cron_month_format',
    'error.cron_month_start_value',
    'error.cron_month_step',
    'error.cron_month_number',
    'error.cron_month_range',
  ])

  return {
    minute: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d{1,2})(\/\d{1,2})?$/

        if (!pattern.test(value)) {
          return translate['error.cron_minute_format']
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 0 || parseInt(start) > 59)
          ) {
            return translate['error.cron_minute_start_value']
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 59) {
            return translate['error.cron_minute_step']
          }

          return false
        }

        if (isNaN(parseInt(value))) {
          return translate['error.cron_minute_number']
        }

        if (parseInt(value) < 0 || parseInt(value) > 59) {
          return translate['error.cron_minute_range']
        }

        return false
      },
    },
    hour: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d{1,2})(\/\d{1,2})?$/

        if (!pattern.test(value)) {
          return translate['error.cron_hour_format']
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 0 || parseInt(start) > 23)
          ) {
            return translate['error.cron_hour_start_value']
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 23) {
            return translate['error.cron_hour_step']
          }

          return false
        }

        if (isNaN(parseInt(value))) {
          return translate['error.cron_hour_number']
        }

        if (parseInt(value) < 0 || parseInt(value) > 23) {
          return translate['error.cron_hour_range']
        }

        return false
      },
    },
    day_of_week: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d)(\/\d)?$/

        if (!pattern.test(value)) {
          return translate['error.cron_day_of_week_format']
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 0 || parseInt(start) > 6)
          ) {
            return translate['error.cron_day_of_week_start_value']
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 6) {
            return translate['error.cron_day_of_week_step']
          }

          return false
        }

        if (isNaN(parseInt(value))) {
          return translate['error.cron_day_of_week_number']
        }

        if (parseInt(value) < 0 || parseInt(value) > 6) {
          return translate['error.cron_day_of_week_range']
        }

        return false
      },
    },
    day_of_month: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d{1,2})(\/\d{1,2})?$/

        if (!pattern.test(value)) {
          return translate['error.cron_day_of_month_format']
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 1 || parseInt(start) > 31)
          ) {
            return translate['error.cron_day_of_month_start_value']
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 31) {
            return translate['error.cron_day_of_month_step']
          }

          return false
        }

        if (isNaN(parseInt(value))) {
          return translate['error.cron_day_of_month_number']
        }

        if (parseInt(value) < 1 || parseInt(value) > 31) {
          return translate['error.cron_day_of_month_range']
        }

        return false
      },
    },
    month_of_year: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d{1,2})(\/\d{1,2})?$/

        if (!pattern.test(value)) {
          return translate['error.cron_month_format']
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 1 || parseInt(start) > 12)
          ) {
            return translate['error.cron_month_start_value']
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 12) {
            return translate['error.cron_month_step']
          }

          return false
        }

        if (isNaN(parseInt(value))) return translate['error.cron_month_number']

        if (parseInt(value) < 1 || parseInt(value) > 12) {
          return translate['error.cron_month_range']
        }

        return false
      },
    },
  }
}

export { useCronValidators }
