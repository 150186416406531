import { EuiButton, EuiFormRow } from '@elastic/eui'
import { ROUTES } from '@shared/config'
import { useToggle } from '@shared/hooks'
import { Translate } from '@shared/ui/translate'
import { useTypedParams } from 'react-router-typesafe-routes'

import { ChangeUserPasswordModal } from './change-user-password-modal'

const ChangeUserPassword: ReactFc = () => {
  const { id: userId } = useTypedParams(ROUTES.SETTINGS.USERS.USER)
  const [isModalOpen, toggleIsModalOpen] = useToggle()

  return (
    <EuiFormRow helpText={<Translate token="message.hidden_password" />}>
      <>
        <EuiButton onClick={toggleIsModalOpen} size="s">
          <Translate token="action.change_password" />
        </EuiButton>
        <ChangeUserPasswordModal isOpen={isModalOpen} onClose={toggleIsModalOpen} userId={userId} />
      </>
    </EuiFormRow>
  )
}

export { ChangeUserPassword }
