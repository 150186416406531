import { EuiHeaderLink } from '@elastic/eui'
import { Modal } from '@shared/ui/modal'
import { useTranslate } from '@shared/ui/translate'
import { FormProvider } from 'react-hook-form'

import { useAddCategory } from '../model'
import { CategoryForm } from './category-form'

const AddCategory: ReactFc = () => {
  const { isOpen, showModal, closeModal, methods, addCategory, isSubmitting } = useAddCategory()
  const translate = useTranslate(['action.add_category'])

  return (
    <>
      <EuiHeaderLink iconType="plusInCircle" color="primary" iconSide="right" onClick={showModal}>
        {translate['action.add_category']}
      </EuiHeaderLink>

      <FormProvider {...methods}>
        <Modal
          title={translate['action.add_category']}
          onClose={closeModal}
          onSubmit={addCategory}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <CategoryForm isSubmitting={isSubmitting} />
        </Modal>
      </FormProvider>
    </>
  )
}

export { AddCategory }
