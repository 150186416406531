import { EuiFlexGroup } from '@elastic/eui'
import { useStyles } from '@shared/ui/styles'
import { useTranslate } from '@shared/ui/translate'

import { usePrintLink, usePublicLink } from '../model'
import { SkuToolbarButton } from './sku-toolbar-button'
import { SkuToolbarDownloadButton } from './sku-toolbar-download-button'
import { getStyles } from './styles'

export const SkuToolbar: ReactFc = () => {
  const styles = useStyles(getStyles)
  const printSkuPage = usePrintLink()
  const { isPublic, openPublicLink } = usePublicLink()

  const translate = useTranslate([
    'action.download_description',
    'label.print_version',
    'action.notify_changes',
    'label.public_catalog_link',
    'message.link_after_publication',
  ])

  return (
    <EuiFlexGroup gutterSize="none" css={styles.toolbar}>
      <SkuToolbarDownloadButton
        content={translate['action.download_description']}
        iconType="save"
      />
      <SkuToolbarButton
        content={translate['label.print_version']}
        iconType="document"
        onClick={printSkuPage}
      />
      <SkuToolbarButton
        content={translate['action.notify_changes']}
        iconType="userAvatar"
        onClick={() => {
          return alert(translate['action.notify_changes'])
        }}
      />
      <SkuToolbarButton
        content={
          isPublic
            ? translate['label.public_catalog_link']
            : translate['message.link_after_publication']
        }
        iconType="editorLink"
        onClick={openPublicLink}
        isDisabled={!isPublic}
      />
    </EuiFlexGroup>
  )
}
