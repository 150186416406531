import { EuiHeaderLink } from '@elastic/eui'
import { useObjectGroupApi } from '@shared/api'
import { ROUTES } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { useTranslate } from '@shared/ui/translate'
import { useTypedParams } from 'react-router-typesafe-routes'

import { UpdateAttributeGroupModal } from './update-attribute-group-modal'

export const UpdateAttributeGroup: ReactFc = () => {
  const { id: attributeGroupId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP)
  const attributeGroupQuery = useObjectGroupApi(attributeGroupId)

  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()
  const translate = useTranslate(['action.edit'])

  if (!attributeGroupQuery.isSuccess) {
    return null
  }

  return (
    <>
      <EuiHeaderLink
        iconType="gear"
        color="primary"
        textProps={false}
        aria-label={translate['action.edit']}
        onClick={showModal}
      />

      <UpdateAttributeGroupModal
        attributeGroup={attributeGroupQuery.data}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  )
}
