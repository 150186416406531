import { EuiButtonEmpty } from '@elastic/eui'
import { useDialog } from '@shared/hooks'
import { Translate } from '@shared/ui/translate'
import { Fragment } from 'react'

import { SkuHistoryModal } from './sku-history-modal'
import { type SkuHistoryRestoreProps } from './types'

const SkuHistoryRestore = (props: SkuHistoryRestoreProps) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()

  return (
    <Fragment key={props.id}>
      <EuiButtonEmpty size="s" iconType="minimize" onClick={showModal}>
        <Translate token="action.version_comparison" />
      </EuiButtonEmpty>

      <SkuHistoryModal version={props} isOpen={isOpen} closeModal={closeModal} />
    </Fragment>
  )
}

export { SkuHistoryRestore }
