import { useAttributeOptionsApi } from '@entities/attributes'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { useDialog } from '@shared/hooks'
import { NOTIFICATION, useNotificationsStore } from '@shared/ui/notifications'
import { useTranslate } from '@shared/ui/translate'
import { useForm } from 'react-hook-form'

import { type AddAttributeOptionFormInputs } from './types'

export const useAddAttributeOption = (attributeId: number) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const addNotification = useNotificationsStore.useAddNotification()
  const methods = useForm<AddAttributeOptionFormInputs>()
  const translate = useTranslate(['error.server'])

  const createAttributeOption = useAttributeOptionsApi.createOption()

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const onError = () => {
    methods.setError('root', {
      type: 'manual',
      message: translate['error.server'],
    })
  }

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_OPTIONS_LIST(attributeId) })
    closeModal()
    addNotification(NOTIFICATION.ATTRIBUTE_OPTION_CREATE_SUCCESS)
  }

  const onSubmit = async ({ name }: AddAttributeOptionFormInputs) => {
    await createAttributeOption.mutateAsync(
      {
        body: {
          value: name,
          attribute: attributeId,
        },
      },
      { onSuccess, onError },
    )
  }

  const addOption = methods.handleSubmit(onSubmit)

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    addOption,
    isSubmitting: createAttributeOption.isPending,
  }
}
