import { EuiForm, EuiPanel, EuiSpacer } from '@elastic/eui'
import { AttributesCategoryFilterTree } from '@features/attributes'
import { CategorySearch } from '@features/catalog'
import { CategoryFilter } from '@features/categories'
import { API_ATTRIBUTE_MODEL, useAttributeListApi } from '@shared/api'
import React from 'react'

const AttributesSidebarForm: ReactFc = () => {
  const { data: attributes } = useAttributeListApi({ model: API_ATTRIBUTE_MODEL.SKU })

  const rootAttributesCount = attributes?.results.reduce((acc, attribute) => {
    return attribute.category === null ? acc + 1 : acc
  }, 0)

  return (
    <EuiPanel hasShadow={false}>
      <EuiForm fullWidth>
        <CategorySearch />
        <EuiSpacer />

        <CategoryFilter
          getTreeItems={(items) => {
            return AttributesCategoryFilterTree({ items, rootAttributesCount, hasRoot: true })
          }}
        />
      </EuiForm>
    </EuiPanel>
  )
}

const AttributesSidebarFormMemo = React.memo(AttributesSidebarForm) as typeof AttributesSidebarForm

export { AttributesSidebarFormMemo as AttributesSidebarForm }
