import { type ApiAttributeList } from '@shared/api/types'

import { AttributeName } from './attribute-name'
import { getAttributeNameLabel } from './get-attribute-name-label'

const renderAttributeName: RenderColumn<ApiAttributeList, 'name'> = (name, attribute) => {
  return <AttributeName name={name} label={getAttributeNameLabel(attribute)} id={attribute.id} />
}

export { renderAttributeName }
