import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText, EuiToolTip } from '@elastic/eui'
import { AUTOMATIC_TASK_NAME } from '@shared/config'
import { getLocalDate } from '@shared/lib'
import { Translate } from '@shared/ui/translate'

import { type TaskCardSubInfoProps } from './types'

const TaskCardSubInfo: ReactFc<TaskCardSubInfoProps> = ({
  createdAt,
  createdBy,
  taskType,
  lastLaunchAt,
}) => {
  const subInfoItems = [
    { iconType: 'user', text: createdBy, toolTip: Translate({ token: 'message.initiator' }) },
    {
      iconType: 'calendar',
      text: getLocalDate(createdAt),
      toolTip: Translate({ token: 'message.create_date' }),
    },
    {
      iconType: 'document',
      text: Translate({ token: AUTOMATIC_TASK_NAME[taskType] }),
      toolTip: Translate({ token: 'message.type' }),
    },
    {
      iconType: lastLaunchAt ? 'timeslider' : null,
      text: lastLaunchAt,
      toolTip: Translate({ token: 'message.last_launch' }),
    },
  ]

  return (
    <EuiFlexGroup alignItems="center">
      {subInfoItems.map((item, index) => {
        const { iconType, text, toolTip } = item

        return (
          <EuiFlexItem key={index} grow={false}>
            <EuiToolTip position="top" content={toolTip}>
              <EuiFlexGroup alignItems="center" gutterSize="s">
                {iconType && <EuiIcon type={iconType} size="m" />}

                <EuiText size="s">{text}</EuiText>
              </EuiFlexGroup>
            </EuiToolTip>
          </EuiFlexItem>
        )
      })}
    </EuiFlexGroup>
  )
}

export { TaskCardSubInfo }
