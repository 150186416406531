import { addAttributesList, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { ROUTES } from '@shared/config'
import { useTypedParams } from 'react-router-typesafe-routes'

export const usePrintData = () => {
  const { id: skuId } = useTypedParams(ROUTES.CATALOG.SKU)
  const skuQuery = useSkuApi(skuId)
  const attributeGroupsQuery = useAttributeGroupsList()

  if (attributeGroupsQuery.isSuccess && skuQuery.isSuccess) {
    return addAttributesList({
      groups: attributeGroupsQuery.data,
      attributes: skuQuery.data.attributes,
    }) as AttributeGroup[]
  }

  return null
}
