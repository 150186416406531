import { EuiI18n } from '@elastic/eui'
import { DEFAULT_LANGUAGE } from '@shared/config'
import { TRANSLATE_MAPPING } from '@shared/config/translate'

import { type TranslateProps } from './types'

const Translate: ReactFc<TranslateProps> = ({ token, tokens, children }) => {
  if (token) {
    const defaultValue = TRANSLATE_MAPPING[DEFAULT_LANGUAGE][token]

    return <EuiI18n token={token} default={defaultValue} />
  }

  if (tokens && children) {
    const defaultValues = tokens.map((token) => {
      return TRANSLATE_MAPPING[DEFAULT_LANGUAGE][token]
    })

    return <EuiI18n tokens={tokens} defaults={defaultValues} children={children} />
  }

  return null
}

export { Translate }
