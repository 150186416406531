import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { type ApiObjectAttribute } from '@shared/api/types'
import { useEffect } from 'react'

import { useSkuApi } from '../api'
import { type UseSkuDefaultValuesArgs } from './types'

const useSkuDefaultValues = ({ id, methods }: UseSkuDefaultValuesArgs) => {
  const { data: sku, isLoading } = useSkuApi(id)

  useEffect(() => {
    if (!isLoading && sku?.attributes) {
      const defaultValues = sku.attributes.reduce(
        (acc, attribute) => {
          if (attribute.attribute_type === API_ATTRIBUTE_TYPE.FILE) {
            return acc
          }

          if (attribute.attribute_type === API_ATTRIBUTE_TYPE.LIST) {
            acc[attribute.id] = attribute.value && [
              {
                value: attribute.option_id,
                label: attribute.value,
              },
            ]

            return acc
          }

          if (attribute.attribute_type === API_ATTRIBUTE_TYPE.JSON) {
            acc[attribute.id] = attribute.value ? JSON.stringify(attribute.value, null, 2) : ''

            return acc
          }

          if (attribute.attribute_type === API_ATTRIBUTE_TYPE.BOOL) {
            acc[attribute.id] = attribute.value || false

            return acc
          }

          acc[attribute.id] = attribute.value ? attribute.value.toString() : ''

          return acc
        },
        {} as Record<number, ApiObjectAttribute['value']>,
      )

      // Обновляем значения формы
      methods.reset(defaultValues)
    }
  }, [sku])
}

export { useSkuDefaultValues }
