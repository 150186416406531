import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPageSection } from '@elastic/eui'
import { useAttributeOptionsApi, useAttributeOptionsColumns } from '@entities/attributes'
import { AddAttributeOption, RemoveAttributeOptions } from '@features/attributes'
import { type ApiAttributeOption } from '@shared/api/types'
import { ROUTES } from '@shared/config'
import { Search } from '@shared/ui'
import { Table, useSelectionTable } from '@shared/ui/table'
import { useTranslate } from '@shared/ui/translate'
import { useSearchParams } from 'react-router'
import { useTypedParams } from 'react-router-typesafe-routes'

import { ContextMenu } from './context-menu'

const OptionsList: ReactFc = () => {
  const { id: attributeId } = useTypedParams(ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE)
  const attributeOptionsColumns = useAttributeOptionsColumns()
  const translate = useTranslate(['action.find_attribute'])

  // Поиск по опциям
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') || undefined

  const { selection } = useSelectionTable<ApiAttributeOption>()

  const optionsListQuery = useAttributeOptionsApi.listOptions({
    attribute: attributeId,
    search,
  })

  const optionsList = optionsListQuery.data?.results || []

  const columnsWithAction = [
    {
      width: '40px',
      actions: [
        {
          render: ContextMenu,
        },
      ],
    },
    ...attributeOptionsColumns,
  ]

  return (
    <EuiPageSection paddingSize="none">
      <EuiFlexGroup alignItems="stretch">
        <EuiFlexItem grow={false}>
          <Search searchParam="search" placeholder={translate['action.find_attribute']} />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup justifyContent="flexEnd" gutterSize="none">
            <RemoveAttributeOptions />
            <AddAttributeOption />
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule margin="s" />

      <Table
        columns={columnsWithAction}
        items={optionsList}
        selection={selection}
        loading={optionsListQuery.isLoading}
      />
    </EuiPageSection>
  )
}

export { OptionsList }
