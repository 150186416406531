import { $api } from '@shared/api'

import { type UsersListQuery } from '../types'

const useUsersListApi = (args: UsersListQuery = {}) => {
  return $api.useQuery('get', '/users/', {
    params: {
      query: {
        ...args,
      },
    },
  })
}

export { useUsersListApi }
