import { EuiContextMenuItem, EuiIcon } from '@elastic/eui'
import { Translate } from '@shared/ui/translate'

import { useRemoveAttributeGroups } from '../model'

const MenuItemRemoveAttributeGroup: ReactFc<ItemWithId> = ({ id }) => {
  const { removeFromGroups } = useRemoveAttributeGroups([id])

  return (
    <EuiContextMenuItem
      icon={<EuiIcon type="trash" color="danger" />}
      size="s"
      onClick={removeFromGroups}
    >
      <Translate token="action.delete" />
    </EuiContextMenuItem>
  )
}

export { MenuItemRemoveAttributeGroup }
