import { EuiText } from '@elastic/eui'
import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { attributeTypeEnumValues } from '@shared/api/openapi-types'
import { Translate, useTranslate } from '@shared/ui/translate'

import { ATTRIBUTE_TYPE_HELP } from './attribute-type-help'
import { ATTRIBUTE_TYPE_MAP } from './attribute-type-map'

const useAttributeTypeOptions = () => {
  const allTokens = Object.values(ATTRIBUTE_TYPE_MAP)
  const translate = useTranslate(allTokens)

  const attributeTypeOptions = attributeTypeEnumValues
    .map((key) => {
      const inputDisplay = ATTRIBUTE_TYPE_MAP[key]

      return {
        value: key,
        inputDisplay: translate[inputDisplay],
        dropdownDisplay: (
          <>
            <Translate token={inputDisplay} />
            <EuiText size="xs" color="subdued">
              <Translate token={ATTRIBUTE_TYPE_HELP[key].TOOLTIP} />
            </EuiText>
          </>
        ),
      }
    })
    //TODO: Пока не появится на бекенде
    .filter(({ value }) => {
      return value !== API_ATTRIBUTE_TYPE.NESTED_LIST
    })

  return attributeTypeOptions
}

export { useAttributeTypeOptions }
